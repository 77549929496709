import { combineReducers } from 'redux';
import { createTransform, persistReducer, Transform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import alertReducer from './alert.reducer';
import casesReducer from './cases.reducer';
import languagesReducer from './languages.reducer';
import timerReducer from './time.reducer';
import productsReducer from './products.reducer';
import pageReducer from './page.reducer';
import userReducer from './user.reducer';
import sidebarReducer from './sidebar.reducer';
import { decodeJwt } from '../../helpers';
import { userInitialState } from '../states/user.states';

const setTransform: Transform<any, any> = createTransform(
  inboundState => ({ ...inboundState }),
  (outboundState, key) => {
    switch (key) {
      case 'user':
        return outboundState.activeToken
        && decodeJwt(outboundState.activeToken) // Set to logged out state if user token expired
          ? { ...outboundState, isPasswordExpired: false }
          : { ...userInitialState };
      default:
        return { ...outboundState };
    }
  }
);

export const userInfoPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  transforms: [setTransform],
  stateReconciler: autoMergeLevel2,
};

const languagesPersistConfig = {
  key: 'languages',
  storage,
  whitelist: ['currentLanguage'],
  stateReconciler: autoMergeLevel2,
};

const productsPersistConfig = {
  key: 'products',
  storage,
  whitelist: ['formSpecDict'],
  stateReconciler: autoMergeLevel2,
};

const pagePersistConfig = {
  key: 'page',
  storage,
  whitelist: ['referrerUri'],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  user: userReducer,
  alert: alertReducer,
  cases: casesReducer,
  languages: persistReducer(languagesPersistConfig, languagesReducer),
  time: timerReducer,
  products: persistReducer(productsPersistConfig, productsReducer),
  page: persistReducer(pagePersistConfig, pageReducer),
  sidebar: sidebarReducer
});

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;
