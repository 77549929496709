import React, { FC, ReactNode, useState } from 'react';
import {
  Form,
  DatePicker,
  Row as AntdRow,
  Radio,
} from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Modal from './Modal';
import { FormButton } from '../Forms';
import { ICaseStatusFilter, ICaseFilters } from '../../interfaces';

export type CaseFilterModalProps = {
  width?: string | number;
  title?: string | ReactNode;
  visible: boolean;
  onCancel: () => void;
  onApply: (selectedFilters: ICaseFilters) => null | void;
  caseStatusFilters: readonly ICaseStatusFilter[];
}

const { RangePicker } = DatePicker;

const Row = styled(AntdRow)`
  margin-bottom: ${props => props.theme.unit.space_md};
`;

const StyledRangePicker = styled(RangePicker)`
  cursor: pointer;
  width: 100%;

  .ant-calendar-range-picker-separator {
    margin: 0 ${props => props.theme.unit.space_sm};
  }

  input {
    text-align: left;
    font-size: 0.875rem;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-top: ${props => props.theme.unit.space_xl};

  // TODO: move this to button component as one of the varient for reuse
  & .ant-btn:first-child {
    color: ${({ theme }) => theme.color.primary};
    border-color: ${({ theme }) => theme.color.primary};

    &:hover {
      // Ant Design is adding style from root so !important are needed
      color: ${({ theme }) => theme.color.primaryFocus} !important;
      border-color: ${({ theme }) => theme.color.primaryFocus} !important;
    }
  }

  @media ${props => props.theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;

    & .ant-btn {
      margin: 0;
    }
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }
`;

const StyledRadioButton = styled(Radio.Button)`
  label&.ant-radio-button-wrapper {
    width: 50%;
    height: auto;
    border-radius: 0;
    text-align: center;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    min-height: 1.875rem;
    padding-top: ${props => props.theme.unit.space_sm};
    padding-bottom: ${props => props.theme.unit.space_sm};
  }
`;

const CaseFilterModal: FC<CaseFilterModalProps> = ({
  width = 350,
  title,
  visible,
  onApply,
  onCancel,
  caseStatusFilters,
}) => {
  const intl = useIntl();

  const initialFilters: ICaseFilters = {
    applicationStatus: caseStatusFilters[0].value,
    dateRange: []
  };
  const [filters, setFilters] = useState<ICaseFilters>(initialFilters);

  const onDatePickerUpdate = (dateRange: RangePickerValue):void => {
    setFilters({ ...filters, dateRange: dateRange as ICaseFilters['dateRange'] });
  };

  const onStatusSelectionChange = (event: RadioChangeEvent) => {
    setFilters({ ...filters, applicationStatus: event.target.value });
  };

  const onReset = () => {
    setFilters(initialFilters);
  };

  return (
    <Modal
      visible={visible}
      closable
      onCancelButtonClick={onCancel}
      width={width}
      title={title ?? intl.formatMessage({ id: 'caseFilterModal.title' })}
      customizedFooter={(
        <ButtonContainer>
          <FormButton onClick={onReset}>{intl.formatMessage({ id: 'caseFilterModal.button.reset' })}</FormButton>
          <FormButton type="primary" onClick={() => onApply(filters)}>{intl.formatMessage({ id: 'caseFilterModal.button.apply' })}</FormButton>
        </ButtonContainer>
      )}
      fullScreenInMobile
    >
      <Form>
        <Form.Item label={intl.formatMessage({ id: 'caseFilterModal.dateRange.label' })}>
          <StyledRangePicker
            format="DD/MM/YY"
            separator="-"
            dropdownClassName="filter-modal-date-range-picker"
            id="date-range-picker"
            name="dateRangePicker"
            onChange={onDatePickerUpdate}
            value={filters.dateRange}
            placeholder={[
              intl.formatMessage({ id: 'caseFilterModal.dateRange.startDate' }),
              intl.formatMessage({ id: 'caseFilterModal.dateRange.endDate' }),
            ]}
            size="large"
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'caseFilterModal.applicationStatus.label' })}>
          <Row type="flex" justify="start">
            <StyledRadioGroup
              onChange={onStatusSelectionChange}
              defaultValue={caseStatusFilters[0]?.value}
              value={filters.applicationStatus}
              buttonStyle="solid"
              size="large"
            >
              {!!caseStatusFilters?.length && caseStatusFilters.map(option => (
                <StyledRadioButton
                  key={`status-option-${option.value}`}
                  value={option.value}
                  className="formbuilder__case-filter-modal__status-option"
                >
                  {intl.formatMessage({ id: option.title })}
                </StyledRadioButton>
              ))}
            </StyledRadioGroup>
          </Row>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default CaseFilterModal;
