// TODO: Delete this and get it from formspec
const usualResidency = {
  options: [
    {
      key: 'AbuDhabi',
      value: 'Abu Dhabi'
    },
    {
      key: 'Australia',
      value: 'Australia'
    },
    {
      key: 'Austria',
      value: 'Austria'
    },
    {
      key: 'Belgium',
      value: 'Belgium'
    },
    {
      key: 'Brunei',
      value: 'Brunei'
    },
    {
      key: 'Canada',
      value: 'Canada'
    },
    {
      key: 'China',
      value: 'China'
    },
    {
      key: 'Cuba',
      value: 'Cuba'
    },
    {
      key: 'Cyprus',
      value: 'Cyprus'
    },
    {
      key: 'Denmark',
      value: 'Denmark'
    },
    {
      key: 'Dubai',
      value: 'Dubai'
    },
    {
      key: 'France',
      value: 'France'
    },
    {
      key: 'Germany',
      value: 'Germany'
    },
    {
      key: 'Guam',
      value: 'Guam'
    },
    {
      key: 'Holland',
      value: 'Holland'
    },
    {
      key: 'HongKong',
      value: 'Hong Kong'
    },
    {
      key: 'India',
      value: 'India'
    },
    {
      key: 'Indonesia',
      value: 'Indonesia'
    },
    {
      key: 'Iran',
      value: 'Iran'
    },
    {
      key: 'Ireland',
      value: 'Ireland'
    },
    {
      key: 'Italy',
      value: 'Italy'
    },
    {
      key: 'Japan',
      value: 'Japan'
    },
    {
      key: 'Lao',
      value: 'Lao, PDR'
    },
    {
      key: 'Macau',
      value: 'Macau'
    },
    {
      key: 'Malaysia',
      value: 'Malaysia'
    },
    {
      key: 'Monaco',
      value: 'Monaco'
    },
    {
      key: 'Myanmar',
      value: 'Myanmar'
    },
    {
      key: 'Nepal',
      value: 'Nepal'
    },
    {
      key: 'NewZealand',
      value: 'New Zealand'
    },
    {
      key: 'NorthKorea',
      value: 'North Korea'
    },
    {
      key: 'Norway',
      value: 'Norway'
    },
    {
      key: 'Others',
      value: 'Others'
    },
    {
      key: 'Pakistan',
      value: 'Pakistan'
    },
    {
      key: 'Philippines',
      value: 'The Philippines'
    },
    {
      key: 'Poland',
      value: 'Poland'
    },
    {
      key: 'Portugal',
      value: 'Portugal'
    },
    {
      key: 'Russia',
      value: 'Russia'
    },
    {
      key: 'Singapore',
      value: 'Singapore'
    },
    {
      key: 'SouthAfrica',
      value: 'S. Africa'
    },
    {
      key: 'SouthKorea',
      value: 'S. Korea'
    },
    {
      key: 'Spain',
      value: 'Spain'
    },
    {
      key: 'SriLanka',
      value: 'Sri Lanka'
    },
    {
      key: 'Sudan',
      value: 'Sudan'
    },
    {
      key: 'Sweden',
      value: 'Sweden'
    },
    {
      key: 'Swiss',
      value: 'Swiss'
    },
    {
      key: 'Syria',
      value: 'Syria'
    },
    {
      key: 'Taiwan',
      value: 'Taiwan'
    },
    {
      key: 'Thailand',
      value: 'Thailand'
    },
    {
      key: 'Turkey',
      value: 'Turkey'
    },
    {
      key: 'UK',
      value: 'UK'
    },
    {
      key: 'US',
      value: 'US'
    },
    {
      key: 'Vietnam',
      value: 'Vietnam'
    }
  ]
};

export default usualResidency;
