const blobToBase64 = async (blob): Promise<string> => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onload = e => {
      const result = (typeof e.target?.result === 'string') ? e.target.result.replace(/^data:.+;base64,/, '') : '';
      resolve(result);
    };
    reader.readAsDataURL(blob);
  });
};

export default blobToBase64;
