import React, {
  FC, memo, useEffect, useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { Alert, message } from 'antd';
import { AlertProps } from 'antd/lib/alert';

import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { alertActions, AlertActionTypes } from '../../store/actions';
import { AppState } from '../../store/reducers';

interface ICustomFormAlert
  extends Partial<
    Omit<AlertProps,
      | 'type'
      | 'message'
      | 'description'
      | 'afterClose'
    >
  > {
  contentValues?: any;
  descriptionValues?: any;
  customFormAlertCss?: FlattenSimpleInterpolation;
  shouldShowAlert?: boolean;
  shouldRemain?: boolean; // alert will always remain
  messageKey?: string;
  duration?: number;
}

interface IFormAlert {
  className?: string;
}

const StyledFormAlert = styled(Alert)<{
  customFormAlertCss?: FlattenSimpleInterpolation
}>`
  ${props => props.customFormAlertCss ?? ''}
`;

const FormAlert: FC<IFormAlert> = ({
  className
}) => {
  const {
    component,
    msgType: type,
    msgContent: content,
    msgDescription: description,
    customProps: {
      contentValues = {},
      descriptionValues = {},
      shouldShowAlert = true,
      shouldRemain,
      customFormAlertCss,
      messageKey,
      duration,
      ...otherCustomProps
    },
  } = useSelector((state: AppState) => ({
    component: state.alert.component,
    msgType: state.alert.msgType,
    msgContent: state.alert.msgContent,
    msgDescription: state.alert.msgDescription,
    customProps: state.alert.customProps as ICustomFormAlert,
  }), shallowEqual);

  const intl = useIntl();
  const messageToBeDisplayed = content ? intl.formatMessage({ id: content }, contentValues) : '';
  const descriptionToBeDisplayed = description ? intl.formatMessage({ id: description }, descriptionValues) : '';

  const dispatch = useDispatch<ThunkDispatch<AppState, null, AlertActionTypes>>();
  const clearAlertState = useCallback(() => dispatch(alertActions.clear()), [dispatch]);

  useEffect(() => {
    component === 'message' && message.config({
      top: 50,
      maxCount: 3,
      duration: 3
    });
  }, [component]);

  useEffect(() => {
    if (messageToBeDisplayed && (component === 'message')) {
      message[type]?.({
        content: messageToBeDisplayed,
        key: messageKey,
        duration: shouldRemain ? 0 : duration || 3
      })?.then(clearAlertState);
    }
  }, [messageToBeDisplayed, messageKey, component, type, clearAlertState, shouldRemain, duration]);

  switch (component) {
    // TO-DO: confirm whether to remove this alert case since this is not used outside of form builder
    case 'alert':
      return shouldShowAlert ? (
        <StyledFormAlert
          {...otherCustomProps}
          className={className}
          type={type as AlertProps['type']}
          message={messageToBeDisplayed}
          description={descriptionToBeDisplayed}
          afterClose={clearAlertState}
          customFormAlertCss={customFormAlertCss}
        />
      ) : null;
    default:
      return null;
  }
};

export default memo(FormAlert);
