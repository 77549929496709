import { sidebarTypes } from '../types';
import { SidebarActionTypes } from '../actions';
import { sidebarInitialState, SidebarStateType } from '../states/sidebar.states';

const sidebarReducer = (
  state: SidebarStateType = sidebarInitialState,
  { type, customerSupportModalVisible }: SidebarActionTypes
): SidebarStateType => {
  switch (type) {
    case sidebarTypes.SET_CUSTOMER_SUPPORT_MODAL_VISIBLE:
      return { ...state, customerSupportModalVisible };
    default:
      return state;
  }
};

export default sidebarReducer;
