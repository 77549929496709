import { languagesTypes } from '../types';
import { LanguagesActionTypes } from '../actions';
import { LanguagesStateTypes, languagesInitialState } from '../states/languages.states';

const languagesReducer = (
  state: LanguagesStateTypes = languagesInitialState,
  {
    type, currentLanguage, translationDict, formSpecVersion
  }: LanguagesActionTypes
): LanguagesStateTypes => {
  switch (type) {
    case languagesTypes.SET_LANGUAGE_SUCCESS:
      return {
        ...state,
        currentLanguage: currentLanguage ?? state.currentLanguage
      };

    case languagesTypes.SET_LANGUAGE_DICT_SUCCESS:
      if (state.formSpecVersion !== formSpecVersion) {
        const newTranslationDict = Object.fromEntries(
          Object.entries(languagesInitialState.translationDict).map(([language, translation]) => [
            language, { ...translation, ...(translationDict?.[language] ?? {}) }
          ])
        );

        return {
          ...state,
          formSpecVersion: formSpecVersion || state.formSpecVersion,
          translationDict: newTranslationDict,
        };
      }
      return state;

    default:
      return state;
  }
};

export default languagesReducer;
