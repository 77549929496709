import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import { NewFormItemProps } from './FormItem';

interface FormButtonProps extends ButtonProps, NewFormItemProps {
  fontSize?: string;
}

const StyledButton = styled(Button)<FormButtonProps>`
  box-sizing: border-box;
  min-height: 40px;
  font-weight: bold;
  font-size: ${props => props.fontSize || '1em'};
  &.ant-btn > i,
  &.ant-btn > span {
    font-size: 1rem;
  }
`;

const FormButton: FC<FormButtonProps> = ({
  type = 'default',
  disabled = false,
  htmlType = 'button',
  block = false,
  onClick,
  fontSize,
  loading = false,
  children,
  className
}) => (
  <StyledButton
    type={type}
    disabled={disabled}
    htmlType={htmlType}
    block={block}
    onClick={onClick}
    fontSize={fontSize}
    loading={loading}
    className={className}
  >
    {children}
  </StyledButton>
);

export default FormButton;
