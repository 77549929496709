import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router';
import { ICaseEntity } from '../interfaces';
import { makeRequest } from '../helpers';
import { routes, apiEndpointDomians } from '../constants';

import {
  ICreateCase,
  IUpdateCase,
  IUpdateCaseStatus,
  IFetchCase,
  IFetchCaseList,
  IGetLiveCaseSession,
  IJoinLiveCase,
  IUploadCaseDocument,
  IDownloadCaseFiles,
  IMakeTempDocumentPermanent,
  IDeleteCaseDocument,
  ICreateLinkShare,
  IDelegateCaseToAnonymousUser,
  IGetCaseDataFromPasteBin,
  IGeneratePolicyNumber
} from './cases.service.types';
import { CaseStatus } from '../interfaces/cases.types';

const {
  CASESTORE_DOMAIN_WITH_PATH,
  LIVEJSONSERVER_DOMAIN_WITH_PATH,
  EXCELENGINE_DOMAIN_WITH_PATH,
} = apiEndpointDomians;

/*
 * Entity Store postman: CaseStore > Cases > Adds a new case
 */
const createCase = async (caseData: ICaseEntity): Promise<ICreateCase> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases`;
  return (await makeRequest({ method: 'POST', url, data: caseData })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Update Case
 */
const updateCase = async (
  caseId: string,
  caseData: ICaseEntity
): Promise<IUpdateCase> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}`;
  return (await makeRequest({ method: 'PUT', url, data: caseData })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Update case status
 */
const updateCaseStatus = async (
  caseId: string,
  status: CaseStatus
): Promise<IUpdateCaseStatus> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/status`;
  const data = {
    status1: status
  };
  return (await makeRequest({ method: 'PUT', url, data })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Get case by Id
 */
const fetchCase = async (caseId: string): Promise<IFetchCase> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}`;
  return (await makeRequest({ method: 'GET', url })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Case list
 */
const fetchCaseList = async (
  page?: number,
  pageSize = 5,
  query?: string | null
): Promise<IFetchCaseList> => {
  const url = stringifyUrl(
    {
      url: `${CASESTORE_DOMAIN_WITH_PATH}/user/cases?__sort=-updated&__page=${page ?? 1}&__size=${pageSize}`,
      query: { query: query || null }
    },
    { skipNull: true },
  );
  return (await makeRequest({ method: 'GET', url })).data;
};

/*
 * Entity Store postman: CaseStore > LiveJson > Get live Case Session
 */
const getLiveCaseSession = async (
  caseId: string,
): Promise<IGetLiveCaseSession> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/live`;
  return (await makeRequest({ method: 'GET', url })).data;
};

/*
 * https://github.com/CoherentCapital/LiveJSONServer/blob/dev/program-flow.md
 * swagger: https://livejsonserver.dev.coherent.com.hk/api
 */
const joinLiveCase = async (
  caseId: string,
  participantId: string,
  participantKey: string
): Promise<IJoinLiveCase> => {
  const url = `${LIVEJSONSERVER_DOMAIN_WITH_PATH}/api/sessions/${caseId}`;
  const data = { participantId, participantKey };
  return (await makeRequest({ method: 'POST', url, data })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Adds Case Document
 */
const uploadCaseDocument = async (
  caseId: string,
  file: File,
): Promise<IUploadCaseDocument> => {
  const formData = new FormData();
  formData.append('file', file);

  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/docs`;
  const contentType = 'multipart/form-data';

  return (await makeRequest({
    method: 'POST',
    url,
    data: formData,
    headers: { 'Content-Type': contentType },
  })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Get case doc zip
 */
const downloadCaseFiles = async (caseId: string): Promise<IDownloadCaseFiles> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/docs/zip`;
  const responseType = 'blob';
  const contentType = 'application/zip';

  return (await makeRequest({
    method: 'GET',
    url,
    responseType,
    headers: { 'Content-Type': contentType }
  })).data;
};

/*
 * Adding Temp Documents as Permanent Case Documents
 * Entity Store postman: CaseStore > Cases > Add Temp Doc to Case
 */
const makeTempDocumentPermanent = async (
  caseId: string, fileName: string, documentId: string
): Promise<IMakeTempDocumentPermanent> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/docs/temp`;
  const data = { fileName, docId: documentId };
  return (await makeRequest({
    method: 'POST',
    url,
    data,
  })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Delete Case Document
 */
const deleteCaseDocument = async (caseId: string, documentId: string): Promise<
  IDeleteCaseDocument
> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/docs/${documentId}`;
  return (await makeRequest({
    method: 'DELETE',
    url
  })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Delete Case Document
 */
const delegateCaseToAnonymousUser = async (
  caseId: string,
  userId: string,
  expiryDatetime: Date,
): Promise<IDelegateCaseToAnonymousUser> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/cases/${caseId}/delegate`;
  const data = {
    userId,
    expires: expiryDatetime.toISOString(),
  };

  return (await makeRequest({ method: 'PUT', url, data })).data;
};

/*
 * Entity Store postman: CaseStore > Cases > Adds a new link
 */
const createLinkShare = async (
  caseId: string,
  userId: string,
  expiryDatetime: Date,
  introMessage: string,
  usageLimit: number,
  language?: string
): Promise<ICreateLinkShare> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/links`;

  const cobrowsingSubPath = generatePath(routes.guestWelcome, { caseId });

  const data = {
    targetUrl: stringifyUrl(
      {
        url: `https://${window.location.host}${process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL}${cobrowsingSubPath}`,
        query: { language: language ?? 'zh-HK' }
      },
      { skipNull: true }
    ),
    expires: `${expiryDatetime.toISOString().split('.')[0]}Z`, // KeyCloak is not accepting milliseconds
    introMsg: introMessage,
    userId,
    entityId: caseId,
    usageLimit
  };

  return (await makeRequest({ method: 'POST', url, data })).data;
};

/*
 * Entity Store postman: CaseStore > Pastes > Get Paste by Id
 */
const getCaseDataFromPasteBin = async (
  pasteId: string
): Promise<IGetCaseDataFromPasteBin> => {
  const url = `${CASESTORE_DOMAIN_WITH_PATH}/pastes/${pasteId}`;
  return (await makeRequest({ method: 'GET', url })).data;
};

/*
 * Product Factory script runner to generate policy number
 */
const generatePolicyNumber = async (
  serviceName: string,
  engineName: string,
  serviceCategory: string,
): Promise<IGeneratePolicyNumber> => {
  const url = `${EXCELENGINE_DOMAIN_WITH_PATH}/Product/${serviceName}/engines/execute/${engineName}`;
  const data = {
    RequestedServiceCategory: serviceCategory,
    EngineType: 'Type5',
    Inputs: {}
  };

  return (await makeRequest({ method: 'POST', url, data })).data;
};

const CasesService = {
  createCase,
  updateCase,
  updateCaseStatus,
  fetchCase,
  fetchCaseList,
  getLiveCaseSession,
  joinLiveCase,
  uploadCaseDocument,
  downloadCaseFiles,
  makeTempDocumentPermanent,
  deleteCaseDocument,
  delegateCaseToAnonymousUser,
  createLinkShare,
  getCaseDataFromPasteBin,
  generatePolicyNumber
};

export default CasesService;
