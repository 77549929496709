const Unit = {
  BASESIZE: '16px',
  SPACE_XSM: '0.25rem',
  SPACE_SM: '0.5rem',
  SPACE_MD: '1rem',
  SPACE_LG: '1.5rem',
  SPACE_XL: '2rem',
  CORNER_SM: '0.25rem',
  CORNER_MD: '0.5rem',
  APP_HEIGHT: '100%',
  PAGE_HEIGHT: '100%',
  HEADER_HEIGHT: '2.875rem'
};

// Shared between build script and react
(function (exports) {
  exports.Unit = Unit;
}(typeof exports === 'undefined' ? (this.Unit = {}) : exports));

