import { ThunkDispatch } from 'redux-thunk';

import { routes } from '../../constants';
import { alertActions } from '.';
import { userTypes } from '../types';
import { UserService } from '../../services';
import { AppState } from '../reducers';
import {
  getValidRoleAndGroup,
  decodeJwt,
  errorLogger,
  userAuth,
  authorizationFlow,
  getUserInfoFromDecodedJWT
} from '../../helpers';
import stringToBoolean from '../../helpers/string-to-boolean';
import { getRequestErrorCode } from '../../services/service.helpers';
import { isGetAnonymousUserTokenSuccess } from '../../services/user.service.helpers';
import { IUserInfo } from '../../interfaces';

export interface LoginActionTypes {
  type: string;
  userInfo?: IUserInfo | null;
  isSupervisor?: boolean;
  isAnonymous?: boolean;
  activeToken?: string;
  refreshToken?: string,
  userToken?: string;
  anonymousToken?: string;
  userName?: string,
  isPasswordExpired?: boolean;
  isAccountLocked?: boolean;
  userGroup?: string | null;
  sessionExpiryTime?: number | null;
}

const shouldValidateToken = stringToBoolean(process.env.REACT_APP_SHOULD_VALIDATE_TOKEN ?? 'true');

const userSessionEstablished = () => async (
  dispatch: ThunkDispatch<AppState, null, LoginActionTypes>,
  getState
) => {
  const { user: { userInfo: { preferredUsername: email } } } = getState();
  const loginEvent = new CustomEvent('user_session_established', { detail: { email } });
  window.dispatchEvent(loginEvent);
};

/*
 * OAuth Authorization flow actions (KeyCloak integration)
 */
const redirectToSingleSignOnPage = () => async (): Promise<{
  isRedirectSuccess: true;
  errorCode: '';
} | {
  isRedirectSuccess: false;
  errorCode: 'UNKNOWN_ERROR';
}> => {
  try {
    const uri = userAuth[authorizationFlow].getUri();
    window.location.href = uri;

    return { isRedirectSuccess: true, errorCode: '' };
  } catch (error) {
    errorLogger(error);
    return { isRedirectSuccess: false, errorCode: 'UNKNOWN_ERROR' };
  }
};

const loginWithOAuthAuthorizationCode = (
  urlWithAuthorizationCode: string
) => async (
  dispatch: ThunkDispatch<AppState, null, LoginActionTypes>,
): Promise<{
  isAuthorized: true;
  errorCode: '';
} | {
  isAuthorized: false;
  errorCode: 'UNKNOWN_ERROR' | 'UNAUTHORIZED_GROUP';
}> => {
  try {
    const user = await userAuth[authorizationFlow].getToken(urlWithAuthorizationCode);

    if (!user) {
      throw new Error('UNKNOWN_ERROR');
    }

    const { accessToken, refreshToken } = user;

    if (accessToken) {
      const decodedJwt: any = await decodeJwt(accessToken, shouldValidateToken);

      if (!decodedJwt) {
        throw new Error('INVALID_USER_TOKEN');
      }

      const userInfo: IUserInfo = getUserInfoFromDecodedJWT(decodedJwt);

      const { group: decodedGroup, role: decodedRole } = userInfo.groups
        ? getValidRoleAndGroup(userInfo.groups)
        : { group: '', role: '' };

      if (decodedGroup) {
        dispatch({
          type: userTypes.LOGIN_SUCCESS,
          userToken: accessToken,
          refreshToken,
          isSupervisor: decodedRole === 'supervisor',
          userGroup: decodedGroup,
          userInfo,
          userName: userInfo.name,
          sessionExpiryTime: (decodedJwt?.exp || 0) * 1000,
        });

        return { isAuthorized: true, errorCode: '' };
      }
      throw new Error('UNAUTHORIZED_GROUP');
    }

    throw new Error('UNKNOWN_ERROR');
  } catch (error) {
    errorLogger(error);

    const filteredErrorCode = [
      'UNAUTHORIZED_GROUP',
    ].includes(error.message)
      ? error.message
      : 'UNKNOWN_ERROR';

    return { isAuthorized: false, errorCode: filteredErrorCode };
  }
};

const getAnonymousUserToken = (
  accessCode: string, anonymousUserId: string, linkId: string
) => async (): Promise<{
  accessToken: string | null;
}> => {
  try {
    const data = await UserService.getAnonymousUserToken(accessCode, anonymousUserId, linkId);

    if (!isGetAnonymousUserTokenSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return { accessToken: data.access_token };
  } catch (error) {
    errorLogger(error);
    return { accessToken: null };
  }
};

const logout = (shouldRedirect = true) => (
  dispatch: ThunkDispatch<AppState, null, LoginActionTypes>,
  getState
) => {
  const { user: { isAnonymous } } = getState();
  // Custom Event to fire logger
  const logoutEvent = new CustomEvent('logout');
  window.dispatchEvent(logoutEvent);
  dispatch({ type: userTypes.LOGOUT });

  if (shouldRedirect) {
    const loginUrl = `${window.location.origin}${process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL}${routes.login}`;
    const timeoutUrl = `${window.location.origin}${process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL}${routes.sessionTimeout}`;
    const logoutUrl = process.env.REACT_APP_OPENID_END_SESSION_ENDPOINT ?? '';

    // if user is anonymous user, show session timeout page instead
    window.location.href = isAnonymous ? timeoutUrl : `${logoutUrl}?redirect_uri=${loginUrl}`;
  }
};

const loginAsAnonymousUser = (
  token: string, anonymousUserName: string
) => async (
  dispatch: ThunkDispatch<AppState, null, LoginActionTypes>,
): Promise<{
  isAuthorized: true;
  errorCode: '';
} | {
  isAuthorized: false;
  errorCode: 'UNKNOWN_ERROR' | 'UNAUTHORIZED_GROUP';
}> => {
  try {
    const decodedJwt: any = await decodeJwt(token, shouldValidateToken);

    if (!decodedJwt) {
      throw new Error('INVALID_USER_TOKEN');
    }

    dispatch({
      type: userTypes.ANONYMOUS_LOGIN_SUCCESS,
      anonymousToken: token,
      activeToken: token,
      userName: anonymousUserName,
      isSupervisor: false,
      sessionExpiryTime: (decodedJwt?.exp || 0) * 1000,
    });

    return { isAuthorized: true, errorCode: '' };
  } catch (error) {
    errorLogger(error);

    const filteredErrorCode = [
      'UNAUTHORIZED_GROUP',
    ].includes(error.message)
      ? error.message
      : 'UNKNOWN_ERROR';

    return { isAuthorized: false, errorCode: filteredErrorCode };
  }
};

const setUserToken = (token: string) => ({
  type: userTypes.SET_USER_TOKEN,
  userToken: token,
});

const unsetAnonymousIdToken = () => ({
  type: userTypes.UNSET_ANONYMOUS_ID_TOKEN,
});

const setActiveToken = (token?: string) => ({
  type: userTypes.SET_ACTIVE_TOKEN,
  activeToken: token
});

const refreshUserToken = () => async (
  dispatch: ThunkDispatch<AppState, null, LoginActionTypes>,
  getState
) => {
  dispatch({ type: userTypes.REFRESH_USER_TOKEN });
  try {
    const { user: { activeToken, refreshToken } } = getState();

    if (!refreshToken) {
      throw new Error('NO_REFRESH_TOKEN');
    }

    const token = userAuth.createToken(activeToken, refreshToken, {});
    const updatedUser = await token.refresh();

    if (!updatedUser) {
      throw new Error('MAX_SESSION_TIME_REACHED');
    }

    dispatch(setActiveToken(updatedUser.accessToken));
    dispatch(setUserToken(updatedUser.accessToken));
    return { isRefreshSuccess: true };
  } catch (error) {
    const errorTranslationKey = ['NO_REFRESH_TOKEN', 'MAX_SESSION_TIME_REACHED'].includes(error.message)
      ? `userAction.refreshUserToken.${error.message}`
      : 'userAction.refreshUserToken.MAX_SESSION_TIME_REACHED';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { isRefreshSuccess: false };
  }
};

export const userActions = {
  redirectToSingleSignOnPage,
  loginWithOAuthAuthorizationCode,
  getAnonymousUserToken,
  logout,
  setUserToken,
  loginAsAnonymousUser,
  unsetAnonymousIdToken,
  setActiveToken,
  refreshUserToken,
  userSessionEstablished
};
