import styled from 'styled-components';
import { StyledInputProps } from '../../types';

const StyledInput = styled.input.attrs(() => ({ className: 'ant-input' }))<StyledInputProps>`
  width:${props => (props.width ? `${props.width}rem` : '100%')};

  &.ant-input {
    border-color: ${props => (
      props.userColor
        ? props.userColor
        : props.error
          ? props.theme.color.alert
          : props.theme.color.border
    )};
    background-color:  ${props => (props.error
    ? props.theme.color.lightAlert
    : props.theme.color.white)};
  }

  &.ant-input:disabled {
    background-color: ${props => props.theme.color.disabledBackground};
    color: ${props => props.theme.color.disabledText};

    &:hover,
    &:focus {
      border-color: ${props => (
        props.userColor
          ? props.userColor
          : props.error
            ? props.theme.color.alert
            : props.theme.color.border
      )};
    }
  }

  &:required, &:invalid {
    box-shadow: none;
  }
`;

export default StyledInput;
