// Error Logging Service
import * as Sentry from '@sentry/react';

const loginListener = () => {
  // Listen for custom login event
  const logUserEmail = (e: CustomEventInit) => {
    const { detail: { email } } = e;
    Sentry.configureScope(scope => scope.setUser({ email }));
  };

  window.addEventListener('user_session_established', logUserEmail, false);
};

const logoutListener = () => {
  // Listen for custom logout event
  window.addEventListener('logout', () => {
    Sentry.configureScope(scope => scope.setUser(null));
  }, false);
};

const catchedErrorListener = () => {
  // Log catched error to Sentry
  window.addEventListener('catched_error', (e: CustomEventInit) => {
    const { detail: { error } } = e;
    Sentry.captureException(error);
  }, false);
};

const sentryInit = () => {
  const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENV, REACT_APP_COMMIT_HASH } = process.env;

  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: REACT_APP_SENTRY_ENV || 'Development',
      release: REACT_APP_COMMIT_HASH
    });

    loginListener();
    logoutListener();
    catchedErrorListener();
  }
};

export default sentryInit;
