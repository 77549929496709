import moment from 'moment';

export const fileNameFormatter = (
  filename: string,
  appendTime: boolean,
  extension: string
) => {
  const validFileNameParts = filename && typeof filename === 'string' && filename.match(/[a-z0-9]+/gi);
  const formattedFileName = (validFileNameParts && validFileNameParts.join('_')) || extension;
  const timestamp = moment().format('YYYYMMDDHHmmss');

  return appendTime
    ? `${formattedFileName}_${timestamp}.${extension}`
    : `${formattedFileName}.${extension}`;
};

export const limitFileName = (filename: string, characterLimit: number) => {
  const filenameCheckArray = filename.match(/(.+?)(\.[^.]+$|$)/);
  const newFileName = filenameCheckArray && characterLimit > filenameCheckArray[2].length
    ? `${filenameCheckArray[1].substr(0, characterLimit - filenameCheckArray[2].length)}${filenameCheckArray[2]}`
    : filename;
  return newFileName;
};
