const numberToFaceValue = (
  input: number,
  currency = 'USD', // To show the $ symbol, does matter which currency user is selected in case
  decimalPoint = 2,
): string => (
  input.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPoint,
  })
);

export default numberToFaceValue;
