/* eslint-disable camelcase */
import { IFetchGeoLocationSuccess, IFetchGeoLocationFailure } from './external.service.types';

export const hasFetchGeoLocationErrorCode = (object: any): boolean => object?.error_message;

export const getFetchGeoLocationErrorCode = (object: IFetchGeoLocationFailure): string | null => (
  object?.error_message ?? null
);

export const isFetchGeoLocationSuccess = (
  object: IFetchGeoLocationSuccess | IFetchGeoLocationFailure
): object is IFetchGeoLocationSuccess => object && !hasFetchGeoLocationErrorCode(object);
