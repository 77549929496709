import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Modal from '.';

interface ICustomerSupportModal {
  visible: boolean;
  phoneNumber: string;
  email: string;
  onCloseModal: () => void;
}

const CustomerSupportModal: FC<ICustomerSupportModal> = ({
  visible,
  phoneNumber,
  email,
  onCloseModal
}) => {
  const intl = useIntl();

  return (
    <Modal
      width={370}
      visible={visible}
      title={intl.formatMessage({ id: 'customerSupportModal.title' })}
      description={intl.formatMessage({ id: 'customerSupportModal.description' })}
      closable
      onCancelButtonClick={onCloseModal}
      fullScreenInMobile
    >
      <div>
        <p>{intl.formatMessage({ id: 'customerSupportModal.phone.label' })}{phoneNumber}</p>
        <p>{intl.formatMessage({ id: 'customerSupportModal.email.label' })}{email}</p>
      </div>
      <br />
      <div>
        <p>
          {intl.formatMessage({ id: 'customerSupportModal.serviceHours.label' })}
          {intl.formatMessage({ id: 'customerSupportModal.serviceHours.text' })}
        </p>
      </div>
    </Modal>
  );
};

export default CustomerSupportModal;
