// Provide conditional-rendering that React-Route does not have at this moment
// See the discussion in https://github.com/ReactTraining/react-router/issues/6767

import React, { FC } from 'react';
import { Route as ReactRoute, Redirect, RouteProps } from 'react-router-dom';
import { checkUnsupportedBrowser } from '../../helpers';
import { routes } from '../../constants';

interface IRoute extends RouteProps {
  shouldNotRender?: boolean;
}

const isBrowserUnsupported = checkUnsupportedBrowser();

const Route: FC<IRoute> = ({
  shouldNotRender = false,
  ...props
}) => (shouldNotRender
  ? null
  : isBrowserUnsupported && props.path !== routes.unsupportedBrowser
    ? <Redirect to={{ pathname: routes.unsupportedBrowser }} />
    : !isBrowserUnsupported && props.path === routes.unsupportedBrowser
      ? <Redirect to={{ pathname: '/' }} />
      : <ReactRoute {...props} />
);

export default Route;
