import React from 'react';
import styled from 'styled-components';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input/TextArea.d';
import TextArea from 'antd/lib/input/TextArea';

export interface TextAreaProps extends AntdTextAreaProps {
  error: string;
  userColor?: string;
}

const StyledTextArea = styled(props => {
  const { userColor, error, ...textAreaProps } = props;
  return <TextArea {...textAreaProps} />;
}) <TextAreaProps>`
  width: 100%;
  padding: 0.375rem;

  &.ant-input, &.ant-input:hover {
    border: 0.0625rem solid ${props => (
      props.userColor
        ? props.userColor
        : props.error
          ? props.theme.color.alert
          : props.theme.color.border
    )};

    background-color: ${props => (props.error
      ? props.theme.color.lightAlert
      : props.theme.color.white)};
  }

  &.ant-input:disabled {
    background-color: ${props => props.theme.color.disabledBackground};
    color: ${props => props.theme.color.disabledText};
    -webkit-text-fill-color: ${props => props.theme.color.disabledText};
    opacity: 1;

    &:hover {
      border: 0.0625rem solid ${props => (
        props.userColor
          ? props.userColor
          : props.error
            ? props.theme.color.alert
            : props.theme.color.border
      )};
      cursor: default;
    }
  }
`;

export default StyledTextArea;
