import React, { FC } from 'react';
import QRCode from 'qrcode.react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'antd';
import Modal from './Modal';

interface IShareModal {
  showShareModal: boolean;
  onClose: () => void;
  onCreateLink: () => Promise<void>;
  cobrowsingLink: string | null;
  isCreatingCobrowsingLink: boolean;
  guestName: string;
}

const ShareContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.unit.space_lg};
  width: 80%;
`;

const QRCodeWrapper = styled.div<{ isLoading: boolean }>`
  padding: ${props => props.theme.unit.space_sm};
  border: 0.1875rem solid
    ${props => (props.isLoading ? props.theme.color.primary : props.theme.color.black)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.unit.space_sm};
`;

const ShareModal: FC<IShareModal> = ({
  showShareModal,
  onClose,
  cobrowsingLink,
  onCreateLink,
  isCreatingCobrowsingLink,
  guestName,
}) => {
  const intl = useIntl();

  return (
    <Modal
      visible={showShareModal}
      title={intl.formatMessage({ id: 'shareModal.title' })}
      cancelButtonLabel={cobrowsingLink
        ? undefined
        : intl.formatMessage({ id: 'shareModal.cancelButton' })}
      onCancelButtonClick={onClose}
      okButtonLabel={cobrowsingLink
        ? undefined
        : intl.formatMessage({ id: 'shareModal.inviteButton' })}
      okButtonLoading={isCreatingCobrowsingLink}
      onOkButtonClick={onCreateLink}
      closable={!!cobrowsingLink}
      destroyOnClose
      className="share-modal"
    >
      {cobrowsingLink
        ? (
          <ShareContent>
            <QRCodeWrapper
              id="modal__share__qrwrapper"
              className="formbuilder__modal__share__qrwrapper"
              isLoading={false}
            >
              <QRCode
                id="modal__share__qrcode"
                className="formbuilder__modal__share__qrcode"
                value={cobrowsingLink}
                includeMargin={false}
              />
            </QRCodeWrapper>

            <StyledButton
              id="modal__share__button"
              className="formbuilder__modal__share__button"
              icon="link"
              size="large"
              type="primary"
              data-copy-url={cobrowsingLink}
            >
              {intl.formatMessage({ id: 'shareModal.copyButton' })}
            </StyledButton>
          </ShareContent>
        )
        : intl.formatMessage({
          id: 'shareModal.inviteMessage'
        }, {
          USER_FULL_NAME: guestName
        })}
    </Modal>
  );
};

export default ShareModal;
