/* eslint-disable class-methods-use-this */
import React from 'react';
import { Parser, Tag } from 'bbcode-to-react';

class BreakTag extends Tag {
  toReact() {
    return (
      <br />
    );
  }
}

const parser = new Parser();
parser.registerTag('br', BreakTag as typeof Tag);

const parseBbcode = (bbcodeString: string) => parser.toReact(bbcodeString);

export default parseBbcode;
