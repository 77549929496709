// TODO: Delete this and get it from formspec
const usualResidency = {
  options: [
    {
      key: 'Standard',
      value: 'Standard'
    },
    {
      key: 'Preferred',
      value: 'Preferred'
    }]
};

export default usualResidency;
