const AppConstants = {
  VERSION: '1.3.0',
  TENANT: process.env.REACT_APP_TENANT,
  UNSUPPORTED_BROWSER_VERSION: {
    ie: 11,
    firefox: 24,
    chrome: 48,
    safari: 9,
  },
  ADMIN_CONTACT_EMAIL: process.env.REACT_APP_ADMIN_CONTACT_EMAIL || 'contactus@coherent.com.hk',
  ADMIN_CONTACT_PHONE: process.env.REACT_APP_ADMIN_CONTACT_PHONE_NUMBER || '+852-1234 5678',
  PRODUCT_DOMAIN: process.env.REACT_APP_PRODUCT_DOMAIN || 'epos'
};

export default AppConstants;
