interface IDisplayNameOptions {
  lastNameInFront?: boolean;
  lastNameUpperCase?: boolean;
}

export interface INameData {
  fullName: string;
  lastName: string;
  firstName: string;
}

interface IDisplayNameFormatter {
  (lastName: string, firstName: string): string;
  (lastName: string, firstName: string, returnAs:'string'): string;
  (lastName: string, firstName: string, returnAs:'object'): INameData;
}

const defaultOptions: IDisplayNameOptions = {
  lastNameInFront: true,
  lastNameUpperCase: true
};

// Return type `any` is need, see:
// https://github.com/microsoft/TypeScript/issues/33482#issuecomment-532699243
const displayNameFormatter: IDisplayNameFormatter = (
  lastName: string,
  firstName: string,
  returnAs?: 'string' | 'object',
  options?: IDisplayNameOptions,
): any => {
  const displayOptions = options ?? defaultOptions;
  const firstNameToBeDisplayed: string = typeof firstName === 'string' ? firstName : '';
  let lastNameToBeDisplayed: string = typeof lastName === 'string' ? lastName : '';

  if (displayOptions.lastNameUpperCase) {
    lastNameToBeDisplayed = lastNameToBeDisplayed.toUpperCase();
  }

  const displayName = displayOptions.lastNameInFront
    ? `${lastNameToBeDisplayed} ${firstNameToBeDisplayed}`
    : `${firstNameToBeDisplayed} ${lastNameToBeDisplayed}`;

  if (returnAs === 'object') {
    return {
      fullName: displayName.trim(),
      lastName: lastNameToBeDisplayed,
      firstName: firstNameToBeDisplayed
    };
  }

  return displayName.trim();
};

export default displayNameFormatter;
