import { TimeActionTypes } from '../actions';

export interface TimeStateType extends Required<Omit<TimeActionTypes, 'type'>> {
  isAbsTimeoutModalClicked: boolean;
}

export const timeInitialState: TimeStateType = {
  startTime: null,
  isAbsTimeoutModalClicked: false,
};
