import { get, set, unset } from 'lodash';

export const constructFormSpec = (spec: any) => {
  const specFields = {};
  const specFieldsByPath = {};
  const signatureFields: any[] = [];
  const specPreProcessors: { [jsonPath: string]: any[] } = {};

  function setFields(obj) {
    const {
      name,
      jsonPath,
      triggerStateUpdate,
      type,
      sectionIndex,
      sectionName,
      subSectionIndex,
      subSectionName,
      metadata,
    } = obj;

    specFields[name] = {
      jsonPath,
      triggerStateUpdate,
      sectionIndex,
      sectionName,
      subSectionIndex,
      subSectionName,
      type,
      metadata,
    };
  }

  function setFieldsByPath(obj) {
    const {
      name,
      jsonPath,
      triggerStateUpdate,
      type,
      sectionIndex,
      sectionName,
      subSectionIndex,
      subSectionName,
      metadata,
    } = obj;

    specFieldsByPath[jsonPath] = {
      name,
      triggerStateUpdate,
      sectionIndex,
      sectionName,
      subSectionIndex,
      subSectionName,
      type,
      metadata
    };
  }

  function setSignatureFields(obj) {
    const {
      name,
      jsonPath,
      sectionIndex,
      sectionName,
      subSectionName,
    } = obj;
    signatureFields.push({
      name,
      jsonPath,
      sectionIndex,
      sectionName,
      subSectionName
    });
  }

  function handleEntity(obj) {
    return obj.type === 'entity'
      && obj.metadata?.fields?.forEach(field => handleField({ sectionIndex: obj.sectionIndex, ...field }));
  }

  function handleEntities(obj) {
    return obj.type === 'entities'
      && obj.metadata?.fields?.forEach(entity => handleEntity({ sectionIndex: obj.sectionIndex, ...entity }));
  }

  function handlePDFSignature(obj) {
    const {
      name,
      jsonPath,
      triggerStateUpdate,
      sectionIndex,
      sectionName,
      subSectionIndex,
      subSectionName,
      metadata,
    } = obj;

    if (metadata?.preProcessors && metadata.pdfPath) {
      specPreProcessors[metadata.pdfPath] = metadata.preProcessors;
    }
    metadata?.signaturePath.forEach(path => handleField({
      name,
      jsonPath: `${jsonPath}.${path}`,
      triggerStateUpdate,
      type: 'Signature',
      sectionIndex,
      sectionName,
      subSectionIndex,
      subSectionName,
      metadata: {},
    }));
  }

  function handleField(obj) {
    if (obj.jsonPath) {
      switch (obj.type) {
        case 'entities':
          handleEntities(obj);
          break;
        case 'entity':
          handleEntity(obj);
          break;
        case 'PDFSignature':
          handlePDFSignature(obj);
          break;
        case 'Signature':
          setSignatureFields(obj);
          setFields(obj);
          setFieldsByPath(obj);
          break;
        default: // Default as normal field
          setFields(obj);
          setFieldsByPath(obj);
      }
    }
  }

  if (typeof spec === 'object' && Boolean(spec)) {
    spec.sections.forEach(
      ({ subSections, name: sectionName }, sectionIndex) => subSections?.forEach(
        ({ fields, name: subSectionName }, subSectionIndex) => fields?.forEach(
          field => handleField({
            ...field, sectionIndex, sectionName, subSectionIndex, subSectionName
          })
        )
      )
    );
    return {
      ...spec,
      fields: specFields,
      fieldsByPath: specFieldsByPath,
      signatureFields,
      preProcessors: specPreProcessors,
    };
  }
  return undefined;
};

export const updateJSONData = (data, jsonPath: string, value) => {
  if (value === undefined) {
    /**
     * Lodash unset will only delete the property of the jsonPath only { a: { b: 1 } }
     * if the parent of the jsonPath position has only one child
     * after deletion that parent will have a {} as value { a: {} }
     * proper way is to also delete the parent in this case, recursively
     */
    let jsonPathToBeDeleted = jsonPath;
    const jsonPathArray = jsonPath.split('.');
    for (let i = jsonPathArray.length - 1; i > 0; i--) {
      const currentJsonPath = jsonPathArray.slice(0, i).join('.');
      if (Object.keys(get(data, currentJsonPath, {})).length > 1) {
        break;
      } else {
        jsonPathToBeDeleted = currentJsonPath;
      }
    }
    unset(data, jsonPathToBeDeleted);
  } else {
    set(data, jsonPath, value);
  }
};
