import React, { FC, ChangeEvent } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import styled from 'styled-components';
import FormLabel, { FormLabelProps } from './FormLabel';
import FormItem, { NewFormItemProps } from './FormItem';
import FormFieldGrid, { FormFieldGridProps } from './FormFieldGrid';

interface IInput extends InputProps {
  id?: string;
  name: string;
  placeholder?: string;
  type?: string;
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  width?: number;
}

interface FormInputProps
  extends Omit<FormLabelProps, 'name'>,
  IInput,
  NewFormItemProps,
  FormFieldGridProps {}

const InputByType = ({ type, ...props }) => (type === 'password' ? <Input.Password {...props} /> : <Input {...props} />);

const StyledInput = styled(({ error, ...props }) => <InputByType {...props} />)<IInput>`
  &.ant-input, & .ant-input {
    height: 2.75rem;
  }

  /* Chrome autofill background */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px
      ${props => (props.error
        ? props.theme.color.lightAlert
        : props.theme.color.white)}
      inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const FormInput: FC<FormInputProps> = ({
  id, maxLength, autoFocus, ...props
}) => {
  const {
    label,
    name,
    placeholder,
    type,
    value,
    onChange,
    disabled,
    error,
    help,
    onBlur,
    inputMode,
    pattern,
  } = props;

  return (
    <FormFieldGrid {...props}>
      {label && <FormLabel {...props} />}

      <FormItem
        error={error}
        help={help}
      >
        <StyledInput
          id={id}
          name={name}
          inputMode={inputMode}
          pattern={pattern}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          maxLength={maxLength}
          autoFocus={autoFocus}
          error={error}
        />
      </FormItem>
    </FormFieldGrid>
  );
};

export default FormInput;
