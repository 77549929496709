import { makeRequest } from '../helpers';
import { IFetchGeoLocation } from './external.service.types';

const geoLocationApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const azureFaceSubscriptionKey = process.env.REACT_APP_AZURE_FACE_SUBSCRIPTION_KEY;

const fetchGeoLocation = async (
  latitude: number,
  longitude: number,
  language,
  key = geoLocationApiKey,
): Promise<IFetchGeoLocation> => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${language}&key=${key}`;
  return (await makeRequest({
    method: 'GET',
    url,
    headers: { 'Content-Type': null },
  }, {
    shouldUseToken: false,
  })).data;
};

// TODO: apply strong typing to azureFaceDetect
const azureFaceDetect = (imageUrl: string) => {
  const url = 'https://facialverification.cognitiveservices.azure.com/face/v1.0/detect';

  return makeRequest({
    method: 'POST',
    url,
    data: { url: imageUrl },
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': azureFaceSubscriptionKey,
    }
  }, {
    shouldUseToken: false,
  });
};

// TODO: apply strong typing to azureFaceVerify
const azureFaceVerify = (faceId1: string, faceId2: string) => {
  const url = 'https://facialverification.cognitiveservices.azure.com/face/v1.0/verify';

  return makeRequest({
    method: 'POST',
    url,
    data: {
      faceId1,
      faceId2,
    },
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': azureFaceSubscriptionKey,
    }
  }, {
    shouldUseToken: false,
  });
};

// TODO: apply strong typing to getExternalPDF
const getExternalPDF = async (
  externalURL: string
) => {
  const responseType = 'blob';
  const contentType = 'application/pdf';

  return (await makeRequest({
    method: 'GET',
    url: externalURL,
    responseType,
    headers: { 'Content-Type': contentType }
  }, {
    shouldUseToken: false,
  })).data;
};

export default {
  fetchGeoLocation,
  azureFaceDetect,
  azureFaceVerify,
  getExternalPDF
};
