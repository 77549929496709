import { pageTypes } from '../types';

export interface PageActionTypes {
  type: string;
  currentPageId: string;
  referrerUri?: string;
}

const setCurrentPageId = (currentPageId: string) => ({
  type: pageTypes.SET_CURRENT_PAGE,
  currentPageId,
});

const setReferrerUri = (referrerUri: string) => ({
  type: pageTypes.SET_REFERRER_URI,
  referrerUri
});

export const pageActions = {
  setCurrentPageId,
  setReferrerUri
};
