import { userTypes } from '../types';
import { LoginActionTypes } from '../actions';
import { UserStateType, userInitialState } from '../states/user.states';

const userReducer = (
  state: UserStateType = userInitialState,
  action: LoginActionTypes
): UserStateType => {
  switch (action.type) {
    case userTypes.GET_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.userInfo || null,
        userName: action.userName || ''
      };
    case userTypes.LOGIN_CHANGE_PASSWORD_REQUIRED:
      return {
        ...state,
        userToken: state.userToken,
        refreshToken: state.refreshToken,
        isPasswordExpired: true,
        isAccountLocked: false,
      };
    case userTypes.LOGIN_RESET_PASSWORD_REQUIRED:
      return {
        ...state,
        userToken: state.userToken,
        refreshToken: state.refreshToken,
        isPasswordExpired: false,
        isAccountLocked: true,
      };
    case userTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userToken: action.userToken || '',
        anonymousToken: '',
        refreshToken: action.refreshToken || '',
        isPasswordExpired: false,
        isAccountLocked: false,
        isSupervisor: action.isSupervisor || false,
        isAnonymous: false,
        userInfo: action.userInfo || null,
        userName: action.userName || '',
        userGroup: action.userGroup || null,
        sessionExpiryTime: action.sessionExpiryTime || null,
      };
    case userTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordExpired: false,
        isAccountLocked: false,
      };
    case userTypes.ANONYMOUS_LOGIN_SUCCESS:
      return {
        ...state,
        userToken: '',
        anonymousToken: action.anonymousToken || state.anonymousToken,
        activeToken: action.anonymousToken || state.activeToken,
        userName: action.userName || '',
        isPasswordExpired: false,
        isAccountLocked: false,
        isSupervisor: false,
        isAnonymous: true,
        userGroup: null,
        sessionExpiryTime: action.sessionExpiryTime || null,
      };
    case userTypes.LOGOUT:
      return userInitialState;
    case userTypes.SET_USER_TOKEN:
      return {
        ...state,
        userToken: action.userToken || '',
      };
    case userTypes.UNSET_ANONYMOUS_ID_TOKEN:
      return {
        ...state,
        anonymousToken: '',
      };
    case userTypes.SET_ACTIVE_TOKEN:
      return {
        ...state,
        activeToken: action.activeToken || state.userToken,
      };
    default:
      return state;
  }
};

export default userReducer;
