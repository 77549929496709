import { sidebarTypes } from '../types';

export interface SidebarActionTypes {
  type: string;
  customerSupportModalVisible: boolean
}

const showCustomerSupportModal = () => ({
  type: sidebarTypes.SET_CUSTOMER_SUPPORT_MODAL_VISIBLE,
  customerSupportModalVisible: true
});

const hideCustomerSupportModal = () => ({
  type: sidebarTypes.SET_CUSTOMER_SUPPORT_MODAL_VISIBLE,
  customerSupportModalVisible: false
});

export const sidebarActions = {
  showCustomerSupportModal,
  hideCustomerSupportModal,
};
