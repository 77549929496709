import { stringifyUrl } from 'query-string';
import {
  makeRequest, extractPdfPath, colorHexToRgb, removeSignature, isValidUuid
} from '../helpers';

import { IGetMergedPdfUrl, IGetPdfImage } from './pdf.service.types';
import { apiEndpointDomians } from '../constants';

const { PDFSERVICE_DOMAIN_WITH_PATH } = apiEndpointDomians;

/*
 * Merge multiple PDFs into single PDF:
 * API ducumentation not found
 *
 * PreProcessors format:
 * https://github.com/CoherentCapital/PDFServices/blob/dev/docs/pdffieldinjection.md
 */
// TODO: Ask for API owners' help for documentation
const getMergedPdfUrl = async (
  coreData: string,
  docStoreFiles: {
    FormName: string; DocStoreFilePath?: string, PreProcessors?: any, DocumentId?: string
  }[],
  filename: string | undefined = undefined,
  mode: 'pdf' | 'zip' = 'pdf',
): Promise<IGetMergedPdfUrl> => {
  const url = stringifyUrl(
    {
      url: `${PDFSERVICE_DOMAIN_WITH_PATH}/pdfmerge/generatepdf`,
      query: { outputformat: mode, filename: filename ?? null },
    },
    { skipNull: true }
  );
  const payload = docStoreFiles.map(({
    DocStoreFilePath, FormName, PreProcessors, DocumentId
  }) => {
    const extractedPath = extractPdfPath(DocStoreFilePath ?? '');
    const docFileConfig: any = {
      FormName,
      FormFields: coreData,
      ReadUsingUserToken: extractedPath.indexOf('cases/') === 0,
    };

    // 'DocStoreFilePath' get higher priority if both 'DocumentId' and 'DocStoreFilePath' are passed
    if (DocumentId) {
      docFileConfig.DocumentId = DocumentId;
      docFileConfig.DocStoreFilePath = extractedPath;
    } else if (DocStoreFilePath) {
      docFileConfig.DocStoreFilePath = extractedPath;
    }
    if (PreProcessors) {
      docFileConfig.PreProcessors = PreProcessors;
    }
    return docFileConfig;
  });

  return makeRequest({ method: 'POST', url, data: payload });
};

/*
 * Convert PDF to images
 *
 * Generate PDF image:
 * https://github.com/CoherentCapital/PDFServices/blob/dev/docs/APIDoc.md#generate-image-api
 *
 * PreProcessors format:
 * https://github.com/CoherentCapital/PDFServices/blob/dev/docs/pdffieldinjection.md
 */
const getPdfImage = async (
  pdfTitle: string,
  caseData: any,
  preProcessors: object,
  color: string,
  includeSignature: boolean,
  pdfPath?: string,
  base64Pdf?: string
): Promise<IGetPdfImage> => {
  const url = `${PDFSERVICE_DOMAIN_WITH_PATH}/pdfmerge/generateimage`;
  const rgb = colorHexToRgb(color);

  // 'DocStoreFilePath' get higher priority if both 'DocumentId' and 'DocStoreFilePath' are passed
  // 'base64Pdf' has highest priority in Front-end logic, as it doesn't require accessing DocStore
  let documentParams = {};
  if (base64Pdf) {
    documentParams = { base64Pdf };
  } else if (pdfPath) {
    documentParams = isValidUuid(pdfPath) ? { DocumentId: pdfPath } : { DocStoreFilePath: pdfPath };
  }

  const data = {
    FormFields: includeSignature ? caseData : removeSignature(caseData, false),
    FormName: pdfTitle,
    ...documentParams,
    FontColor: {
      R: rgb ? rgb.red : 0,
      B: rgb ? rgb.blue : 0,
      G: rgb ? rgb.green : 0
    },
    TempGenerate: true,
    PreProcessors: preProcessors,
    /*
     * PDFLibType - Optional, supports 'XFinium' and 'IronPDF'.
     * If not defined then PDF Service will use XFinium to render by default
     * We switched to IronPDF as it have better support for Chinese characters
     */
    PDFLibType: 'IronPDF'
  };

  return (await makeRequest({ method: 'POST', url, data })).data;
};

const PdfService = {
  getMergedPdfUrl,
  getPdfImage
};

export default PdfService;
