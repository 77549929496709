import { translationDictFromLocale } from '../../constants';
import { LanguagesActionTypes } from '../actions';

export type LanguagesStateTypes = Required<Omit<LanguagesActionTypes, 'type'>>;

export const languagesInitialState: LanguagesStateTypes = {
  currentLanguage: 'en',
  translationDict: translationDictFromLocale,
  formSpecVersion: '',
};
