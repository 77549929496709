import { isEqual } from 'lodash';
import { alertTypes } from '../types';
import { AlertActionTypes } from '../actions';
import { AlertStateType, alertInitialState } from '../states/alert.states';

const alertReducer = (
  state: AlertStateType = alertInitialState,
  {
    type,
    msgType,
    msgContent,
    msgDescription,
    customProps,
    component,
  }: AlertActionTypes
): AlertStateType => {
  switch (type) {
    case alertTypes.SET_MESSAGE:
      return isEqual({
        msgType, msgContent, msgDescription, customProps, component,
      }, state)
        ? state
        : {
          msgType: msgType ?? '',
          msgContent: msgContent ?? '',
          msgDescription: msgDescription ?? '',
          customProps: customProps ?? {},
          component: component ?? 'message',
        };
    case alertTypes.CLEAR_MESSAGE:
      return alertInitialState;
    default:
      return state;
  }
};

export default alertReducer;
