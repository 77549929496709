import commonTranslation from './common.json';
import loginTranslation from './login.json';
import sidebarTranslation from './sidebar.json';
import casesTranslation from './cases.json';
import applicationTranslation from './application.json';
import formBuilderTranslation from './formbuilder.json';
import homeTranslation from './home.json';
import templateTranslation from './template.json';
import errorTranslation from './errors.json';
import productsTranslation from './products.json';
import salesTranslation from './sales.json';
import modalTranslation from './modal.json';
import unsupportedBrowserTranslation from './unsupported-browser.json';
import maintenanceNoticeTranslation from './maintenance-notice.json';

const es: { [s: string]: string | object } = {
  ...commonTranslation,
  ...loginTranslation,
  ...sidebarTranslation,
  ...casesTranslation,
  ...applicationTranslation,
  ...formBuilderTranslation,
  ...homeTranslation,
  ...templateTranslation,
  ...errorTranslation,
  ...productsTranslation,
  ...salesTranslation,
  ...modalTranslation,
  ...unsupportedBrowserTranslation,
  ...maintenanceNoticeTranslation,
};

export default es;
