import { IconProps } from 'antd/lib/icon';
// CANNOT IMPORT FROM HELPER INDEX.TS DUE TO CIRCULAR REFERENCING
import history from '../helpers/history';
import stringToBoolean from '../helpers/string-to-boolean';
import { CoherentConnectIcon, CoherentLeadsIcon } from '../components/Icon/CustomIcons';

interface ISideBarItemBase {
  icon: IconProps['component'] | IconProps['type'];
  pageId: string;
  disabled?: boolean;
  url?: string;
}

interface ISideBarItem extends ISideBarItemBase {
  pageName: string;
  onClick: () => void;
}

const DISABLE_PRODUCTS = stringToBoolean(process.env.REACT_APP_DISABLE_PRODUCTS_ROUTE ?? 'false');
const DISABLE_TEMPLATES = stringToBoolean(process.env.REACT_APP_DISABLE_TEMPLATES_ROUTE ?? 'false');
const DISABLE_QUICK_QUOTE = stringToBoolean(process.env.REACT_APP_DISABLE_QUICK_QUOTE_ROUTE ?? 'false');
const DISABLE_NEW_APPLICATION = stringToBoolean(process.env.REACT_APP_DISABLE_NEW_APPLICATION_ROUTE ?? 'false');
const DISABLE_LEADS = stringToBoolean(process.env.REACT_APP_DISABLE_LEADS_ROUTE ?? 'false');
const DISABLE_CONNECT = stringToBoolean(process.env.REACT_APP_DISABLE_CONNECT_ROUTE ?? 'false');

const SidebarItemsBase: ISideBarItemBase[] = [
  {
    icon: 'home',
    pageId: 'home',
  },
  {
    icon: 'copy',
    pageId: 'templates',
    disabled: DISABLE_TEMPLATES
  },
  {
    icon: 'calculator',
    pageId: 'quick-quote',
    disabled: DISABLE_QUICK_QUOTE
  },
  {
    icon: 'plus',
    pageId: 'application',
    disabled: DISABLE_NEW_APPLICATION
  },
  {
    icon: 'bars',
    pageId: 'cases',
  },
  {
    icon: 'appstore',
    pageId: 'products',
    disabled: DISABLE_PRODUCTS
  },
  {
    icon: CoherentConnectIcon,
    pageId: 'coherent-connect',
    disabled: DISABLE_CONNECT,
    url: process.env.REACT_APP_CONNECT_URL
  },
  {
    icon: CoherentLeadsIcon,
    pageId: 'coherent-leads',
    disabled: DISABLE_LEADS,
    url: process.env.REACT_APP_LEADS_URL
  },
];

const SidebarItems: ISideBarItem[] = SidebarItemsBase.map(item => ({
  ...item,
  pageName: `sidebar.item.${item.pageId}`,
  onClick: item.url
    ? () => window.open(item.url)
    : () => history.push(`/${item.pageId}`),
}));

export default SidebarItems;
