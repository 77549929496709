import {
  ICreateAnonymousUerSuccess,
  IGetAnonymousUserTokenSuccess
} from './user.service.types';
import { IStatusFailure } from './service.types';

export const isCreateAnonymousUerSuccess = (
  object: ICreateAnonymousUerSuccess | IStatusFailure
): object is ICreateAnonymousUerSuccess => !('errorCode' in object);

export const isGetAnonymousUserTokenSuccess = (
  object: IGetAnonymousUserTokenSuccess | IStatusFailure
): object is IGetAnonymousUserTokenSuccess => !('errorCode' in object);
