import styled from 'styled-components';
import { Select } from 'antd';

import { StyledSelectProps } from '../../types';

const { Option, OptGroup } = Select;

const StyledSelect = styled(Select)<StyledSelectProps>`
  &.ant-select-disabled .ant-select-selection {
    background: ${props => props.theme.color.disabledBackground};
    color: ${props => props.theme.color.disabledText};

    &:hover,
    &:focus {
      border-color: ${props => (
        props.userColor
          ? props.userColor
          : props.error
            ? props.theme.color.alert
            : props.theme.color.border
      )};
    }
  }

  width:${props => (props.width ? `${props.width}rem` : '100%')};

  .ant-select-selection {
    border-color: ${props => (
      props.userColor
        ? props.userColor
        : props.error
          ? props.theme.color.alert
          : props.theme.color.border
    )};
    background-color:  ${props => (props.error
    ? props.theme.color.lightAlert
    : props.theme.color.white)};
  }

  &:required, &:invalid {
    box-shadow: none;
  }
`;

export default StyledSelect;
export { Option as SelectOption, OptGroup as SelectOptGroup };
