/* eslint-disable import/no-extraneous-dependencies */

export { default as ExportOutline } from '@ant-design/icons/lib/outline/ExportOutline';

export { default as EyeOutline } from '@ant-design/icons/lib/outline/EyeOutline';

export { default as EyeInvisibleOutline } from '@ant-design/icons/lib/outline/EyeInvisibleOutline';

export { default as DoubleRightOutline } from '@ant-design/icons/lib/outline/DoubleRightOutline';

export { default as DoubleLeftOutline } from '@ant-design/icons/lib/outline/DoubleLeftOutline';

export { default as CalendarOutline } from '@ant-design/icons/lib/outline/CalendarOutline';

export { default as UploadOutline } from '@ant-design/icons/lib/outline/UploadOutline';

export { default as MinusCircleOutline } from '@ant-design/icons/lib/outline/MinusCircleOutline';

export { default as SaveOutline } from '@ant-design/icons/lib/outline/SaveOutline';

export { default as UndoOutline } from '@ant-design/icons/lib/outline/UndoOutline';

export { default as RedoOutline } from '@ant-design/icons/lib/outline/RedoOutline';

export { default as ShareAltOutline } from '@ant-design/icons/lib/outline/ShareAltOutline';

export { default as SearchOutline } from '@ant-design/icons/lib/outline/SearchOutline';

export { default as UpOutline } from '@ant-design/icons/lib/outline/UpOutline';

export { default as DownOutline } from '@ant-design/icons/lib/outline/DownOutline';

export { default as LeftOutline } from '@ant-design/icons/lib/outline/LeftOutline';

export { default as RightOutline } from '@ant-design/icons/lib/outline/RightOutline';

export { default as AppstoreOutline } from '@ant-design/icons/lib/outline/AppstoreOutline';

export { default as AppstoreFill } from '@ant-design/icons/lib/fill/AppstoreFill';

export { default as PlusCircleOutline } from '@ant-design/icons/lib/outline/PlusCircleOutline';

export { default as CopyOutline } from '@ant-design/icons/lib/outline/CopyOutline';

export { default as PrinterOutline } from '@ant-design/icons/lib/outline/PrinterOutline';

export { default as DeleteOutline } from '@ant-design/icons/lib/outline/DeleteOutline';

export { default as QuestionCircleOutline } from '@ant-design/icons/lib/outline/QuestionCircleOutline';

export { default as UnorderedListOutline } from '@ant-design/icons/lib/outline/UnorderedListOutline';

export { default as UserAddOutline } from '@ant-design/icons/lib/outline/UserAddOutline';

export { default as UserGroupAddOutline } from '@ant-design/icons/lib/outline/UsergroupAddOutline';

export { default as CheckCircleFill } from '@ant-design/icons/lib/fill/CheckCircleFill';

export { default as CloseCircleFill } from '@ant-design/icons/lib/fill/CloseCircleFill';

export { default as CloseCircleOutline } from '@ant-design/icons/lib/outline/CloseCircleOutline';

export { default as ExclamationCircleFill } from '@ant-design/icons/lib/fill/ExclamationCircleFill';

export { default as CaretDownFill } from '@ant-design/icons/lib/fill/CaretDownFill';

export { default as CaretUpFill } from '@ant-design/icons/lib/fill/CaretUpFill';

export { default as UserOutline } from '@ant-design/icons/lib/outline/UserOutline';

export { default as ProfileOutline } from '@ant-design/icons/lib/outline/ProfileOutline';

export { default as PlusOutline } from '@ant-design/icons/lib/outline/PlusOutline';

export { default as MinusOutline } from '@ant-design/icons/lib/outline/MinusOutline';

export { default as DatabaseOutline } from '@ant-design/icons/lib/outline/DatabaseOutline';

export { default as FundOutline } from '@ant-design/icons/lib/outline/FundOutline';

export { default as MenuOutline } from '@ant-design/icons/lib/outline/MenuOutline';

export { default as CaretDownOutline } from '@ant-design/icons/lib/outline/CaretDownOutline';

export { default as CaretUpOutline } from '@ant-design/icons/lib/outline/CaretUpOutline';

export { default as LoadingOutline } from '@ant-design/icons/lib/outline/LoadingOutline';

export { default as SwapOutline } from '@ant-design/icons/lib/outline/SwapOutline';

export { default as CheckOutline } from '@ant-design/icons/lib/outline/CheckOutline';

export { default as CloseOutline } from '@ant-design/icons/lib/outline/CloseOutline';

export { default as ArrowRightOutline } from '@ant-design/icons/lib/outline/ArrowRightOutline';

export { default as MedicineBoxOutline } from '@ant-design/icons/lib/outline/MedicineBoxOutline';

export { default as HeartOutline } from '@ant-design/icons/lib/outline/HeartOutline';

export { default as HomeOutline } from '@ant-design/icons/lib/outline/HomeOutline';

export { default as CompassOutline } from '@ant-design/icons/lib/outline/CompassOutline';

export { default as CarOutline } from '@ant-design/icons/lib/outline/CarOutline';

export { default as BankOutline } from '@ant-design/icons/lib/outline/BankOutline';

export { default as DollarOutline } from '@ant-design/icons/lib/outline/DollarOutline';

export { default as CoffeeOutline } from '@ant-design/icons/lib/outline/CoffeeOutline';

export { default as FilePdfOutline } from '@ant-design/icons/lib/outline/FilePdfOutline';

export { default as FormOutline } from '@ant-design/icons/lib/outline/FormOutline';

export { default as PaperClipOutline } from '@ant-design/icons/lib/outline/PaperClipOutline';

export { default as TeamOutline } from '@ant-design/icons/lib/outline/TeamOutline';

export { default as FileTextOutline } from '@ant-design/icons/lib/outline/FileTextOutline';

export { default as CalculatorOutline } from '@ant-design/icons/lib/outline/CalculatorOutline';

export { default as BarsOutline } from '@ant-design/icons/lib/outline/BarsOutline';

export { default as VideoCameraOutline } from '@ant-design/icons/lib/outline/VideoCameraOutline';

export { default as BlockOutline } from '@ant-design/icons/lib/outline/BlockOutline';

export { default as WarningFill } from '@ant-design/icons/lib/fill/WarningFill';

export { default as WarningTwoTone } from '@ant-design/icons/lib/twotone/WarningTwoTone';

export { default as EditOutline } from '@ant-design/icons/lib/outline/EditOutline';

export { default as DownloadOutline } from '@ant-design/icons/lib/outline/DownloadOutline';

export { default as CloseCircle } from '@ant-design/icons/lib/outline/CloseCircleOutline';

export { default as ExclamationCircle } from '@ant-design/icons/lib/outline/ExclamationCircleOutline';

export { default as Ellipsis } from '@ant-design/icons/lib/outline/EllipsisOutline';

export { default as ClockCircle } from '@ant-design/icons/lib/outline/ClockCircleOutline';

export { default as Link } from '@ant-design/icons/lib/outline/LinkOutline';

export { default as Global } from '@ant-design/icons/lib/outline/GlobalOutline';

export { default as File } from '@ant-design/icons/lib/outline/FileOutline';

export { default as InfoCircleFill } from '@ant-design/icons/lib/fill/InfoCircleFill';
