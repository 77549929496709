import { createGlobalStyle, ThemeProviderProps } from 'styled-components';
import { colorHexToRgba } from '../../helpers';

const GlobalStyle = createGlobalStyle<ThemeProviderProps<any>>`
  html {
    font-size: ${props => props.theme.unit.basesize};
  }

  body, body * > *:not(script):not(style) {
    h1, h2, h3, h4, h5, h6 {
      color: ${props => props.theme.color.headings};
    }
  }

  body {
    background-color: ${props => props.theme.color.background};
  }

  html, body, #__next {
    display: block;
    height: ${props => props.theme.unit.app_height};
  }

  #root {
    height: ${props => props.theme.unit.app_height};
  }

  html {
    --antd-wave-shadow-color: ${props => props.theme.color.primary};
  }

  /* Over Ant Design here if it cannot be override using less variable */
  .ant-dropdown {
    z-index: 999 !important;
  }

  #root, .ant-modal-content {
    .ant-btn:not(.ant-btn-danger):not(.ant-btn-primary):not([disabled]) {
       &:hover,
       &:focus {
          color: ${props => props.theme.color.button};
          border-color: ${props => props.theme.color.button};
       }
       &:active,
       &.active {
        color: ${props => props.theme.color.buttonHover};
        border-color: ${props => props.theme.color.buttonHover};
      }
    }

    .ant-btn > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%
      vertical-align: middle;
    }

    .ant-btn i + span {
      max-width: calc(100% - 1.5em);
    }

    .ant-btn:not(.ant-btn-danger):not(.ant-btn-primary)[disabled] {
      border-color: ${props => props.theme.color.buttonDisabled};
      color: ${props => props.theme.color.buttonDisabled};
      background-color: ${props => colorHexToRgba(props.theme.color.white, 0.5)};
    }

    .ant-btn-primary:not([disabled]) {
      color: ${props => props.theme.color.buttonText};

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: ${props => props.theme.color.buttonHover};
        color: ${props => props.theme.color.buttonHoverText};
        border-color: ${props => props.theme.color.buttonHover};
      }
    }

    .ant-btn-primary[disabled] {
      &,
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: ${props => props.theme.color.buttonDisabled};
        color: ${props => props.theme.color.buttonDisabledText};
        border-color: ${props => props.theme.color.buttonDisabled};
      }
    }

    .ant-pagination-item a {
      text-decoration: none;
    }

    // FIXME: Temp fix for margin of antd loading spinner in button
    .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon.anticon-loading {
      margin-left: -14px;
      padding-right: 8px;
    }
  }
`;

export default GlobalStyle;
