import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer, { AppState, userInfoPersistConfig } from './reducers';

const { NODE_ENV } = process.env;

const middleWares: any[] = [thunkMiddleware];

const devToolsAndMiddleWares: any = NODE_ENV !== 'production'
  ? composeWithDevTools(applyMiddleware(...middleWares))
  : applyMiddleware(...middleWares);

export const store = createStore(
  persistReducer<AppState>(userInfoPersistConfig, rootReducer),
  devToolsAndMiddleWares
);

export const persistor = persistStore(store);
