import React, {
  FC, Suspense, lazy, useEffect, useState
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IntlProvider } from 'react-intl';
import {
  Switch, Router, Redirect, matchPath
} from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { ThemeProvider } from 'styled-components';
import { themes, GlobalStyle } from './assets/styles';

import {
  history,
  scrollElement,
  decodeJwt,
  loadChunk,
  getConfigurations,
  IGetConfigurations,
  stringToBoolean
} from './helpers';
import { AppState } from './store/reducers';
import {
  userActions, LoginActionTypes, languagesActions, LanguagesActionTypes,
} from './store/actions';
import { antdLocaleMap, routes } from './constants';

import SuspenseLoading from './components/Loading';
import { FormAlert } from './components/Forms';
import { Route } from './components/Router';

const Login = lazy(() => loadChunk(() => import(/* webpackPrefetch: true, webpackChunkName: 'Login' */ './containers/Login')));
const UnsupportedBrowser = lazy(() => loadChunk(() => import(/* webpackPrefetch: true, webpackChunkName: 'UnsupportedBrowser' */ './containers/UnsupportedBrowser')));
const sessionTimeout = lazy(() => loadChunk(() => import(/* webpackPrefetch: true, webpackChunkName: 'UnsupportedBrowser' */ './containers/SessionTimeout')));
const MaintenanceNotice = lazy(() => loadChunk(() => import(/* webpackPrefetch: true, webpackChunkName: 'MaintenanceNotice' */ './containers/MaintenanceNotice')));
const Authenticated = lazy(() => loadChunk(() => import(/* webpackPrefetch: true, webpackChunkName: 'Authenticated' */ './containers/Authenticated')));
const AnonymousAuthenticated = lazy(() => loadChunk(() => import(/* webpackPrefetch: true, webpackChunkName: 'AnonymousAuthenticated' */ './containers/AnonymousAuthenticated')));

const App: FC = () => {
  const {
    currentLanguage,
    translationDict,
    userToken,
    anonymousToken,
    referrerUri,
  } = useSelector((state: AppState) => ({
    currentLanguage: state.languages.currentLanguage ?? 'en',
    translationDict: state.languages.translationDict,
    userToken: state.user.userToken,
    anonymousToken: state.user.anonymousToken,
    referrerUri: state.page.referrerUri,
  }), shallowEqual);

  const dispatch = useDispatch<
    ThunkDispatch<AppState, null, LoginActionTypes | LanguagesActionTypes
  >>();

  const [isValidTokenFromUrl, setIsValidTokenFromUrl] = useState(false);
  const [isCheckingTokenFromUrl, setIsCheckingTokenFromUrl] = useState(true);

  const [anonymousUserName, setAnonymousUserName] = useState('');

  const params = new URLSearchParams(history.location.search);
  const token = params.get('token') ?? '';
  const languageFromParam = params.get('language') ?? '';
  const currentAntdLocale = antdLocaleMap[currentLanguage];

  // Load application configurations from remote
  // Used for turn on maintenance mode currently
  const [isConfigurationsReady, setIsConfigurationsReady] = useState(false);
  const [isSanityCheck, setIsSanityCheck] = useState(false);
  const [configurations, setConfigurations] = useState<IGetConfigurations>({});

  const [isUserCheckTokenFinish, setIsUserCheckTokenFinish] = useState(false);

  const shouldValidateToken = stringToBoolean(process.env.REACT_APP_SHOULD_VALIDATE_TOKEN ?? 'true');

  useEffect(() => {
    const checkTokenExpiry = async () => {
      if (userToken) {
        const decodedUserToken = await decodeJwt(userToken, shouldValidateToken);
        if (!decodedUserToken) {
          const { isRefreshSuccess } = await dispatch(userActions.refreshUserToken());
          isRefreshSuccess
            ? dispatch(userActions.userSessionEstablished())
            : dispatch(userActions.logout(false));
        } else {
          dispatch(userActions.userSessionEstablished());
        }
      }
      setIsUserCheckTokenFinish(true);
    };

    checkTokenExpiry();
  }, [dispatch, userToken, shouldValidateToken]);

  useEffect(() => {
    // Bypass maintenance mode - For internal use only
    if (params.get('sanity-check')) {
      setIsSanityCheck(true);
    }

    // Fetch live configuration every 1 minutes
    const CONFIGURATION_FETCH_WAIT_TIME = 60000;

    const loadConfigurations = async () => {
      const newConfigurations = await getConfigurations();
      setConfigurations(newConfigurations);
      setIsConfigurationsReady(true);
    };

    loadConfigurations();

    let interval: number = window.setInterval(loadConfigurations, CONFIGURATION_FETCH_WAIT_TIME);

    // Use visibility API to prevent calling of configuration when tab is in background
    // This can aslo prevent delay of setInterval when tab is in background
    const onVisibilityChange = (): void => {
      if (document.hidden) {
        window.clearInterval(interval);
        return;
      }

      loadConfigurations();
      interval = window.setInterval(loadConfigurations, CONFIGURATION_FETCH_WAIT_TIME);
    };

    document.addEventListener('visibilitychange', onVisibilityChange, false);

    return () => {
      window.clearInterval(interval);
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };

    /*
     * IMPORTANT: params is not passed as dependencies
     * Since the query param is not passed when route changed
     */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Scroll to page top when route changes
  useEffect(() => {
    const unlistenHistory = history.listen(() => {
      window.scrollTo(0, 0);

      const mainContent = document.getElementById('main-content');
      mainContent && scrollElement(mainContent, 0, 0);
    });

    return unlistenHistory;
  }, []);

  // Validate token from URL param
  useEffect(() => {
    const checkUrlToken = async () => {
      if (token) {
        const decodedTokenFromUrl: any = await decodeJwt(token, shouldValidateToken);
        const hasValidTokenInUrl = (decodedTokenFromUrl?.groups ?? []).includes('user:anonymous');
        hasValidTokenInUrl
          ? setAnonymousUserName(decodedTokenFromUrl?.username)
          : dispatch(userActions.unsetAnonymousIdToken());
        setIsValidTokenFromUrl(hasValidTokenInUrl);
      }
      setIsCheckingTokenFromUrl(false);
    };

    checkUrlToken();
  }, [dispatch, token, shouldValidateToken]);

  // Set active token
  useEffect(() => {
    isUserCheckTokenFinish && dispatch(userActions.setActiveToken(anonymousToken || userToken));
  }, [dispatch, anonymousToken, userToken, isUserCheckTokenFinish]);

  // Set language
  useEffect(() => {
    languageFromParam && dispatch(languagesActions.setCurrentLanguage(languageFromParam));
  }, [dispatch, languageFromParam]);

  const router = (
    <Router history={history}>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route
            path={routes.login}
            render={({ match, location }) => (
              // FIXME: proper handing for activeToken and /login/guest route
              !userToken
                ? <Login match={match} hasToken={!!userToken} location={location} />
                : (
                  <Redirect
                    to={{
                      pathname: referrerUri ?? location?.state?.from?.pathname,
                      search: location?.state?.from?.search,
                      state: { from: location },
                    }}
                  />
                )
            )}
          />
          <Route
            path={routes.unsupportedBrowser}
            component={UnsupportedBrowser}
          />
          <Route
            path={routes.sessionTimeout}
            component={sessionTimeout}
          />
          <Route
            render={({ location }) => (isCheckingTokenFromUrl
              ? <SuspenseLoading />
              : (
                (location?.state?.anonymousToken || isValidTokenFromUrl)
                && matchPath(location.pathname, routes.cobrowsingCaseEdit)
              )
                ? (
                  <AnonymousAuthenticated
                    token={location?.state?.anonymousToken ?? token}
                    anonymousUserName={anonymousUserName}
                  />
                )
                : userToken
                  ? <Authenticated />
                  : <Redirect to={{ pathname: routes.login, state: { from: location }, }} />
            )}
          />
        </Switch>
      </Suspense>
    </Router>
  );

  const maintance = (
    <Router history={history}>
      <Suspense fallback={<SuspenseLoading />}>
        <Route component={MaintenanceNotice} />
      </Suspense>
    </Router>
  );

  // For IntlProvider, if onError is undefined, it would throw console warning for unfound keys.
  // So to disable warning, set onError to () => {} is necessary.
  // And doing so, as before, could also expose the intl-element's id to the component.
  return (
    <ConfigProvider locale={currentAntdLocale}>
      <IntlProvider
        locale={currentLanguage}
        messages={translationDict[currentLanguage]}
        onError={() => {}}
      >
        <FormAlert />
        <ThemeProvider theme={themes.default}>
          {
            isConfigurationsReady && isUserCheckTokenFinish
              ? !isSanityCheck && configurations?.maintenance
                ? maintance
                : router
              : <SuspenseLoading />
          }
          <GlobalStyle />
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
