import { ComponentType } from 'react';
import { errorLogger } from '.';
import { store } from '../store';
import { alertActions } from '../store/actions';

const loadChunk = fn => new Promise<{ default: ComponentType<any>; }>((resolve, reject) => {
  fn()
    .then(resolve)
    .catch(error => {
      errorLogger(error);
      store.dispatch(alertActions.error('react.lazy.chunk.error'));
      window.setTimeout(() => {
        window.location.reload();
        reject(error);
      }, 5000);
    });
});

export default loadChunk;
