const errorLogger = (error: Error) => {
  // Display error in console on non-production builds and ignore test environment
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    console.error(error);
  }

  // Dispatch custom Event to trigger logger, currently we uses Sentry
  const errorCatchedEvent = new CustomEvent('catched_error', {
    detail: { error }
  });
  window.dispatchEvent(errorCatchedEvent);
};

export default errorLogger;
