// TODO: Use field name + FieldRow class / id to scroll
const fieldFocus = (
  ref: React.MutableRefObject<HTMLElement | null>,
  isScroll = true
) => {
  /**
   * Scroll when 2 condition match:
   * 1. ref !== active element
   * 2. ref does not contains active element (handles cases when focused element is wrapper div)
   * Note that if ref === activeElement, ref.current.contains(document.activeElement) === true
   */
  const { activeElement } = document;
  if (ref.current && ref.current !== activeElement && !ref.current.contains(activeElement)) {
    isScroll
      ? ref.current.scrollIntoView({ block: 'center', inline: 'nearest' })
      : ref.current.focus();
  }
};

export default fieldFocus;
