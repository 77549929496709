const ColorBase = {
  TRANSPARENT: 'rgba(0, 0, 0, 0)',
  SEMI_TRANSPARENT: 'rgba(0, 0, 0, 0.5)',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  RED: '#ED1C24',
  LIGHTRED: '#FEEDF2',
  SHADOW: 'rgba(0, 0, 0, 0.1)',
  COLOREDSHADOW: 'rgba(156, 66, 255, 0.1)',
  BODY: '#1c1c1c',
  HIGHLIGHT_ORANGE: '#FE9F4C',
  HIGHLIGHT_GREEN: '#57B24F',
  HIGHLIGHT_BLUE: '#76BBFE',
  HIGHLIGHT_PINK: '#E7887D',
  COHERENT_FUSCHIA: '#D533A3',
  COHERENT_AQUA: '#24C4CC',
  COHERENT_PURPLE110: '#8C3CE6',
  COHERENT_PURPLE: '#9C43FF',
  COHERENT_PURPLE90: '#A656FF',
  COHERENT_PURPLE40: '#D7B4FF',
  COHERENT_AQUA_BLUE110: '#357EE6',
  COHERENT_AQUA_BLUE: '#3B8CFF',
  COHERENT_AQUA_BLUE90: '#4F98FF',
  COHERENT_AQUA_BLUE40: '#B1D1FF',
  COHERENT_LIGHT_BLUE: '#00A8E5',
  COHERENT_GREEN: '#58B947',
  COHERENT_ORANGE: '#E8892C',

  DARK_COHERENT: '#201A3D',

  // Naming of GREY is darkness of grey
  // Found at: https://www.december.com/html/spec/colorcodes.html
  // Please use E(grey) instead of A (gray) for consistency
  GREY_20: '#333333',
  GREY_31: '#4f4f4f',
  GREY_38: '#616161',
  GREY_51: '#828282',
  GREY_60: '#9A9A9A',
  GREY_74: '#BDBDBD',
  GREY_84: '#D7D7D7',
  GREY_88: '#E0E0E0',
  GREY_93: '#ECECEB',
  GREY_94: '#F9F9F9',
  GREY_95: '#F2F2F2',
  GREY_96: '#F5F5F5',
  GREY_97: '#F7F7F7',

  BLUE_GREY: '#323639',
  COHERENT_BLUE_GREY: '#3F92A8',
};

const Color = {
  WHITE: ColorBase.WHITE,
  LIGHT_GREY: ColorBase.GREY_74,
  GREY: ColorBase.GREY_31,
  BLACK: ColorBase.BLACK,
  TRANSPARENT: ColorBase.TRANSPARENT,
  SEMI_TRANSPARENT: ColorBase.SEMI_TRANSPARENT,

  BODY: ColorBase.BODY,
  BODY_DARK: ColorBase.DARK_COHERENT,
  BODY_LIGHT: ColorBase.WHITE,

  LINKS: ColorBase.GREY_20,
  LINKS_HOVER: ColorBase.BLACK,

  LABEL_COLOR: ColorBase.BODY,
  BORDER: ColorBase.GREY_88,
  HOVER: ColorBase.COHERENT_AQUA_BLUE40,
  BACKGROUND: ColorBase.GREY_93,
  COLORED_BACKGROUND: ColorBase.GREY_93,

  PRIMARY: ColorBase.COHERENT_AQUA_BLUE,
  PRIMARY_SHADOW: ColorBase.COLOREDSHADOW,
  PRIMARY_FOCUS: ColorBase.COHERENT_AQUA_BLUE90,
  PRIMARY_ACTIVE: ColorBase.COHERENT_AQUA_BLUE110,

  HEADINGS: ColorBase.COHERENT_AQUA_BLUE,
  ACTIONBAR_BACKGROUND: ColorBase.GREY_31,

  SUBHEADER_BACKGROUND: ColorBase.WHITE,
  SUBHEADER_TEXT: ColorBase.COHERENT_AQUA_BLUE,
  DARK_SUBHEADER_BACKGROUND: ColorBase.GREY_31,
  DARK_SUBHEADER_TEXT: ColorBase.WHITE,

  BANNER_TEXT: ColorBase.WHITE,
  BANNER_OVERLAY_BACKGROUND: ColorBase.COHERENT_AQUA_BLUE,

  ALERT: ColorBase.RED,
  LIGHT_ALERT: ColorBase.LIGHTRED,

  MODAL_ALERT_ICON: ColorBase.COHERENT_AQUA_BLUE,

  SHADOW: ColorBase.SHADOW,
  LIGHT_SHADOW: ColorBase.SHADOW,

  DISABLED_BACKGROUND: ColorBase.GREY_94,
  DISABLED_TEXT: ColorBase.GREY_51,

  REMARKS_TEXT: ColorBase.GREY_51,

  COLLAPSED_SIDEBAR_TEXT: ColorBase.BODY,
  SELECTED_SIDEBAR_TEXT: ColorBase.WHITE,
  SELECTED_SIDEBAR_ICON: ColorBase.WHITE,
  UNSELECTED_SIDEBAR_TEXT: ColorBase.BODY,
  UNSELECTED_SIDEBAR_ICON: ColorBase.COHERENT_AQUA_BLUE,

  HAMBURGER_MENU_ICON: ColorBase.GREY_31,

  ERROR_TEXT: ColorBase.RED,

  BUTTON: ColorBase.COHERENT_AQUA_BLUE,
  BUTTON_TEXT: ColorBase.WHITE,
  BUTTON_HOVER: ColorBase.COHERENT_AQUA_BLUE90,
  BUTTON_HOVER_TEXT: ColorBase.WHITE,
  BUTTON_DISABLED: ColorBase.GREY_84,
  BUTTON_DISABLED_TEXT: ColorBase.BODY,

  FORM_BUILDER_BACKGROUND: ColorBase.GREY_96,
  FORM_BUILDER_ENTITIES_BACKGROUND: ColorBase.GREY_97,
  FORM_BUILDER_PROGRESSBAR: ColorBase.WHITE,
  FORM_BUILDER_PROGRESSBAR_DISABLED: ColorBase.GREY_74,
  FORM_BUILDER_CONTROL_BACKGROUND: ColorBase.WHITE,
  FORM_BUILDER_CONTROL_ICON: ColorBase.COHERENT_AQUA_BLUE,
  FORM_BUILDER_CONTROL_ICON_TEXT: ColorBase.BODY,
  FORM_BUILDER_CONTROL_DISABLED: ColorBase.GREY_88,

  PDF_VIEWER_BACKGROUND: ColorBase.GREY_31,
  PDF_VIEWER_TOOL_BACKGROUND: ColorBase.BLUE_GREY,
  PDF_VIEWER_ZOOM_BTN_BACKGROUND: ColorBase.GREY_95,
  PDF_VIEWER_ZOOM_BTN_TEXT: ColorBase.GREY_38,
  SIGNATURE_PANEL_BACKGROUND: ColorBase.GREY_31,
  SIGNATURE_PANEL_INNER_BACKGROUND: ColorBase.GREY_51,
  SIGNATURE_PANEL_INNER_TEXT: ColorBase.WHITE,
  SIGNATURE_PANEL_HINT_TEXT_FILL: ColorBase.GREY_74,
  SIGNATURE_PANEL_DISABLED_BUTTON_TEXT: ColorBase.GREY_74,

  CARD_IMAGE_OVERLAY: ColorBase.DARK_COHERENT,
  CARD_RIGHT_LABEL_SECONDARY: ColorBase.BODY,
  CARD_LEFT_LABEL: ColorBase.GREY_51,

  OPEN_CASE_STATUS: ColorBase.COHERENT_LIGHT_BLUE,
  SIGNING_CASE_STATUS: ColorBase.COHERENT_ORANGE,
  SUBMITTED_CASE_STATUS: ColorBase.COHERENT_GREEN,
  ISSUED_CASE_STATUS: ColorBase.COHERENT_BLUE_GREY,

  SELECTED_ITEM_BACKGROUND: ColorBase.COHERENT_AQUA_BLUE,
  SELECTED_ITEM_HOVER_BACKGROUND: ColorBase.COHERENT_AQUA_BLUE90,
  SELECTED_ITEM_TEXT: ColorBase.WHITE,
  SELECTED_ITEM_HOVER_TEXT: ColorBase.WHITE,
  UNSELECTED_ITEM_BACKGROUND: ColorBase.WHITE,
  UNSELECTED_ITEM_HOVER_BACKGROUND: ColorBase.WHITE,
  UNSELECTED_ITEM_TEXT: ColorBase.COHERENT_AQUA_BLUE,
  UNSELECTED_ITEM_HOVER_TEXT: ColorBase.COHERENT_AQUA_BLUE90,
};

// Shared between build script and react
(function (exports) {
  exports.Color = Color;
}(typeof exports === 'undefined' ? (this.Color = {}) : exports));
