const routes = {
  root: '/',
  login: '/login',
  singleSignOnLogin: '/login/sso',
  unsupportedBrowser: '/unsupported-browser',
  home: '/home',
  cases: '/cases',
  application: '/application',
  editCase: '/application/:caseId',
  salesIllustration: '/sales-illustration',
  products: '/products',
  statistics: '/statistics',
  clients: '/clients',
  templates: '/templates',
  quickQuote: '/quick-quote',
  resources: '/resources',
  guestWelcome: '/login/guest/:caseId',
  cobrowsingCaseEdit: '/guest/application/:caseId',
  sessionTimeout: '/session-timeout',
};

export default routes;
