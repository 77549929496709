/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import jwt from 'jsonwebtoken';
import { store } from '../store';
import { userActions } from '../store/actions';
import errorLogger from './error-logger';

export type IRequestMethod = Method;
export type IRequestOptions = AxiosRequestConfig;
export type IRequestFlowOptions = Partial<{
  shouldUseToken: boolean;
}>;
export type IResponse<T = any> = AxiosResponse<T>;

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.responseType = 'json';

const blobToJson = (blob: Blob) => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = function parseJson() {
    if (typeof this.result === 'string') {
      try {
        resolve(JSON.parse(this.result));
      } catch (error) {
        errorLogger(error);
        resolve(null);
      }
    }
  };
  reader.readAsText(blob);
});

const request = async <T = any>(
  requestOptions: AxiosRequestConfig
) => {
  try {
    return await axios.request<T>(requestOptions);
  } catch (error) {
    // To return API response even if status code is not 2XX
    // Check if response is Blob first
    // for case which responseType is set to blob to get error code
    if (error.response && error.response.data instanceof Blob) {
      const jsonErrorBody = await blobToJson(error.response.data);
      if (jsonErrorBody) {
        error.response.data = jsonErrorBody;
      }
    }

    return error.response;
  }
};

const preprocessRequestOptions = (requestOptions: AxiosRequestConfig) => {
  if (['GET', 'HEAD'].includes(requestOptions.method as string)) {
    delete requestOptions.data;
  } else if (!requestOptions.data) {
    // assign {} to avoid axios removing content-type
    requestOptions.data = {};
  }

  if (requestOptions.data && requestOptions.data instanceof FormData) {
    requestOptions.headers = { ...requestOptions.headers, 'Content-Type': null };
  }
};

const setToken = (requestOptions: AxiosRequestConfig) => {
  const { activeToken } = store.getState().user;
  requestOptions.headers = { ...requestOptions.headers, Authorization: `Bearer ${activeToken}` };
};

const setOcpApimSubscriptionKey = (requestOptions: AxiosRequestConfig) => {
  requestOptions.headers = { ...requestOptions.headers, 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY };
};

const checkToken = async (): Promise<{ checkTokenSuccess: boolean, error?: Error }> => {
  try {
    const { activeToken } = store.getState().user;
    const decodedJwt: any = (activeToken && jwt.decode(activeToken)) || undefined;

    if (decodedJwt.exp && decodedJwt.exp * 1000 < Date.now()) {
      const { isRefreshSuccess } = await store.dispatch<any>(userActions.refreshUserToken());
      if (!isRefreshSuccess) {
        store.dispatch<any>(userActions.logout());
        throw new Error('REFRESH_TOKEN_NOT_SUCCESSFUL');
      }
    }

    return { checkTokenSuccess: true };
  } catch (error) {
    return { checkTokenSuccess: false, error };
  }
};

const makeRequest = async <T = any>(
  requestOptions: IRequestOptions,
  { shouldUseToken = true }: IRequestFlowOptions = {},
): Promise<IResponse<T>> => {
  try {
    if (shouldUseToken) {
      const { checkTokenSuccess, error: checkTokenError } = await checkToken();
      if (!checkTokenSuccess) {
        throw checkTokenError;
      }
      setToken(requestOptions);
    }
    if (shouldUseToken && process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY) {
      setOcpApimSubscriptionKey(requestOptions);
    }
    preprocessRequestOptions(requestOptions);
    return request<T>(requestOptions);
  } catch (error) {
    errorLogger(error);

    // Hacky and lazy way not to change all types applied to all services
    // `data` is currently mock to have an errorCode
    // TODO: check if services using other error_code, error_codes, etc. is working fine

    // Currently actions does not support this new error code 'REFRESH_TOKEN_NOT_SUCCESSFUL'
    // and has fall back to 'UNKNOWN_ERROR', and it causes the 'UNKNOWN_ERROR' alert to show together with
    // 'MAX_SESSION_TIME_REACHED' alerted by the refreshUserToken action
    // Since we already show 'MAX_SESSION_TIME_REACHED' error
    // We should hide 'REFRESH_TOKEN_NOT_SUCCESSFUL' as the return value of services in action level
    // TODO: all actions that call services should not alert user for 'REFRESH_TOKEN_NOT_SUCCESSFUL'
    return {
      data: {
        errorCode: 'REFRESH_TOKEN_NOT_SUCCESSFUL'
      } as unknown as T,
      status: 403,
      statusText: 'Forbidden',
      headers: undefined,
      config: requestOptions
    };
  }
};

export default makeRequest;
