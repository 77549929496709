import { ThunkDispatch } from 'redux-thunk';

import { ExternalService } from '../../services';
import { alertActions, AlertActionTypes } from '.';
import { AppState } from '../reducers';
import { errorLogger, blobToBase64 } from '../../helpers';

import {
  isFetchGeoLocationSuccess,
  getFetchGeoLocationErrorCode
} from '../../services/external.service.helpers';

const fetchGeoLocation = (
  latitude: number,
  longitude: number,
  language = 'en',
) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const data = await ExternalService.fetchGeoLocation(
      latitude,
      longitude,
      language,
    );

    if (!isFetchGeoLocationSuccess(data)) {
      const errorMessage = getFetchGeoLocationErrorCode(data);
      throw new Error(errorMessage ?? data.status);
    }

    const { results } = data;

    return { canFetchValidGeolocation: true, results };
  } catch (error) {
    dispatch(alertActions.error(
      [
        'ZERO_RESULTS',
      ].includes(error.message)
        ? `externalAction.fetchGeoLocation.${error.message}`
        : 'UNKNOWN_ERROR'
    ));

    errorLogger(error);

    return { canFetchValidGeolocation: false, results: null };
  }
};

const azureFaceVerify = (imageUrl1: string, imageUrl2: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const getErrorCodeFromAzurePayload = data => data?.error?.code
      || data?.error?.statusCode?.toString();

    if (!imageUrl1 || !imageUrl2) {
      throw new Error('MISSING_IMAGE');
    }

    const [faceId1, faceId2] = await Promise.all([imageUrl1, imageUrl2].map(async imageUrl => {
      const {
        status: detectStatus,
        data: detectPayload
      } = await ExternalService.azureFaceDetect(imageUrl);

      if (detectStatus !== 200) {
        throw new Error(getErrorCodeFromAzurePayload(detectPayload));
      }

      const faces: { faceId: string }[] = detectPayload;

      if (faces?.[0]?.faceId) {
        return faces?.[0]?.faceId;
      }

      throw new Error('NO_FACE_DETECTED_ON_IMAGE');
    }));

    const { status, data } = await ExternalService.azureFaceVerify(faceId1, faceId2);

    if (status !== 200) {
      throw new Error(getErrorCodeFromAzurePayload(data));
    }

    return data;
  } catch (error) {
    dispatch(alertActions.error(
      [
        'MISSING_IMAGE',
        'NO_FACE_DETECTED_ON_IMAGE',
        'BadArgument',
        'InvalidURL',
        'InvalidImage',
        'InvalidImageSize',
        'Unspecified',
        'OperationTimeOut',
        '403',
        '429'
      ].includes(error.message)
        ? `externalAction.azureFaceVerify.${error.message}`
        : 'UNKNOWN_ERROR'
    ));

    errorLogger(error);

    return {
      isIdentical: false,
      confidence: 0
    };
  }
};

const getPdfAsBase64 = (externalPdfPath: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>
): Promise<string> => {
  try {
    const pdf = await ExternalService.getExternalPDF(externalPdfPath);
    if (!(pdf instanceof Blob)) {
      throw new Error('FAILED_GETTING_EXTERNAL_PDF');
    }
    return await blobToBase64(pdf);
  } catch (error) {
    dispatch(alertActions.error(
      ['FAILED_GETTING_EXTERNAL_PDF'].includes(error.message)
        ? `externalAction.getPdfAsBase64.${error.message}`
        : 'UNKNOWN_ERROR'
    ));
    errorLogger(error);
    return '';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const externalActions = {
  fetchGeoLocation,
  azureFaceVerify,
  getPdfAsBase64
};
