import React, { FC } from 'react';
import styled from 'styled-components';

export interface FormFieldGridProps {
  gridDirectionIsCol?: boolean;
  gridMainLengthCell1?: string;
  padding?: string;
  margin?: string;
}

const StyledGrid = styled.div<FormFieldGridProps>`
  display: grid;

  grid-template-rows: ${props => (!props.gridDirectionIsCol
    ? `${props.gridMainLengthCell1 || '30px'} auto`
    : 'auto')};
  grid-template-columns: ${props => (props.gridDirectionIsCol
    ? `${props.gridMainLengthCell1 || '30%'} auto`
    : 'auto')};

  width: 100%;
  align-items: center;

  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
`;

const FormFieldGrid: FC<FormFieldGridProps> = ({
  children,
  ...formFieldGridProps
}) => (
  <StyledGrid {...formFieldGridProps}>{children}</StyledGrid>
);

export default FormFieldGrid;
