import { pageTypes } from '../types';
import { PageActionTypes } from '../actions';
import { pageInitialState, PageStateType } from '../states/page.states';

const pageReducer = (
  state: PageStateType = pageInitialState,
  { type, currentPageId, referrerUri = '' }: PageActionTypes
): PageStateType => {
  switch (type) {
    case pageTypes.SET_CURRENT_PAGE:
      return { ...state, currentPageId, referrerUri: '' };
    case pageTypes.SET_REFERRER_URI:
      return { ...state, referrerUri };
    default:
      return state;
  }
};

export default pageReducer;
