import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';

import { IFormFieldProps } from '../../types';
import Sup from './Sup';

interface IDescriptionMeta {
  prefix?: string;
}

interface IDescriptionProps
  extends Pick<
  IFormFieldProps,
  'label' | 'description' | 'metadata' | 'required' | 'readonly' | 'name'
  > {
  className?: string;
  metadata?: IDescriptionMeta;
}

const DescriptionDiv = styled.div`
  font-size: 0.75rem;
  margin-bottom: ${props => props.theme.unit.space_sm};
`;

const Description: FC<IDescriptionProps> = ({
  name,
  label,
  description,
  metadata,
  required,
  readonly,
  className,
}) => (description ? (
  <DescriptionDiv className={className}>
    <span className="formbuilder__row__description__text">
      <FormattedHTMLMessage id={`${name}_${description}`} defaultMessage={description} />
    </span>
    {required && !readonly && !!label && !!metadata?.prefix && (
      <Sup className="formbuilder__row__description__sup">*</Sup>
    )}
  </DescriptionDiv>
) : null);

export default Description;
