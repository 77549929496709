import { ProductsActionType } from '../actions';

export interface ProductStateTypes extends Required<
  Omit<ProductsActionType, 'type' | 'formSpecDetailsToBeAdded'>
> {
  formSpecDict: {
    [productName: string]: {
      [engineName: string]: object;
    }
  };
}

export const productInitialState: ProductStateTypes = {
  productList: {},
  productCategories: {},
  formSpecDict: {},
};
