import { casesTypes } from '../types';
import { CasesActionTypes } from '../actions';
import { CaseStateType, caseInitialState } from '../states/cases.states';

const casesReducer = (
  state: CaseStateType = caseInitialState,
  {
    type,
    caseList,
    participant,
    sessionUrl,
  }: CasesActionTypes
): CaseStateType => {
  switch (type) {
    case casesTypes.FETCH_SUCCESS:
      return {
        ...state,
        caseList: caseList ?? null
      };
    case casesTypes.FETCH_LIVE_CASE_SUCCESS:
      return {
        ...state,
        participant: participant ?? state.participant,
        sessionUrl: sessionUrl ?? state.sessionUrl,
      };
    default:
      return state;
  }
};

export default casesReducer;
