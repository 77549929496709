import { Color, Unit, Style } from '../../constants';
import { mediaQueries } from './media';

const themes = {
  default: {
    color: {
      body: Color.BODY,
      bodyDark: Color.BODY_DARK,
      bodyLight: Color.BODY_LIGHT,
      links: Color.LINKS,
      linksHover: Color.LINKs_HOVER,
      headings: Color.HEADINGS,
      actionbarBackground: Color.ACTIONBAR_BACKGROUND,
      subheaderBackground: Color.SUBHEADER_BACKGROUND,
      subheaderText: Color.SUBHEADER_TEXT,
      darkSubheaderBackground: Color.DARK_SUBHEADER_BACKGROUND,
      darkSubheaderText: Color.DARK_SUBHEADER_TEXT,
      bannerText: Color.BANNER_TEXT,
      bannerOverlayBackground: Color.BANNER_OVERLAY_BACKGROUND,
      labelColor: Color.LABEL_COLOR,
      border: Color.BORDER,
      background: Color.BACKGROUND,
      coloredBackground: Color.COLORED_BACKGROUND,
      primary: Color.PRIMARY,
      primaryShadow: Color.PRIMARY_SHADOW,
      primaryFocus: Color.PRIMARY_FOCUS,
      primaryActive: Color.PRIMARY_ACTIVE,
      alert: Color.ALERT,
      lightAlert: Color.LIGHT_ALERT,
      modalAlertIcon: Color.MODAL_ALERT_ICON,
      transparent: Color.TRANSPARENT,
      semiTransparent: Color.SEMI_TRANSPARENT,
      white: Color.WHITE,
      shadow: Color.SHADOW,
      hover: Color.HOVER,
      disabledBackground: Color.DISABLED_BACKGROUND,
      disabledText: Color.DISABLED_TEXT,
      remarksText: Color.REMARKS_TEXT,
      collapsedSidebarText: Color.COLLAPSED_SIDEBAR_TEXT,
      selectedSidebarText: Color.SELECTED_SIDEBAR_TEXT,
      selectedSidebarIcon: Color.SELECTED_SIDEBAR_ICON,
      unselectedSidebarText: Color.UNSELECTED_SIDEBAR_TEXT,
      unselectedSidebarIcon: Color.UNSELECTED_SIDEBAR_ICON,
      hamburgerMenuIcon: Color.HAMBURGER_MENU_ICON,
      errorText: Color.ERROR_TEXT,
      button: Color.BUTTON,
      buttonText: Color.BUTTON_TEXT,
      buttonHover: Color.BUTTON_HOVER,
      buttonHoverText: Color.BUTTON_HOVER_TEXT,
      buttonDisabled: Color.BUTTON_DISABLED,
      buttonDisabledText: Color.BUTTON_DISABLED_TEXT,
      formBuilderBackground: Color.FORM_BUILDER_BACKGROUND,
      formBuilderEntitiesBackground: Color.FORM_BUILDER_ENTITIES_BACKGROUND,
      formBuilderProgressBar: Color.FORM_BUILDER_PROGRESSBAR,
      formBuilderProgressBarDark: Color.FORM_BUILDER_PROGRESSBAR_DARK,
      formBuilderProgressBarDisabled: Color.FORM_BUILDER_PROGRESSBAR_DISABLED,
      formBuilderControlIcon: Color.FORM_BUILDER_CONTROL_ICON,
      formBuilderControlBackground: Color.FORM_BUILDER_CONTROL_BACKGROUND,
      formBuilderControlIconText: Color.FORM_BUILDER_CONTROL_ICON_TEXT,
      formBuilderControlDisabled: Color.FORM_BUILDER_CONTROL_DISABLED,
      pdfViewerBackground: Color.PDF_VIEWER_BACKGROUND,
      pdfViewerToolBackground: Color.PDF_VIEWER_TOOL_BACKGROUND,
      pdfViewerZoomBtnBackground: Color.PDF_VIEWER_ZOOM_BTN_BACKGROUND,
      pdfViewerZoomBtnText: Color.PDF_VIEWER_ZOOM_BTN_TEXT,
      signaturePanelBackground: Color.SIGNATURE_PANEL_BACKGROUND,
      signaturePanelInnerBackground: Color.SIGNATURE_PANEL_INNER_BACKGROUND,
      signaturePanelInnerText: Color.SIGNATURE_PANEL_INNER_TEXT,
      signaturePanelHintTextFill: Color.SIGNATURE_PANEL_HINT_TEXT_FILL,
      signaturePanelDisabledButtonText: Color.SIGNATURE_PANEL_DISABLED_BUTTON_TEXT,
      cardImageOverlay: Color.CARD_IMAGE_OVERLAY,
      grey: Color.GREY,
      cardLeftLabel: Color.CARD_LEFT_LABEL,
      lightGrey: Color.LIGHT_GREY,
      cardRightLabelSecondary: Color.CARD_RIGHT_LABEL_SECONDARY,
      openedCaseStatus: Color.OPEN_CASE_STATUS,
      signingCaseStatus: Color.SIGNING_CASE_STATUS,
      completedCaseStatus: Color.SUBMITTED_CASE_STATUS,
      issuedCaseStatus: Color.ISSUED_CASE_STATUS,
      selectedItemBackground: Color.SELECTED_ITEM_BACKGROUND,
      selectedItemHoverBackground: Color.SELECTED_ITEM_HOVER_BACKGROUND,
      selectedItemText: Color.SELECTED_ITEM_TEXT,
      selectedItemHoverText: Color.SELECTED_ITEM_HOVER_TEXT,
      unselectedItemBackground: Color.UNSELECTED_ITEM_BACKGROUND,
      unselectedItemHoverBackground: Color.UNSELECTED_ITEM_HOVER_BACKGROUND,
      unselectedItemText: Color.UNSELECTED_ITEM_TEXT,
      unselectedItemHoverText: Color.UNSELECTED_ITEM_HOVER_TEXT,
    },
    unit: {
      basesize: Unit.BASESIZE,
      space_xsm: Unit.SPACE_XSM,
      space_sm: Unit.SPACE_SM,
      space_md: Unit.SPACE_MD,
      space_lg: Unit.SPACE_LG,
      space_xl: Unit.SPACE_XL,
      corner_sm: Unit.CORNER_SM,
      corner_md: Unit.CORNER_MD,
      app_height: Unit.APP_HEIGHT,
      page_height: Unit.PAGE_HEIGHT,
      headerHeight: Unit.HEADER_HEIGHT,
    },
    style: {
      boxshadow: Style.BOXSHADOW,
      border: Style.BORDER,
    },
    mediaQueries,
  },
};

export default themes;
