import { translationDictFromLocale } from '../../constants';
import { languagesTypes } from '../types';

export interface LanguagesActionTypes {
  type: string;
  currentLanguage?: string;
  translationDict?: {
    [language: string]: {
      [key: string]: string;
    }
  };
  formSpecVersion?: string;
}

const setCurrentLanguage = (currentLanguage: string) => (
  currentLanguage in translationDictFromLocale ? {
    type: languagesTypes.SET_LANGUAGE_SUCCESS,
    currentLanguage,
  } : {
    type: languagesTypes.SET_LANGUAGE_ERROR,
  }
);

const setTranslationDict = (translationDict: object, formSpecVersion: string) => (
  translationDict ? {
    type: languagesTypes.SET_LANGUAGE_DICT_SUCCESS,
    translationDict,
    formSpecVersion,
  } : {
    type: languagesTypes.SET_LANGUAGE_DICT_ERROR
  }
);

export const languagesActions = {
  setCurrentLanguage,
  setTranslationDict,
};
