import { init, ApmBase } from '@elastic/apm-rum';
import { withTransaction as apmLogger } from '@elastic/apm-rum-react';

let apm: ApmBase;

const loginListener = () => {
  // Listen for custom login event
  const logUserEmail = (e: CustomEventInit) => {
    const { detail: { email } } = e;
    apm?.setUserContext({ email });
  };

  window.addEventListener('user_session_established', logUserEmail, false);
};

const logoutListener = () => {
  // Listen for custom logout event
  window.addEventListener('logout', () => {
    apm?.setUserContext({ email: undefined });
  }, false);
};

export const apmInit = () => {
  const {
    REACT_APP_APM_SERVICE_NAME,
    REACT_APP_APM_SERVICE_URL,
    REACT_APP_APM_ENV,
    REACT_APP_COMMIT_HASH
  } = process.env;

  if (
    REACT_APP_APM_SERVICE_NAME
    && REACT_APP_APM_SERVICE_URL
    && REACT_APP_APM_ENV
    && REACT_APP_COMMIT_HASH
  ) {
    apm = init({
      serviceName: REACT_APP_APM_SERVICE_NAME,
      serverUrl: REACT_APP_APM_SERVICE_URL,
      serviceVersion: REACT_APP_COMMIT_HASH,
      environment: REACT_APP_APM_ENV,
    });

    loginListener();
    logoutListener();
  }
};

export default apmLogger;
