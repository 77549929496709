/* Only for non-window element usage, for window object, IE supports window.scrollTo(top, left) */
/* eslint-disable no-param-reassign */

const scrollElement = (
  element: HTMLElement,
  top: number,
  left: number,
  shouldScrollSmoothly = false,
) => {
  if (element) {
    if (!HTMLElement.prototype.scrollTo) {
      element.scrollTop = top;
      element.scrollLeft = left;
    } else {
      shouldScrollSmoothly
        ? element.scrollTo({ top, left, behavior: 'smooth' })
        : element.scrollTo(top, left);
    }
  }
};

export default scrollElement;
