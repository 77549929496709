import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../reducers';
import { alertActions, AlertActionTypes } from '.';
import { ExcelEngineService, DocStoreService } from '../../services';
import { errorLogger } from '../../helpers';
import { isRequestSuccess, getRequestErrorCode } from '../../services/service.helpers';
import { isGetDocumentSuccess } from '../../services/docStore.service.helpers';
import { IDocFile } from '../../interfaces';
import { apiEndpointDomians } from '../../constants';

const { DOCSTORE_DOMAIN_WITH_TENANT } = apiEndpointDomians;

export interface DocStoreActionTypes {
  type: string;
  docFiles?: IDocFile[];
}

const fetchPDF = (dirPath: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const { data: payload } = await ExcelEngineService.getFileManagerFolderWithMetadata(
      dirPath
    );

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    return payload?.data?.documents ?? [];
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'FORBIDDEN'
    ].includes(error.message)
      ? `docAction.fetchPDF.${error.message}`
      : 'UNKNOWN_ERROR'; // INTERNAL_SERVER_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return [];
  }
};

const getDocument = (documentId: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const data = await DocStoreService.getDocument(documentId);

    if (!isGetDocumentSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    const {
      effectiveVersion: {
        id: latestVersionId,
        url: latestVersionUrl,
        created: latestVersionCreateDate,
      },
      path,
    } = data;

    return {
      status: 'success',
      path,
      latestVersionId,
      latestVersionUrl: `${DOCSTORE_DOMAIN_WITH_TENANT}${latestVersionUrl}`,
      latestVersionCreateDate
    };
  } catch (error) {
    const errorTranslationKey = [
      'TEMPORARY_TOKEN_EXPIRED',
      'ENTITY_NOT_FOUND',
      'UNAUTHORIZED'
    ].includes(error.message)
      ? `docAction.getDocument.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return {
      status: 'error',
      path: null,
      latestVersionId: null,
      latestVersionUrl: null,
      latestVersionCreateDate: null
    };
  }
};

/**
 * Upload a document to Entity Store
 * @param {File} file - the file to be uploaded
 * @param {string} path - the path which the file will be stored
 * @param {boolean} isTemp - whether to create the document as a temporary file
 * @param {string} privacyLevel - One of `Global`, `Tenant` and `Private`
 */
const uploadDocument = (
  file: File,
  path: string,
  isTemp = false,
  privacyLevel: 'Global' | 'Tenant' | 'Private' = 'Private'
) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>
) => {
  try {
    const payload = await DocStoreService.uploadDocument(file, path, isTemp, privacyLevel);

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    return {
      ...payload,
      data: {
        ...payload.data,
        url: `${DOCSTORE_DOMAIN_WITH_TENANT}${payload.data.url}`
      }
    };
  } catch (error) {
    console.log(error);
    const fileTooLargeErrorRegex = /^(SIZE|FILE)_MUST_BE_LESS_THAN_\d+$/;
    const errorTranslationKey = [
      'INVALID_REQUEST',
      'INVALID_JSON_STRING',
      'INVALID_VALUE_FOR_PRIVACYLEVEL',
      'ENTITY_EXISTS_ALREADY',
      'NULL_OR_EMPTY_PATH',
      'INVALID_VALUE_FOR_PATH',
      'NULL_OR_EMPTY_DATAJSON',
      'UNAUTHORIZED'
    ].includes(error.message)
      ? `docAction.uploadDocument.${error.message}`
      : fileTooLargeErrorRegex.test(error.message)
        ? 'docAction.uploadDocument.FILE_TOO_LARGE'
        : 'UNKNOWN_ERROR'; // NULL_OR_EMPTY_DATAJSON
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { data: null, status: 'error' };
  }
};

export const docStoreActions = {
  fetchPDF,
  getDocument,
  uploadDocument,
};
