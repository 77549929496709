import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'antd';
import Modal, { ModalPropsType } from '../../../components/Modals';
import { parseBbcode } from '../../utils';

interface HintModalProps extends ModalPropsType {
  bbcode: string
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.unit.space_lg};
  width: 80%;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding-top: 0;
  }
`;

const ContentWrapper = styled.div`
  & img {
    margin: ${props => `0 -${props.theme.unit.space_xl}`}
    width: ${props => `calc(100% + 2*${props.theme.unit.space_xl})`};
  }
  & h1, h2, h3, h4, h5, h6 {
    margin: ${props => `${props.theme.unit.space_lg} 0 ${props.theme.unit.space_md} 0`} ;
    font-weight: bold;
  }
  & ol, ul {
    padding-left: 1rem;
  }
`;

const preprocessBbcode = (bbcode: string) => {
  // extract & remove buttonlabel from text
  const buttonLabelRegex = /\[buttonlabel\](.*?)\[\/buttonlabel\]/g;
  const customButtonLabel = buttonLabelRegex.exec(bbcode)?.[1] ?? null;
  return {
    processedBbcodeText: bbcode.replace(buttonLabelRegex, ''),
    customButtonLabel,
  };
};

const HintModal: FC<HintModalProps> = ({
  bbcode,
  onOkButtonClick,
  ...props
}) => {
  const intl = useIntl();
  const { processedBbcodeText, customButtonLabel } = preprocessBbcode(bbcode);

  return (
    <StyledModal
      {...props}
      className="formbuilder__hint-modal"
    >
      <ContentWrapper className="formbuilder__hint-modal__content">
        {parseBbcode(processedBbcodeText)}
      </ContentWrapper>
      {onOkButtonClick && (
        <ButtonWrapper>
          <StyledButton
            size="large"
            type="primary"
            onClick={onOkButtonClick}
          >
            {customButtonLabel ?? intl.formatMessage({ id: 'hintModal.button' })}
          </StyledButton>
        </ButtonWrapper>
      )}
    </StyledModal>
  );
};

export default HintModal;
