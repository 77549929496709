import styled from 'styled-components';

const Sup = styled.span`
  display: inline-block;
  padding: 0 0.2rem;
  font-weight: bold;
  color: ${props => props.theme.color.alert};
`;

export default Sup;
