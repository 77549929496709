import { get } from 'lodash';
import { CreateCaseType, ICaseEntity, CaseStatus } from '../interfaces';
import { IFetchCaseListSuccess } from '../services/cases.service.types';

const DELIMITER = ' // ';

// Checkout /docs/case-to-entity-mapping.md for details
// IMPORTANT: Remember to update documentation if you apply any changes to the below mapping
// TODO: convert this to a static JSON mapping config after we figure out the best approach
export const convertCaseToEntity = (caseData: CreateCaseType): ICaseEntity => {
  const { status, path, data } = caseData;
  // Map case data to entity store properties which is use for searching and indexing
  // These properties will be returned in the get case list API
  const entityData: ICaseEntity = {
    dateTime1: undefined,
    dateTime2: undefined,
    status1: status,
    status2: undefined,
    path,
    email1: undefined,
    email2: undefined,
    amount1: get(data, 'Applicant.Birth.AgeNextBirthday') || undefined,
    amount2: undefined,
    amount3: undefined,
    policyNumber: get(data, 'Application.Number') || undefined,
    name1: `${get(data, 'Applicant.LastName.English') || ''}${DELIMITER}${get(data, 'Applicant.FirstName.English') || ''}`,
    name2: get(data, 'Agent.FullName') || undefined,
    otherString1: get(data, 'Applicant.Residential.Country') || undefined,
    otherString2: get(data, 'Applicant.Gender') || undefined,
    referenceId: undefined,
    disposition: undefined,
    productName: `${get(data, 'Product.GroupName') || ''}${DELIMITER}${get(data, 'Product.Name')}`,
    dataJson: { ...data }
  };

  // Remvove empty properties since they are optional parameters to Entity Store
  Object.keys(entityData).forEach(
    key => (entityData[key] === undefined) && delete entityData[key]
  );

  return entityData;
};

export interface ICaseSummary {
  caseId: string;
  clientLastName: string;
  clientFirstName: string;
  agentName: string | null;
  applicantNextBirthday: string | null;
  caseStatus: CaseStatus;
  policyNumber: string | null;
  applicantResidentialCountry: string | null;
  applicantGender: string | null;
  productGroupName: string;
  productName: string;
  updated: string;
}

export interface ICaseList {
  totalCases: number;
  totalPages: number;
  page: number;
  result: ICaseSummary[];
}

export const convertEntityToCaseSummary = (
  caseList: IFetchCaseListSuccess
): ICaseList => {
  const clonedCaseList: IFetchCaseListSuccess = JSON.parse(JSON.stringify(caseList));
  const { data: { count, page, pageSize } } = clonedCaseList;
  const resultToConvert = [...clonedCaseList.data.result];

  const caseSummeries = resultToConvert.map(data => {
    const {
      id: caseId,
      name1,
      name2: agentName,
      amount1: applicantNextBirthday,
      status1: caseStatus,
      policyNumber,
      otherString1: applicantResidentialCountry,
      otherString2: applicantGender,
      productName: fullProductName,
      updated
    } = data;

    const [clientLastName, clientFirstName] = (name1 ?? DELIMITER).split(DELIMITER);
    const [productGroupName, productName] = (fullProductName ?? DELIMITER).split(DELIMITER);

    return {
      caseId,
      clientLastName,
      clientFirstName,
      agentName,
      applicantNextBirthday,
      caseStatus,
      policyNumber,
      applicantResidentialCountry,
      applicantGender,
      productGroupName,
      productName,
      updated
    };
  });

  return {
    totalCases: count,
    totalPages: Math.ceil(count / pageSize),
    page,
    result: caseSummeries
  };
};
