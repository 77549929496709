/*
 * KeyCloak API endpoints:
 * https://keycloak.dev.coherent.com.hk/auth/realms/coherent/.well-known/openid-configuration
 *
 * Customized endpoints:
 * https://github.com/CoherentCapital/keycloak/blob/poc/src/main/documentations/AnonymousUsers.md#create-anonymous-user
 */
import { stringifyUrl } from 'query-string';
import { makeRequest } from '../helpers';
import { ICreateAnonymousUser, IGetAnonymousUserToken } from './user.service.types';

const {
  REACT_APP_OPENID_CLIENT_ID: OPENID_CLIENT_ID,
  REACT_APP_TENANT: OAUTH_REALMS,
  REACT_APP_ANONYMOUS_USER_SERVICE_DOMAIN: ANONYMOUS_USER_SERVICE_DOMAIN,
} = process.env;

/**
 * https://github.com/CoherentCapital/keycloak/blob/poc/src/main/documentations/AnonymousUsers.md#create-anonymous-user
 */
const createAnonymousUser = async (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  expiryDatetime: Date
): Promise<ICreateAnonymousUser> => {
  const url = `${ANONYMOUS_USER_SERVICE_DOMAIN}/auth/realms/${OAUTH_REALMS}/anonymous-user/api/v1/create`;
  const data = {
    firstName,
    lastName,
    phoneNumber,
    expiryDatetime: `${expiryDatetime.toISOString().split('.')[0]}Z` // KeyCloak is not accepting milliseconds
  };
  return (await makeRequest({ method: 'POST', url, data })).data;
};

const getAnonymousUserToken = async (
  accessCode: string, anonymousUserId: string, linkId: string
): Promise<IGetAnonymousUserToken> => {
  const url = stringifyUrl(
    {
      url: `${ANONYMOUS_USER_SERVICE_DOMAIN}/auth/realms/${OAUTH_REALMS}/anonymous-token/api/v1/get`,
      query: {
        code: accessCode,
        anonymousUserId,
        linkId,
        client: OPENID_CLIENT_ID
      }
    },
    { skipNull: true },
  );
  return (await makeRequest({ method: 'GET', url }, { shouldUseToken: false })).data;
};

const UserService = {
  createAnonymousUser,
  getAnonymousUserToken
};

export default UserService;
