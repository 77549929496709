import React, { FC, useState } from 'react';
import { Tooltip as AntdTooltip, Icon } from 'antd';
import { useIntl } from 'react-intl';
import HintModal from './HintModal';

import { IFormSpec, GetBindedTextType } from '../../types';

interface ITooltipMetadata {
  useModal: boolean;
}

interface CustomTooltipProps extends Pick<IFormSpec, 'tooltip'| 'name'> {
  tooltipMetadata?: ITooltipMetadata;
  getBindedText?: GetBindedTextType;
}

const CustomTooltip: FC<CustomTooltipProps> = ({
  name,
  tooltip,
  tooltipMetadata,
  getBindedText,
}) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const showOrHideModal = () => setShowModal(showHide => !showHide);

  const translatedTooltip = tooltip
    ? (
      getBindedText?.(intl.formatMessage({ id: `${name}_${tooltip}`, defaultMessage: tooltip }))
      ?? intl.formatMessage({ id: `${name}_${tooltip}`, defaultMessage: tooltip })
    )
    : tooltip;

  if (!translatedTooltip) {
    return null;
  }

  if (!tooltipMetadata?.useModal) {
    return (
      <AntdTooltip
        placement="rightTop"
        arrowPointAtCenter
        className="formbuilder__tooltip"
        title={translatedTooltip}
        trigger="click"
      >
        <Icon type="question-circle" className="formbuilder__tooltip__icon" />
      </AntdTooltip>
    );
  }

  return (
    <>
      <Icon type="question-circle" onClick={showOrHideModal} className="formbuilder__tooltip__icon" />
      <HintModal
        bbcode={translatedTooltip}
        visible={showModal}
        closable
        onCancelButtonClick={showOrHideModal}
        onOkButtonClick={showOrHideModal}
      />
    </>
  );
};

export default CustomTooltip;
