import styled from 'styled-components';

interface IAffixProps {
  fontSize?: number;
}

const Affix = styled.span<IAffixProps>`
  font-size: ${props => (props.fontSize ? `${props.fontSize}rem` : '1em')};
`;

export default Affix;
