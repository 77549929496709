const injectMetadata = (
  coreData: any,
  userInfo: any,
  shouldNotMutateCoreData = false
) => {
  // deep copy coreData
  const data = shouldNotMutateCoreData
    ? coreData
    : JSON.parse(JSON.stringify(coreData));

  if (!Object.prototype.hasOwnProperty.call(data, 'FP')) {
    data.FP = {};
  }
  const fpInfo = userInfo ? { ...userInfo } : {};
  data.FP = { ...data.FP, ...fpInfo };

  return data;
};

export default injectMetadata;
