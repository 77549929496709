export const removeFreezedDocument = (coreData: any, mutate = false) => {
  const coreDataCopy = mutate ? coreData : JSON.parse(JSON.stringify(coreData));
  coreDataCopy.Print && delete coreDataCopy.Print;

  return coreDataCopy;
};

export const getPreGeneratedDocument = (
  data: any, initialOrder: number, preProcessorsObject: any
) => Object.entries<{
  [documentType: string]: any;
}>(
  data?.Documents ?? {}
).map(([documentType, { id, path, original_filename: filename }], index) => {
  const filenameWithoutExtension = filename?.replace(/.pdf$/, '');

  const value: any = {
    id,
    path,
    metadata: {
      Order: initialOrder + index,
      FormName: filenameWithoutExtension ?? documentType,
      Mandatory: true,
    }
  };

  const jsonPathForId = `Documents.${documentType}.id`;
  const jsonPathForPath = `Documents.${documentType}.path`;
  const PreProcessors = preProcessorsObject?.[jsonPathForId]
    ?? preProcessorsObject?.[jsonPathForPath];
  if (PreProcessors) {
    value.metadata.PreProcessors = PreProcessors;
  }
  return value;
});
