import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import ErrorContext from '../../context';
import { IFormFieldProps } from '../../types';

const ErrorDiv = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.color.alert};
  padding: 0.25rem;
`;

type IErrorProps = Partial<
  Pick<IFormFieldProps, 'error' | 'required' | 'value' | 'name'>
>;
const Error: FC<IErrorProps> = ({
  error,
  required,
  value,
  name,
}) => {
  const { attemptedSubmit } = useContext(ErrorContext);

  // Check if excel formula returns any error msg first,
  // then check required-field by submit-status
  const showError = !!error
    || (attemptedSubmit && required && ['', undefined, null].includes(value));
  const errorMsg = error || 'This field is required';

  return showError ? (
    <ErrorDiv className="formbuilder__error">
      <FormattedMessage id={`${name}_${errorMsg}`} defaultMessage={errorMsg} />
    </ErrorDiv>
  ) : null;
};

export default Error;
