import { timeTypes } from '../types';
import { TimeActionTypes } from '../actions';
import { TimeStateType, timeInitialState } from '../states/time.states';

const timeReducer = (
  state: TimeStateType = timeInitialState,
  { type, startTime }: TimeActionTypes
): TimeStateType => {
  switch (type) {
    case timeTypes.SET_START_TIME:
      return { ...state, startTime };
    case timeTypes.CLEAR_START_TIME:
      return { ...state, startTime: null };
    case timeTypes.CLICK_ABS_TIMEOUT_MODAL:
      return { ...state, isAbsTimeoutModalClicked: true };
    default:
      return state;
  }
};

export default timeReducer;
