import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Sup from './Sup';
import Affix from './Affix';
import { IFormFieldProps } from '../../types';

interface IPrefixProps
  extends Pick<
  IFormFieldProps,
  'label'
  | 'metadata'
  | 'readonly'
  | 'required'
  | 'userColor'
  | 'value'
  | 'name'
  > {
  error: boolean;
}

type StyledPrefixProps = Pick<
  IPrefixProps,
  'required' | 'userColor' | 'value' | 'error' | 'readonly'
>;

const StyledPrefix = styled(Affix)<StyledPrefixProps>`
  border-right: 0;
`;

const Prefix: FC<IPrefixProps> = ({
  name,
  label,
  metadata,
  required,
  readonly,
  userColor,
  value,
  error,
}) => (metadata?.prefix ? (
  <StyledPrefix
    className="formbuilder__row__prefix ant-input-group-addon"
    error={error}
    userColor={userColor}
    required={required}
    value={value}
    readonly={readonly}
  >
    <span className="formbuilder__prefix_label">
      <FormattedMessage id={`${name}_${metadata.prefix}`} defaultMessage={metadata.prefix} />
    </span>
    {required && !readonly && !label && <Sup className="formbuilder__prefix__sup">*</Sup>}
  </StyledPrefix>
) : null);

export default Prefix;
