import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Sup from './Sup';
import Affix from './Affix';
import { IFormFieldProps } from '../../types';

interface ISuffixProps
  extends Pick<
    IFormFieldProps,
    | 'label'
    | 'description'
    | 'metadata'
    | 'readonly'
    | 'required'
    | 'userColor'
    | 'value'
    | 'name'
  > {
  error: boolean;
}

type StyledSuffixProps = Pick<
  ISuffixProps,
  'required' | 'userColor' | 'value' | 'error' | 'readonly'
>;

const StyledSuffix = styled(Affix)<StyledSuffixProps>`
  border-left: 0;
`;

const Suffix: FC<ISuffixProps> = ({
  name,
  label,
  description,
  metadata,
  required,
  readonly,
  error,
  userColor,
  value,
}) => (metadata?.suffix ? (
  <StyledSuffix
    className="formbuilder__row__suffix ant-input-group-addon"
    error={error}
    userColor={userColor}
    required={required}
    value={value}
    readonly={readonly}
  >
    <span className="formbuilder__suffix_label">
      <FormattedMessage id={`${name}_${metadata.suffix}`} defaultMessage={metadata.suffix} />
    </span>
    {required && !readonly && !label && !metadata.prefix && !description && (
      <Sup className="formbuilder__suffix__sup">*</Sup>
    )}
  </StyledSuffix>
) : null);

export default Suffix;
