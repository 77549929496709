export const removeSignature = (coreData: any, mutate = false) => {
  const coreDataCopy = mutate ? coreData : JSON.parse(JSON.stringify(coreData));
  Object.entries(coreDataCopy).forEach(
    ([, value]: any) => (
      value instanceof Object && !Object.keys(value).includes('Signature')
        ? removeSignature(value, true)
        : (value
            && value.Signature
            && delete value.Signature) // eslint-disable-line no-param-reassign
    )
  );
  return coreDataCopy;
};

export const isSignatureField = (jsonPath: string) => {
  // Can actually import isValidPropertyPath from format-validate.ts for use
  // But it would be better to keep helper pure functoinal
  const re = /^[a-zA-Z]([a-zA-Z0-9_]*\.)+Signature((\.[a-zA-Z][a-zA-Z0-9_]*)+)?$/;
  return re.test(jsonPath);
};
