import axios from 'axios';

import errorLogger from './error-logger';

export interface IGetConfigurations {
  maintenance?: boolean;
}

export const getConfigurations = async (): Promise<IGetConfigurations> => {
  try {
    const { REACT_APP_FRONTEND_CONFIG_PATH: FRONTEND_CONFIG_PATH } = process.env;

    if (!FRONTEND_CONFIG_PATH) {
      throw new Error('MISSING_FRONTEND_CONFIG_PATH');
    }

    const response = await axios({ method: 'GET', url: FRONTEND_CONFIG_PATH });

    if (!(response.status >= 200 && response.status < 300)) {
      throw new Error('FAILED_TO_FETCH_FRONTEND_CONFIG');
    }

    return response.data;
  } catch (error) {
    errorLogger(error);
    return {};
  }
};
