import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../FormBuilderLibrary/components/FormCommon';

export interface FormLabelProps {
  name: string;
  label?: string | ReactNode;
  labelId?: string;
  labelFontSize?: number;
  labelFontWeight?: string;
  labelColor?: string;
  toolTipSpec?: string;
}

const StyledLabel = styled.label<Omit<FormLabelProps, 'name'>>`
  /* Temp: cannot find a better way to style .ant-form label other than !important at the moment */
  font-size: ${props => (props.labelFontSize ? `${props.labelFontSize}px` : '0.8125rem')} !important;
  font-weight: ${props => props.labelFontWeight || 500};
  white-space: normal;
  color: ${props => props.labelColor || props.theme.color.labelColor};
`;

const FormLabel: FC<FormLabelProps> = ({
  name,
  label,
  labelId,
  toolTipSpec,
  ...labelProps
}) => (label ? (
  <StyledLabel {...labelProps} htmlFor={labelId}>
    {label}
    {!!toolTipSpec && <Tooltip name={name} tooltip={toolTipSpec} />}
  </StyledLabel>
) : null);

export default FormLabel;
