import antdEn from 'antd/lib/locale/en_US';
import antdZhTw from 'antd/lib/locale/zh_TW';
import antdZhCn from 'antd/lib/locale/zh_CN';
import antdTh from 'antd/lib/locale/th_TH';
import antdEs from 'antd/lib/locale/es_ES';
import antdJa from 'antd/lib/locale/ja_JP';
import {
  en, zhHK, zhCN, th, my, es, ja
} from '../locale';

const formatLanguagePackage = (
  current: object, prefix = '', result = {}
) => {
  Object.entries(current).forEach(([key, value]) => {
    switch (typeof value) {
      case 'string':
        // eslint-disable-next-line no-param-reassign
        result[`${prefix}${key}`] = value;
        break;
      case 'object':
        formatLanguagePackage(value, `${prefix}${key}.`, result);
        break;
      default:
        break;
    }
  });

  return result;
};

export const translationDictFromLocale = {
  en: formatLanguagePackage(en),
  'zh-HK': formatLanguagePackage(zhHK),
  'zh-CN': formatLanguagePackage(zhCN),
  th: formatLanguagePackage(th),
  my: formatLanguagePackage(my),
  es: formatLanguagePackage(es),
  ja: formatLanguagePackage(ja),
};

// Ant Design isn't supporting Burmese at the moment
// FIXME: Maintain a custom locale file for Burmese
export const antdLocaleMap = {
  en: antdEn,
  'zh-HK': antdZhTw,
  'zh-CN': antdZhCn,
  th: antdTh,
  my: antdEn,
  es: antdEs,
  ja: antdJa,
};

export const availableLanguages = (
  process.env.REACT_APP_ENABLED_LOCALE ?? 'en:EN'
).split(',').map(language => language.split(':'));
