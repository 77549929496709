export const printPDF = async (
  filesToPrint,
  caseId,
  userInfo,
  downloadMergedPdf,
  onPrintError = () => {},
  ignoreSave = false,
  submitted = false,
  ignoreDownload = false,
) => {
  if (filesToPrint.length === 0) {
    return onPrintError();
  }

  const docStoreFiles = filesToPrint
    .sort((a, b) => a.metadata.Order - b.metadata.Order)
    .map(file => {
      const { FormName, PreProcessors } = file.metadata;
      const fileConfig: any = {
        FormName,
      };
      if (file?.id) {
        fileConfig.DocumentId = file.id;
      }
      if (file?.path) {
        fileConfig.DocStoreFilePath = file.path;
      }
      if (PreProcessors) {
        fileConfig.PreProcessors = PreProcessors;
      }
      return fileConfig;
    });

  return downloadMergedPdf(
    caseId,
    docStoreFiles,
    userInfo,
    ignoreSave,
    submitted,
    ignoreDownload
  );
};

export const onDownloadPDF = async (
  docFiles,
  caseId,
  userInfo,
  downloadMergedPdf,
  downloadPdfError,
  setModalVisibility,
  ignoreSave = false,
  submitted = false,
  ignoreDownload = false,
) => {
  if (docFiles.length === 0) {
    return downloadPdfError();
  }

  const mandatoryFiles = docFiles.filter(
    file => file.metadata?.Mandatory
  );

  if (mandatoryFiles.length === docFiles.length) {
    const result = await printPDF(
      mandatoryFiles,
      caseId,
      userInfo,
      downloadMergedPdf,
      () => {},
      ignoreSave,
      submitted,
      ignoreDownload
    );

    if (ignoreDownload) {
      return result;
    }

    if (result && !result.errorCode) {
      // TODO: support zip extension in default value
      const filename = result.headers.get('Content-Disposition')?.match(/filename="(.*)"/)?.[1] ?? 'file.pdf';
      const filetype = result.headers.get('Content-Type') ?? 'application/pdf';

      return new File([result.payload], filename, { type: filetype });
    }

    return undefined;
  }
  return setModalVisibility?.(true);
};
