export const isValidEmailUser = (emailUser: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
  return re.test(emailUser);
};

export const isValidEmailDomain = (emailDomain: string) => {
  const re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailDomain);
};

export const isValidEmail = (email: string) => {
  const [emailUser, emailDomain] = email.split('@');

  if (emailUser && emailDomain) {
    return isValidEmailUser(emailUser) && isValidEmailDomain(emailDomain);
  }
  return false;
};

export const isValidVerificationCode = (code: string) => {
  const re = /^[0-9]{6}$/;
  return re.test(code);
};

export const isValidPassword = (password: string) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
  return re.test(password);
};

export const isValidImageUrl = (imageUrl: string) => {
  const re = /^(http(s)?:\/\/(.*)|data:(.*)|blob:(.*))$/;
  return re.test(imageUrl);
};

export const isBlobUrl = (url: string) => {
  const re = /^blob:(.*)$/;
  return re.test(url);
};

// If the incoming string has slash, then assume it's not a jsonPath
export const isValidPropertyPath = (propertyPath: string) => !/\//.exec(propertyPath);

export const isValidUuid = (str: string) => {
  const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return re.test(str);
};

export const isSignatureDatePath = (str: string) => {
  const re = /^(.*\.Signature.*)\.date(_DATEDDMMYYYY.*)?$/i;
  const isValid = re.test(str);
  const matchResult = str.match(re);

  return {
    valid: isValid,
    parentNode: matchResult?.[1] ?? null,
  };
};

export const isValidUrl = (url: string) => {
  const re = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
  return re.test(url);
};
