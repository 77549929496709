import React, { FC, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'antd';
import Tooltip from './Tooltip';

interface StyledButtonProps {
  selected: boolean;
  readonly: boolean;
}
interface ICheckboxImageProps extends StyledButtonProps {
  name: string;
  value: string;
  label: string;
  tooltip?: string;
  selectedImagePath?: string;
  unselectedImagePath?: string;
  onChange: (value: string, selected: boolean) => void;
}

const StyledWrapper = styled.div`
  flex: 1;
  padding: ${props => props.theme.unit.space_sm};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledButton = styled(({ selected, readonly, ...buttonProps }) => (
  <Button {...buttonProps} />
))<StyledButtonProps>`
  flex: 1 1 auto;

  &.ant-btn {
    border-radius: ${props => props.theme.unit.corner_sm};
    width: 100%;
    height: auto;
    padding: 0.5rem;
    cursor: pointer;
    box-shadow: none;

    img {
      border: none;
      width: 100%;
    }
  }

  &.ant-btn:not(.ant-btn-danger):not(.ant-btn-primary):not([disabled]) {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: ${props => (props.selected
        ? props.theme.color.primary
        : props.readonly
        ? props.theme.color.transparent
        : props.theme.color.white)};
      border: 0.0625rem solid ${props => (props.selected
        ? props.theme.color.primary
        : props.theme.color.border)};
    }
  }

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
`;

const StyledLabel = styled.div`
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  margin-top: 0.625rem;
  font-weight: 500;
`;

const StyledTooltipContainer = styled.span`
  margin-left: ${props => props.theme.unit.space_sm};

  .anticon {
    vertical-align: middle;
    line-height: 1em;
  }
`;

const CheckboxImage: FC<ICheckboxImageProps> = ({
  name,
  onChange,
  selected,
  value,
  label,
  tooltip = '',
  selectedImagePath,
  unselectedImagePath,
  readonly,
}) => {
  const onClick = useCallback(() => {
    onChange(value, !selected);
  }, [onChange, value, selected]);

  const imagePath = selected
    ? selectedImagePath || unselectedImagePath
    : unselectedImagePath || selectedImagePath;

  return (
    <StyledWrapper>
      <StyledButton
        className="formbuilder__checkboxImage__btn"
        selected={selected}
        onClick={onClick}
        readonly={readonly}
      >
        <img className="formbuilder__checkboxImage__image" src={imagePath} alt={label} />
      </StyledButton>
      <StyledLabel className="formbuilder__checkboxImage__label">
        <FormattedMessage id={`${name}_${label}`} defaultMessage={label} />
        <StyledTooltipContainer>
          <Tooltip name={name} tooltip={tooltip} />
        </StyledTooltipContainer>
      </StyledLabel>
    </StyledWrapper>
  );
};

export default memo(CheckboxImage);
