import { ThunkDispatch } from 'redux-thunk';

import { productsTypes } from '../types';
import { alertActions, AlertActionTypes } from '.';
import { AppState } from '../reducers';
import { IProduct, IProductCategory } from '../../interfaces';
import { ExcelEngineService } from '../../services';
import { isRequestSuccess, getRequestErrorCode } from '../../services/service.helpers';
import {
  isGetFormSpecSuccess,
  isGetFormSpecVersionSuccess,
  isGetFormStateByDataSuccess,
  isGetFormStateSuccess,
} from '../../services/excelEngine.service.helpers';
import { constructFormSpec, errorLogger } from '../../helpers';

export interface ProductsActionType {
  type: string;
  productList?: {
    [key: string]: IProduct[]
  };
  productCategories?: {
    [key: string]: IProductCategory[];
  };
  formSpecDetailsToBeAdded?: [string, object];
}

// output undefined as string if REACT_APP_DEFAULT_PRODUCT_FACTORY_PRODUCT is not defined
const DEFAULT_PRODUCT_NAME = `${process.env.REACT_APP_DEFAULT_PRODUCT_FACTORY_PRODUCT}`;

const fetchFromProductDomain = (
  productDomain: string, subFolderPath = '',
) => async (
  dispatch: ThunkDispatch<AppState, null, ProductsActionType | AlertActionTypes>,
) => {
  try {
    const documentFolderPath = subFolderPath
      ? `/apps/${productDomain}/${subFolderPath}`
      : `/apps/${productDomain}`;
    const { data: payload } = await ExcelEngineService.getFileManagerFolder(
      documentFolderPath
    );

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    } else if (!(Array.isArray(payload?.data?.documents) && payload.data.documents.length)) {
      throw new Error('NO_DOCUMENTS_EXISTS_IN_THIS_FOLDER');
    }

    const productDictionaryPromises = payload.data.documents.map(
      async ({ name, id }) => {
        const key = name.split('.')[0]; // Assume every name is in XXX.json format
        const value = (await ExcelEngineService.getFileManagerDocument(id, name)).data;
        return ([key, value]);
      }
    );

    const productDictionary = Object.fromEntries(await Promise.all(productDictionaryPromises));

    [
      { key: 'productList', type: productsTypes.FETCH_PRODUCTS_SUCCESS },
      { key: 'productCategories', type: productsTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS },
    ].forEach(({ key, type }) => {
      subFolderPath === key
        && dispatch({ type, [key]: productDictionary });
    });

    return true;
  } catch (error) {
    dispatch({ type: productsTypes.FETCH_PRODUCTS_ERROR });
    errorLogger(error);
    return false;
  }
};

const getFormSpecVersion = (engineName: string, productName?: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const { data: payload } = await ExcelEngineService.getFormSpecVersion(
      engineName,
      productName ?? DEFAULT_PRODUCT_NAME,
    );

    if (!isGetFormSpecVersionSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    return payload.data;
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'INVALID_PRODUCT_NAME',
      'INVALID_ENGINE_NAME',
      'ENGINE_NOT_FOUND',
      'DOCUMENT_NOT_FOUND'
    ].includes(error.message)
      ? `productAction.getFormSpecVersion.${error.message}`
      : 'UNKNOWN_ERROR'; // INTERNAL_SERVER_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return null;
  }
};

const getFormSpec = (engineName: string, incomingProductName?: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
  getState,
) => {
  const productName = incomingProductName ?? DEFAULT_PRODUCT_NAME;

  // Check if required formSpec is already cached
  const formSpecVersonResponse = await dispatch(getFormSpecVersion(engineName, productName));
  const version = formSpecVersonResponse?.versionNo;
  const release = formSpecVersonResponse?.release;
  const engineDict = getState()?.products?.formSpecDict?.[productName] ?? {};

  // Take the cached formSpec if versionID and releaseID is not updated
  if (
    engineDict?.[engineName]?.id === version
    && engineDict?.[engineName]?.release === release
  ) {
    return engineDict?.[engineName];
  }

  // Get the latest formSpec from server
  try {
    const { data: payload } = await ExcelEngineService.getFormSpec(engineName, productName);

    if (!isGetFormSpecSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    // Construct formSpec
    const formSpec = constructFormSpec(payload.data);
    dispatch({
      type: productsTypes.ADD_FORMSPEC_SUCCESS,
      formSpecDetailsToBeAdded: [`${productName}.${engineName}`, formSpec],
    });

    return formSpec;
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'INVALID_PRODUCT_NAME',
      'INVALID_ENGINE_NAME',
      'ENGINE_NOT_FOUND',
      'DOCUMENT_NOT_FOUND'
    ].includes(error.message)
      ? `productAction.getFormSpec.${error.message}`
      : 'UNKNOWN_ERROR'; // INTERNAL_SERVER_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return null;
  }
};

const getFormStateByData = (fieldValues: any, engineName: string, productName?: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const { data } = await ExcelEngineService.getFormStateByData(
      engineName,
      productName ?? DEFAULT_PRODUCT_NAME,
      fieldValues,
    );

    if (!isGetFormStateByDataSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return data;
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'INVALID_PRODUCT_NAME',
      'INVALID_ENGINE_NAME',
      'ENGINE_NOT_FOUND',
      'DOCUMENT_NOT_FOUND'
    ].includes(error.message)
      ? `productAction.getFormStateByData.${error.message}`
      : 'UNKNOWN_ERROR'; // INTERNAL_SERVER_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return null;
  }
};

const getFormState = (
  participantId: string,
  participantKey: string,
  sessionUrl: string,
  engineName: string,
  productName?: string
) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
) => {
  try {
    const { data: payload } = await ExcelEngineService.getFormState(
      engineName,
      productName ?? DEFAULT_PRODUCT_NAME,
      participantId,
      participantKey,
      sessionUrl,
    );

    if (!isGetFormStateSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    return payload.data;
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'INVALID_PRODUCT_NAME',
      'INVALID_ENGINE_NAME',
      'ENGINE_NOT_FOUND',
      'DOCUMENT_NOT_FOUND'
    ].includes(error.message)
      ? `productAction.getFormState.${error.message}`
      : 'UNKNOWN_ERROR'; // INTERNAL_SERVER_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return null;
  }
};

export const productsAction = {
  fetchFromProductDomain,
  getFormSpecVersion,
  getFormSpec,
  getFormStateByData,
  getFormState,
};
