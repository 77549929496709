import sentryInit from './sentry';
import { apmInit } from './apm';

// Helper function to disable logging on localhost to prevent data pollution
const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Put all logging service here
if (!isLocalhost) {
  sentryInit();
}

apmInit();
