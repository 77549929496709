import React, {
  FC, useState, useMemo, useCallback, ChangeEvent
} from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Input, Checkbox } from 'antd';

import Modal from '.';

export type deleteReasonOptionsKey = 'another-product' | 'another-insurer' | 'not-this-time' | 'other';

export interface IDeleteCaseReasonOption {
  key: deleteReasonOptionsKey;
  reason: string;
}

export interface IDeleteCaseModal {
  visible: boolean;
  onQuitDeleteModal: () => void;
  skipReason?: boolean;
  onDeleteCase: (reason?: string) => Promise<void>;
  deleteReasonOptionKeys: deleteReasonOptionsKey[];
}

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper,
  &.ant-checkbox-wrapper + &.ant-checkbox-wrapper {
    margin-left: 0;
    margin-bottom: 0.86rem;
    font-size: 0.86rem;
  }
`;

const DeleteCaseModal: FC<IDeleteCaseModal> = ({
  visible,
  onQuitDeleteModal,
  skipReason,
  onDeleteCase,
  deleteReasonOptionKeys,
}) => {
  const intl = useIntl();
  const [deleteCaseKey, setDeleteCaseKey] = useState<deleteReasonOptionsKey>();
  const [deleteCaseReason, setDeleteCaseReason] = useState<string>();
  const [deleteCaseOtherReason, setDeleteCaseOtherReason] = useState<string>();

  const [isDeletingCase, setIsDeletingCase] = useState(false);

  const deleteCaseReasonOptions = deleteReasonOptionKeys.map(key => ({
    key,
    reason: intl.formatMessage({ id: `deleteCaseModal.reason.${key}` })
  }));

  const resetDeleteCase = useCallback(() => {
    onQuitDeleteModal();
    setDeleteCaseKey(undefined);
    setDeleteCaseReason(undefined);
    setDeleteCaseOtherReason(undefined);
  }, [onQuitDeleteModal]);

  const reasonByDeleteCaseKey = useMemo(() => (
    deleteCaseKey === 'other'
      ? deleteCaseOtherReason
      : deleteCaseReason),
  [deleteCaseKey, deleteCaseOtherReason, deleteCaseReason]);

  const onDeleteButtonClick = useCallback(async () => {
    setIsDeletingCase(true);
    await onDeleteCase(reasonByDeleteCaseKey);
    resetDeleteCase();
    setIsDeletingCase(false);
  }, [resetDeleteCase, onDeleteCase, reasonByDeleteCaseKey]);

  return (
    <Modal
      visible={visible}
      title={intl.formatMessage({ id: 'deleteCaseModal.title' })}
      description={skipReason ? undefined : intl.formatMessage({ id: 'deleteCaseModal.description' })}
      cancelButtonLabel={intl.formatMessage({ id: 'deleteCaseModal.cancelButton' })}

      onCancelButtonClick={resetDeleteCase}
      okButtonLabel={intl.formatMessage({ id: 'deleteCaseModal.deleteButton' })}

      okButtonDisable={skipReason ? false : !reasonByDeleteCaseKey}
      okButtonLoading={isDeletingCase}
      onOkButtonClick={onDeleteButtonClick}
      destroyOnClose
      className="delete-case-modal"
    >
      {
        skipReason ? undefined : (
          <CheckboxGroup>
            {deleteCaseReasonOptions.map(({ key, reason }, index) => (
              <StyledCheckbox
                key={key}
                onChange={() => {
                  setDeleteCaseKey(key);
                  setDeleteCaseReason(reason);
                }}
                checked={deleteCaseKey === key}
                className="delete-case-modal__checkbox"
                id={`deleteReason_${index}`}
              >
                {reason}
              </StyledCheckbox>
            ))}
            <Input.TextArea
              rows={3}
              placeholder={intl.formatMessage({ id: 'deleteCaseModal.textarea.placeholder' })}
              disabled={deleteCaseKey !== 'other'}
              value={deleteCaseOtherReason}
              onChange={(
                e: ChangeEvent<HTMLTextAreaElement>
              ) => setDeleteCaseOtherReason(e.target.value)}
              className="delete-case-modal__textarea"
              id="deleteReason_textarea"
            />
          </CheckboxGroup>
        )
      }
    </Modal>
  );
};

export default DeleteCaseModal;
