import { makeRequest } from '../helpers';
import {
  IGetFormSpec,
  IGetFormSpecVersion,
  IGetFormStateByData,
  IGetFormState,
  IFetchPDF,
  IFetchPDFWithMetadata
} from './excelEngine.service.types';
import { apiEndpointDomians } from '../constants';

const {
  EXCELENGINE_DOMAIN_WITH_PATH,
  FILEMANAGER_DOMAIN_WITH_PATH
} = apiEndpointDomians;

/*
 * Get form spec by service name using newly created form builder apis in excel engine.
 * https://github.com/CoherentCapital/ExcelEngine/blob/dev/docs/ProductCatalogueAPI.md#product-engine---get-form-spec
 */
const getFormSpec = async (
  engineName: string, productName: string
): Promise<IGetFormSpec> => {
  const url = `${EXCELENGINE_DOMAIN_WITH_PATH}/product/${encodeURIComponent(productName)}/engines/${encodeURIComponent(engineName)}/GetFormSpec`;
  return makeRequest({ method: 'POST', url });
};

/*
 * Get form specifications version with release details
 * https://github.com/CoherentCapital/ExcelEngine/blob/dev/docs/ProductCatalogueAPI.md#product-engine---get-form-spec-version
 */
const getFormSpecVersion = async (
  engineName: string, productName: string
): Promise<IGetFormSpecVersion> => {
  const url = `${EXCELENGINE_DOMAIN_WITH_PATH}/product/${encodeURIComponent(productName)}/engines/${encodeURIComponent(engineName)}/GetFormSpecVersion`;
  return makeRequest({ method: 'POST', url });
};

/*
 * Get form state by service name & list of form fields using newly created form builder apis in excel engine.
 * https://github.com/CoherentCapital/ExcelEngine/blob/dev/docs/ProductCatalogueAPI.md#product-engine---get-form-state
 * unused?
 */
const getFormStateByData = async (
  engineName: string,
  productName: string,
  fieldValues: any,
): Promise<IGetFormStateByData> => {
  const url = `${EXCELENGINE_DOMAIN_WITH_PATH}/product/${encodeURIComponent(productName)}/engines/${encodeURIComponent(engineName)}/GetFormState`;
  const data = fieldValues;
  return makeRequest({ method: 'POST', url, data });
};

/*
 *  https://github.com/CoherentCapital/ExcelEngine/blob/dev/docs/ProductCatalogueAPI.md#product-engine---get-form-state-from-live-server
 */
const getFormState = async (
  engineName: string,
  productName: string,
  participantId: string,
  participantKey: string,
  sessionUrl: string,
): Promise<IGetFormState> => {
  const url = `${EXCELENGINE_DOMAIN_WITH_PATH}/product/${encodeURIComponent(productName)}/engines/${encodeURIComponent(engineName)}/GetLiveServerFormState`;
  const data = { participantId, participantKey, sessionUrl };
  return makeRequest({ method: 'POST', url, data });
};

/*
 *  https://github.com/CoherentCapital/ExcelEngine/blob/PF-848/docs/FileManagerAPI.md#get-folder--document-list
 */
const getFileManagerFolder = async (dirPath: string): Promise<IFetchPDF> => {
  const url = `${FILEMANAGER_DOMAIN_WITH_PATH}/GetFolderDocs?folderpath=${dirPath}`;
  return makeRequest({ method: 'GET', url });
};

/*
 *  https://github.com/CoherentCapital/ExcelEngine/blob/PF-848/docs/FileManagerAPI.md#get-folder--document-list-with-metadata
 */
const getFileManagerFolderWithMetadata = async (dirPath: string): Promise<
  IFetchPDFWithMetadata
> => {
  const url = `${FILEMANAGER_DOMAIN_WITH_PATH}/GetFolderDocsWithMetadata?folderpath=${dirPath}`;
  return makeRequest({ method: 'GET', url });
};

/*
 * https://github.com/CoherentCapital/ExcelEngine/blob/PF-848/docs/FileManagerAPI.md#download-a-document
 */
const getFileManagerDocument = async (documentId: string, fileName: string): Promise<any> => {
  const url = `${FILEMANAGER_DOMAIN_WITH_PATH}/DownloadDocument/${documentId}/${fileName}`;
  return makeRequest({ method: 'GET', url });
};

const ExcelEngineService = {
  getFormSpec,
  getFormSpecVersion,
  getFormStateByData,
  getFormState,
  getFileManagerFolder,
  getFileManagerFolderWithMetadata,
  getFileManagerDocument
};

export default ExcelEngineService;
