import React, { FC } from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

type SuspenseLoadingType = {
  className?: string;
  containerHeight?: string;
};

const Wrapper = styled.div<{ containerHeight?: string; }>`
  color: ${props => props.theme.color.primary};
  display: flex;
  padding: ${props => props.theme.unit.space_md};
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  height: ${({ containerHeight }) => (containerHeight || '100%')};
`;

const SuspenseLoading: FC<SuspenseLoadingType> = ({ className, containerHeight }) => (
  <Wrapper className={`loading-spinner ${className}`} containerHeight={containerHeight}>
    <Icon type="loading" spin />
  </Wrapper>
);

export default SuspenseLoading;
