import { detect } from 'detect-browser';
import { AppConstants } from '../constants';

const checkUnsupportedBrowser = () => {
  const { name, version } = detect() ?? {};
  return Object.entries(AppConstants.UNSUPPORTED_BROWSER_VERSION).some((
    [browser, maxUnsupportedVersion]
  ) => browser === name && Number(version?.split('.')?.[0]) <= maxUnsupportedVersion);
};

export default checkUnsupportedBrowser;
