import React, {
  FC, memo, ReactNode
} from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Row, Col } from 'antd';
import CSSTransitionGroup from 'react-addons-css-transition-group';

import { Label, Description, Error } from '.';
import { IFormFieldProps } from '../../types';

export interface IFieldRowProps
  extends Pick<
  IFormFieldProps,
  | 'name'
  | 'value'
  | 'label'
  | 'description'
  | 'tooltip'
  | 'metadata'
  | 'error'
  | 'invisible'
  | 'required'
  | 'readonly'
  | 'getBindedText'
  > {
  hideLabel?: boolean;
  hideDescription?: boolean;
  singleColumn?: boolean;
  centerLabel?: boolean;
  alignItem?: 'top' | 'middle' | 'bottom' | 'stretch';
  children: ReactNode;
  forceFieldLabelOnTop: boolean;
}

const ANIMATION_TIME = 500;
const ANIMATION_REPEAT = 2;

const blinker = keyframes`
  50% {
    opacity: 0;
  }
`;

const blinkAnimation = css`
  animation: ${blinker} ${ANIMATION_TIME}ms linear ${ANIMATION_REPEAT};
`;

const Wrapper = styled.div`
  margin-bottom: ${props => props.theme.unit.space_md};

  &:last-of-type {
    margin-bottom: 0;
  }

  /* TODO: Temp disable all ugly field focus outline until we refactor the field focus function */
  .formbuilder__row__field > div {
    outline: none;
  }

  // Fade in animation
  &.field-row-animation {
    &-enter,
    &-leave&-leave-active {
      ${blinkAnimation}
    }

    &-leave,
    &-enter&-enter-active {
      ${blinkAnimation}
    }
  }
`;

const FieldRow: FC<IFieldRowProps> = ({
  hideLabel = false,
  hideDescription = false,
  singleColumn = false,
  centerLabel = false,
  alignItem = 'middle',
  name,
  value: fieldValue,
  label,
  description,
  tooltip,
  metadata,
  error,
  invisible,
  required,
  readonly,
  children,
  forceFieldLabelOnTop,
  getBindedText,
}) => {
  const displayDescription = !!description && !hideDescription;
  const displayLabel = !!label && !hideLabel;
  const labelOnTop = !forceFieldLabelOnTop && !singleColumn;

  return (
    <CSSTransitionGroup
      component={React.Fragment}
      transitionName="field-row-animation"
      transitionEnterTimeout={ANIMATION_TIME * ANIMATION_REPEAT + 100}
      transitionLeaveTimeout={ANIMATION_TIME * ANIMATION_REPEAT + 100}
    >
      {!invisible ? (
        <Wrapper id={`row_${name}`} className="formbuilder__row field-row-animation">
          <Row gutter={[16, 0]} type="flex" align={alignItem}>
            {displayDescription && (
              <Col span={24} className="formbuilder__row__description">
                <Description
                  name={name}
                  label={label}
                  description={description}
                  metadata={metadata}
                  required={required}
                  readonly={readonly}
                />
              </Col>
            )}
            {displayLabel && (
              <Col
                xs={24}
                md={labelOnTop ? 11 : 24}
                className="formbuilder__row__label"
              >
                <Label
                  name={name}
                  label={label}
                  tooltip={tooltip}
                  metadata={metadata}
                  required={required}
                  readonly={readonly}
                  textAlignment={centerLabel ? 'center' : 'left'}
                  getBindedText={getBindedText}
                />
              </Col>
            )}
            <Col
              xs={24}
              md={displayLabel && labelOnTop ? { span: 12, offset: 1 } : 24}
              className="formbuilder__row__field"
            >
              {children}
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              md={displayLabel && labelOnTop ? { span: 12, offset: 12 } : 24}
              className="formbuilder__row__error"
            >
              <Error name={name} error={error} required={required} value={fieldValue} />
            </Col>
          </Row>
        </Wrapper>
      ) : null}
    </CSSTransitionGroup>
  );
};

export default memo(FieldRow);
