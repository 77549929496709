import jwt from 'jsonwebtoken';
import jwksClient from 'jwks-rsa';
import ClientOAuth2 from 'client-oauth2';
import { routes, AppConstants } from '../constants';
import { errorLogger } from '.';
import displayNameFormatter from './display-name-formatter';
import stringToBoolean from './string-to-boolean';
import { IUserInfo } from '../interfaces';

const validRole = ['user', 'supervisor'];
const validHierarchicalGroup = process.env.REACT_APP_VALID_HIERARCHICAL_GROUP || 'coherent';

const OPENID_CLIENT_ID = process.env.REACT_APP_OPENID_CLIENT_ID ?? '';
const OPENID_CLIENT_SECRET = process.env.REACT_APP_OPENID_CLIENT_SECRET || undefined;
const OPENID_AUTHORIZATION_ENDPOINT = process.env.REACT_APP_OPENID_AUTHORIZATION_ENDPOINT ?? '';
const OPENID_TOKEN_ENDPOINT = process.env.REACT_APP_OPENID_TOKEN_ENDPOINT ?? '';
const OPENID_SCOPE = process.env.REACT_APP_OPENID_SCOPE ?? '';
const OPENID_AUTHORIZATION_FLOW = process.env.REACT_APP_OPENID_AUTHORIZATION_FLOW ?? '';

const oAuthSetting = {
  clientId: OPENID_CLIENT_ID,
  clientSecret: OPENID_CLIENT_SECRET,
  authorizationUri: OPENID_AUTHORIZATION_ENDPOINT,
  accessTokenUri: OPENID_TOKEN_ENDPOINT,
  redirectUri: `${window.location.origin}${process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL}${routes.singleSignOnLogin}`,
  scopes: OPENID_SCOPE ? OPENID_SCOPE.split(',') : [],
};

export const userAuth = new ClientOAuth2(oAuthSetting);

export const authorizationFlow = OPENID_AUTHORIZATION_FLOW === 'code' || OPENID_AUTHORIZATION_FLOW === 'token'
  ? OPENID_AUTHORIZATION_FLOW
  : 'code';

export const decodeJwt = async (token: string, verify = false) => {
  if (verify) {
    // Use the jwks_uri to get public key
    // https://keycloak.dev.coherent.com.hk/auth/realms/coherent/.well-known/openid-configuration
    const client = jwksClient({
      jwksUri: process.env.REACT_APP_OPENID_JWKS_URI ?? ''
    });

    const getPublicKey = (header, callback) => {
      client.getSigningKey(header.kid, (error, key: any) => {
        const signingKey = key?.publicKey || key?.rsaPublicKey;
        error && errorLogger(error);
        callback(null, signingKey);
      });
    };

    try {
      const decodedJwt = new Promise((resolve, reject) => {
        jwt.verify(token, getPublicKey, {
          algorithms: ['RS256'],
          ignoreExpiration: false,
        }, (decodeError, decodedValue) => {
          if (decodeError) {
            reject(decodeError);
          }
          resolve(decodedValue);
        });
      });
      return await decodedJwt;
    } catch (error) {
      errorLogger(error);
      return null;
    }
  } else {
    return jwt.decode(token);
  }
};

export const getValidRoleAndGroup = (allRolesAndGroups: string[]) => allRolesAndGroups
  .map(currentRoleAndGroup => {
    const [role, group] = currentRoleAndGroup.split(':').slice(0, 2);
    return { role, group };
  })
  .find(({ role, group }) => validRole.includes(role)
      && validHierarchicalGroup === (
        group?.split('.')[0]
      )) ?? {
  role: '',
  group: '',
};

export const getUserInfoFromDecodedJWT = (decodedJwt : any): IUserInfo => {
  const isDefaultUserGroupAllowed = stringToBoolean(process.env.REACT_APP_IS_DEFAULT_USER_GROUP_ALLOWED ?? 'false');
  const defaultUserGroup = process.env.REACT_APP_DEFAULT_USER_GROUP;
  const lastNameKey = process.env.REACT_APP_OPENID_USERINFO_LAST_NAME_KEY ?? '';
  const firstNameKey = process.env.REACT_APP_OPENID_USERINFO_FIRST_NAME_KEY ?? '';
  const userEmailKey = process.env.REACT_APP_OPENID_USERINFO_EMAIL_KEY ?? '';
  const userIDKey = process.env.REACT_APP_OPENID_USERINFO_USERID_KEY ?? '';

  const groupsFromDecodedJwt: string[] | undefined = decodedJwt?.groups ?? (
    isDefaultUserGroupAllowed && defaultUserGroup
      ? [defaultUserGroup]
      : undefined
  );

  return {
    realm: decodedJwt?.realm || AppConstants.TENANT || '',
    sub: decodedJwt?.[userIDKey] ?? '',
    name: displayNameFormatter(decodedJwt?.[lastNameKey] ?? '', decodedJwt?.[firstNameKey] ?? ''),
    groups: groupsFromDecodedJwt,
    preferredUsername: decodedJwt?.[userEmailKey],
    familyName: decodedJwt?.[lastNameKey] ?? '',
    givenName: decodedJwt?.[firstNameKey] ?? '',
  };
};
