const MAX_IDLE_TIME = parseInt((process.env.REACT_APP_MAX_IDLE_TIME || '7200000'), 10); // 2 hours
const MODAL_COUNTDOWN_TIME = parseInt((process.env.REACT_APP_MODAL_COUNTDOWN_TIME || '60000'), 10); // 1 minute
const MODAL_POPUP_TIME = MAX_IDLE_TIME - MODAL_COUNTDOWN_TIME;
const ABS_MAX_TIME = parseInt((process.env.REACT_APP_ABS_MAX_TIME || '43200000'), 10); // 24 hours
const ABS_MODAL_COUNTDOWN_TIME = parseInt((process.env.REACT_APP_ABS_MODAL_COUNTDOWN_TIME || '300000'), 10); // 5 minutes
const ABS_MAX_TIME_ANONYMOUS = parseInt((process.env.REACT_APP_ABS_MAX_TIME_ANONYMOUS || '7200000'), 10); // 2 hours
const ABS_MODAL_COUNTDOWN_TIME_ANONYMOUS = parseInt((process.env.REACT_APP_ABS_MODAL_COUNTDOWN_TIME_ANONYMOUS || '300000'), 10); // 5 minute

const TimerConstants = {
  MAX_IDLE_TIME,
  MODAL_COUNTDOWN_TIME,
  MODAL_POPUP_TIME,
  ABS_MAX_TIME,
  ABS_MODAL_COUNTDOWN_TIME,
  ABS_MAX_TIME_ANONYMOUS,
  ABS_MODAL_COUNTDOWN_TIME_ANONYMOUS,
};

export default TimerConstants;
