import { ThunkDispatch } from 'redux-thunk';

import { timeTypes } from '../types';
import { AppState } from '../reducers';
import { decodeJwt } from '../../helpers';
import stringToBoolean from '../../helpers/string-to-boolean';

export interface TimeActionTypes {
  type: string;
  startTime: number | null;
}

const shouldValidateToken = stringToBoolean(process.env.REACT_APP_SHOULD_VALIDATE_TOKEN ?? 'true');

const setStartTime = () => async (
  dispatch: ThunkDispatch<AppState, null, TimeActionTypes>,
  getState
) => {
  const {
    time: { startTime },
    user: { activeToken },
  } = getState();
  if (!startTime) {
    const decodedJwt: any = await decodeJwt(activeToken, shouldValidateToken);
    typeof decodedJwt?.iat === 'number' && dispatch({
      type: timeTypes.SET_START_TIME,
      startTime: decodedJwt.iat * 1000,
    });
  }
};

const clearStartTime = () => ({
  type: timeTypes.CLEAR_START_TIME
});

const clickAbsTimeoutModal = () => ({
  type: timeTypes.CLICK_ABS_TIMEOUT_MODAL
});

export const timeActions = {
  setStartTime,
  clearStartTime,
  clickAbsTimeoutModal,
};
