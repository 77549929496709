import { set } from 'lodash';

import { productsTypes } from '../types';
import { ProductsActionType } from '../actions';
import { ProductStateTypes, productInitialState } from '../states/products.states';

const productsReducer = (
  state: ProductStateTypes = productInitialState,
  {
    type,
    productList = {},
    productCategories = {},
    formSpecDetailsToBeAdded,
  }: ProductsActionType,
): ProductStateTypes => {
  switch (type) {
    case productsTypes.FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        productList,
      };
    }
    case productsTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        productCategories,
      };
    }
    case productsTypes.FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        productList: {},
        productCategories: {},
      };
    }
    case productsTypes.ADD_FORMSPEC_SUCCESS:
      /* eslint-disable-next-line no-case-declarations */
      const [path, formSpec] = formSpecDetailsToBeAdded ?? [];
      if (path && formSpec) {
        const newFormSpecDict: typeof state.formSpecDict = (
          JSON.parse(JSON.stringify(state.formSpecDict))
        );
        set(newFormSpecDict, path, formSpec);
        return {
          ...state,
          formSpecDict: newFormSpecDict
        };
      }
      return state;
    default:
      return state;
  }
};

export default productsReducer;
