import { LoginActionTypes } from '../actions';

export type UserStateType = Required<Omit<LoginActionTypes, 'type'>>;

export const userInitialState: UserStateType = {
  userInfo: null,
  activeToken: '',
  refreshToken: '',
  userToken: '',
  anonymousToken: '',
  userName: '',
  isPasswordExpired: false,
  isAccountLocked: false,
  isSupervisor: false,
  isAnonymous: false,
  userGroup: null,
  sessionExpiryTime: null,
};
