import { isRequestSuccess, hasErrorCode } from './service.helpers';

import {
  IGetFormSpecSuccess,
  IGetFormSpecFailure,
  IGetFormStateByDataSuccess,
  IGetFormStateByDataFailure,
  IGetFormStateSuccess,
  IGetFormStateFailure,
  IGetFormSpecVersionSuccess,
  IGetFormSpecVersionFailure,
} from './excelEngine.service.types';

export const isGetFormSpecSuccess = (
  object: IGetFormSpecSuccess | IGetFormSpecFailure
): object is IGetFormSpecSuccess => object && !hasErrorCode(object);

export const isGetFormSpecVersionSuccess = (
  object: IGetFormSpecVersionSuccess | IGetFormSpecVersionFailure
): object is IGetFormSpecVersionSuccess => object && !hasErrorCode(object);

export const isGetFormStateByDataSuccess = (
  object: IGetFormStateByDataSuccess | IGetFormStateByDataFailure
): object is IGetFormStateByDataSuccess => object && !hasErrorCode(object);

export const isGetFormStateSuccess = (
  object: IGetFormStateSuccess | IGetFormStateFailure
): object is IGetFormStateSuccess => isRequestSuccess(object);
