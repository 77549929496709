import { makeRequest } from '../helpers';
import {
  IGetDocument,
  IUploadFile
} from './docStore.service.types';
import { apiEndpointDomians } from '../constants';

const { DOCSTORE_DOMAIN_WITH_PATH } = apiEndpointDomians;

// Entity Store postman: DocStore > Documents > Get Document By Id
const getDocument = async (documentId: string): Promise<IGetDocument> => {
  const url = `${DOCSTORE_DOMAIN_WITH_PATH}/documents/${documentId}`;
  return (await makeRequest({ method: 'GET', url })).data;
};

// Entity Store postman: DocStore > Documents > Add Documents
const uploadDocument = async (
  file: File,
  path: string,
  isTemp,
  privacyLevel: 'Global' | 'Tenant' | 'Private'
): Promise<IUploadFile> => {
  const body = new FormData();
  body.append('file', file);
  body.append('data', JSON.stringify({
    privacyLevel,
    dataJson: {},
    path,
    isTemp
  }));

  const url = `${DOCSTORE_DOMAIN_WITH_PATH}/documents`;
  const contentType = 'multipart/form-data';

  return (await makeRequest({
    method: 'POST',
    url,
    data: body,
    headers: { 'Content-Type': contentType },
  })).data;
};

const DocStoreService = {
  getDocument,
  uploadDocument,
};

export default DocStoreService;
