import React, { FC, ReactNode } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import styled from 'styled-components';

export interface NewFormItemProps extends FormItemProps {
  error?: boolean;
  help?: string | ReactNode;
  padding?: string;
  margin?: string;
  width?: number;
  minwidth?: number;
  maxwidth?: number;
  className?: string;
}

const StyledFormItem = styled(Form.Item)<NewFormItemProps>`
  &.ant-form-item {
    margin: ${props => props.margin || '0px'};
    width: ${props => (props.width ? `${props.width}px` : '100%')};
    min-width: ${props => (props.minwidth ? `${props.minwidth}px` : '0')};
    max-width: ${props => (props.maxwidth ? `${props.maxwidth}px` : 'none')};

    & label {
      display: block;
    }
  }
`;

const FormItem: FC<NewFormItemProps> = ({
  error,
  children,
  ...formItemProps
}) => (
  <StyledFormItem {...formItemProps} validateStatus={error ? 'error' : ''}>
    {children}
  </StyledFormItem>
);

export default FormItem;
