/* eslint-disable camelcase */
import { IStatusSuccess, IStatusFailure } from './service.types';

// isRequestSuccess check for services that must include status in response data
// otherwise specific type guard is used
export const isRequestSuccess = (
  object: IStatusSuccess | IStatusFailure | null
): object is IStatusSuccess => (
  !!object
  && !!object.status
  && ['OK', 'SUCCESS'].includes(object.status.toUpperCase())
);

export const getRequestErrorCode = (object: IStatusFailure | null): string | null => (
  object?.error_code
    ?? object?.errorCode
    ?? object?.error_codes?.[0]
    ?? object?.errors?.[0]
    ?? null
);

export const hasErrorCode = (object: any): boolean => (
  object?.error_code
    || object?.errorCode
    || object?.error_codes?.length
    || object?.errors?.length
);
