import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';

import styled, { FlattenSimpleInterpolation } from 'styled-components';

interface ICustomFormAlert
  extends Partial<
    Omit<AlertProps,
      | 'type'
      | 'message'
      | 'description'
      | 'afterClose'
    >
  > {
  contentValues?: any;
  descriptionValues?: any;
  customFormAlertCss?: FlattenSimpleInterpolation;
  shouldShowAlert?: boolean;
}

interface IFormAlert {
  className?: string;
  type: string;
  content: string;
  description?: string;
  customProps?: ICustomFormAlert;
}

const StyledFormAlert = styled(Alert)<{
  customFormAlertCss?: FlattenSimpleInterpolation
}>`
  ${props => props.customFormAlertCss ?? ''}
`;

const FormInlineErrorAlert: FC<IFormAlert> = ({
  className,
  type,
  content,
  description,
  customProps: {
    contentValues = {},
    descriptionValues = {},
    shouldShowAlert = true,
    customFormAlertCss,
    ...otherCustomProps
  } = {},
}) => {
  const intl = useIntl();
  const messageToBeDisplayed = content ? intl.formatMessage({ id: content }, contentValues) : '';
  const descriptionToBeDisplayed = description ? intl.formatMessage({ id: description }, descriptionValues) : '';

  return shouldShowAlert ? (
    <StyledFormAlert
      {...otherCustomProps}
      className={className}
      type={type as AlertProps['type']}
      message={messageToBeDisplayed}
      description={descriptionToBeDisplayed}
      customFormAlertCss={customFormAlertCss}
    />
  ) : null;
};

export default memo(FormInlineErrorAlert);
