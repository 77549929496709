import {
  IJoinLiveCaseSuccess,
  IJoinLiveCaseFailure,
  IGetLiveCaseSessionSuccess,
  IFetchCaseSuccess,
  IGetCaseDataFromPasteBinSuccess,
  IDownloadCaseFilesSuccess,
  IScriptRunnerFailure,
  IGeneratePolicyNumberSuccess,
  IGeneratePolicyNumberFailure
} from './cases.service.types';
import { IStatusFailure } from './service.types';
import { hasErrorCode } from './service.helpers';

export const isJoinLiveCaseSuccess = (
  object: IJoinLiveCaseSuccess | IJoinLiveCaseFailure
): object is IJoinLiveCaseSuccess => 'data' in object && !!object.data;

export default isJoinLiveCaseSuccess;

export const isGetLiveCaseSessionSuccess = (
  object: IGetLiveCaseSessionSuccess | IStatusFailure
): object is IGetLiveCaseSessionSuccess => object && !hasErrorCode(object);

export const isFetchCaseSuccess = (
  object: IFetchCaseSuccess | IStatusFailure
): object is IFetchCaseSuccess => object && !hasErrorCode(object);

export const isGetCaseDataFromPasteBinSuccess = (
  object: IGetCaseDataFromPasteBinSuccess | IStatusFailure
): object is IGetCaseDataFromPasteBinSuccess => object && !hasErrorCode(object);

export const isDownloadCaseFileSuccess = (
  object: IDownloadCaseFilesSuccess | IStatusFailure
): object is IDownloadCaseFilesSuccess => object && object instanceof Blob;

export const hasGeneratePolicyNumberErrorCode = (
  object: any
): boolean => (
  Boolean(object?.errorCode || object?.data?.Outputs?.Data?.errorCode)
);

export const isGeneratePolicyNumberSuccess = (
  object: IScriptRunnerFailure | IGeneratePolicyNumberSuccess | IGeneratePolicyNumberFailure
): object is IGeneratePolicyNumberSuccess => object && !hasGeneratePolicyNumberErrorCode(object);

export const getGeneratePolicyNumberErrorCode = (
  object: IScriptRunnerFailure | IGeneratePolicyNumberFailure
): string | null => (
  object?.errorCode ?? (object as IGeneratePolicyNumberFailure)?.data?.Outputs?.Data?.errorCode
);
