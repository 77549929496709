import React, { FC } from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { CardProps } from 'antd/es/card';

interface SelectionCardProps extends CardProps {
  checked?: boolean;
  description: string;
  width?: string;
  height?: string;
}

const StyledSelectionCard = styled(Card)<(Pick<SelectionCardProps, 'checked' | 'width' | 'height'>)>`
  outline: none;

  &.ant-card {
    text-align: center;
    ${props => props.width && `width: ${props.width};`}
    ${props => props.height && `height: ${props.height};`}
    background-color: ${props => (props.checked ? props.theme.color.primary : props.theme.color.white)};
    border-radius: ${props => props.theme.unit.corner_md};
    border: 0.0625rem solid ${props => (props.checked ? props.theme.color.primary : props.theme.color.border)};
  }

  & .ant-card-head {
    border-bottom: 0;
    padding: ${props => `${props.theme.unit.space_lg} ${props.theme.unit.space_md}`};
    color: ${props => (props.checked ? props.theme.color.white : props.theme.color.primary)};
  }

  & .ant-card-head-title {
    padding: 0;
    overflow: visible;
    white-space: normal;
  }

  & .ant-card-body {
    padding: ${props => `0 ${props.theme.unit.space_md} ${props.theme.unit.space_lg} ${props.theme.unit.space_md}`};
    color: ${props => (props.checked ? props.theme.color.white : props.theme.color.body)};
    min-height: 5.5rem;
  }
`;

const SelectionCard: FC<SelectionCardProps> = ({
  checked,
  description,
  width,
  height,
  ...props
}) => (
  <StyledSelectionCard
    bordered={false}
    checked={checked}
    className="formbuilder__selection-card"
    width={width}
    height={height}
    {...props}
    tabIndex={-1}
  >
    <p className="formbuilder__selection-card__description">{description}</p>
  </StyledSelectionCard>
);

export default SelectionCard;
