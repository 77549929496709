const userTypes = {
  ANONYMOUS_LOGIN_SUCCESS: 'ANONYMOUS_LOGIN_SUCCESS',

  LOGIN_CHANGE_PASSWORD_REQUIRED: 'USER_LOGIN_CHANGE_PASSWORD_REQUIRED',
  LOGIN_RESET_PASSWORD_REQUIRED: 'USER_LOGIN_RESET_PASSWORD_REQUIRED',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',

  LOGOUT: 'USER_LOGOUT',

  GET_INFO_SUCCESS: 'USER_GET_INFO_SUCCESS',

  SET_USER_TOKEN: 'SET_USER_TOKEN',
  UNSET_ANONYMOUS_ID_TOKEN: 'UNSET_ANONYMOUS_ID_TOKEN',
  SET_ACTIVE_TOKEN: 'SET_ACTIVE_TOKEN',

  REFRESH_USER_TOKEN: 'REFRESH_USER_TOKEN'
};

export default userTypes;
