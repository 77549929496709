import { ThunkDispatch } from 'redux-thunk';
import { set } from 'lodash';
import { casesTypes } from '../types';
import {
  CasesService, PdfService, UserService
} from '../../services';
import { isRequestSuccess, getRequestErrorCode } from '../../services/service.helpers';
import {
  isJoinLiveCaseSuccess,
  isGetLiveCaseSessionSuccess,
  isFetchCaseSuccess,
  isGetCaseDataFromPasteBinSuccess,
  isDownloadCaseFileSuccess,
  isGeneratePolicyNumberSuccess,
  getGeneratePolicyNumberErrorCode
} from '../../services/cases.service.helpers';
import {
  IGetLiveCaseSessionSuccess,
  IJoinLiveCaseSuccess,
  IFetchCaseSuccess,
  IGetCaseDataFromPasteBinSuccess
} from '../../services/cases.service.types';
import { IGetPdfImageSuccess } from '../../services/pdf.service.types';
import { IStatusFailure } from '../../services/service.types';
import { isCreateAnonymousUerSuccess } from '../../services/user.service.helpers';
import { alertActions, AlertActionTypes } from '.';
import { AppState } from '../reducers';
import {
  convertCaseToEntity,
  convertEntityToCaseSummary,
  ICaseList,
  injectMetadata,
  fileNameFormatter,
  makeRequest,
  errorLogger,
  displayNameFormatter
} from '../../helpers';
import { CreateCaseType, CaseStatus, ICaseFilters } from '../../interfaces';
import { apiEndpointDomians } from '../../constants';

const {
  REACT_APP_OPENID_CLIENT_ID: OPENID_CLIENT_ID,
  REACT_APP_TENANT: OAUTH_REALMS,
  REACT_APP_ANONYMOUS_USER_SERVICE_DOMAIN: ANONYMOUS_USER_SERVICE_DOMAIN,
  REACT_APP_POLICY_NUMBER_SERVICE_NAME: POLICY_NUMBER_SERVICE_NAME,
  REACT_APP_POLICY_NUMBER_ENGINE_NAME: POLICY_NUMBER_ENGINE_NAME,
  REACT_APP_POLICY_NUMBER_SERVICE_CATEGORY: POLICY_NUMBER_SERVICE_CATEGORY,
  REACT_APP_POLICY_NUMBER_TRIGGER_POINT: POLICY_NUMBER_TRIGGER_POINT,
} = process.env;

const { DOCSTORE_DOMAIN_WITH_TENANT } = apiEndpointDomians;

export interface IParticipant {
  id: string;
  key: string;
  name: string;
  createdAt: Date | null;
}

export interface CasesActionTypes {
  type: string;
  caseList?: ICaseList | null;
  participant?: IParticipant | null;
  sessionUrl?: string;
}

const createCase = (caseData: CreateCaseType, shouldGeneratePolicyNumber = true) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{ caseId: string | null }> => {
  try {
    const caseDataToBeConverted = { ...caseData };

    // Calls policy number generation script uploaded to product factory
    if (
      POLICY_NUMBER_SERVICE_NAME
      && POLICY_NUMBER_ENGINE_NAME
      && POLICY_NUMBER_SERVICE_CATEGORY
      && POLICY_NUMBER_TRIGGER_POINT === 'CREATE_CASE'
      && shouldGeneratePolicyNumber
    ) {
      const payload = await CasesService.generatePolicyNumber(
        POLICY_NUMBER_SERVICE_NAME,
        POLICY_NUMBER_ENGINE_NAME,
        POLICY_NUMBER_SERVICE_CATEGORY
      );

      if (!isGeneratePolicyNumberSuccess(payload)) {
        throw new Error(getGeneratePolicyNumberErrorCode(payload) ?? 'UNKNOWN_ERROR');
      }

      const {
        data: {
          Outputs: {
            Data: { result: policyNumber }
          }
        }
      } = payload;

      set(caseDataToBeConverted, 'data.Application.Number', policyNumber);
    }

    const body = convertCaseToEntity(caseDataToBeConverted);
    const payload = await CasesService.createCase(body);

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    const { data: { id: caseId } } = payload;

    return { caseId };
  } catch (error) {
    const errorTranslationKey = [
      // Generate Policy Number
      'MISSING_REQUIRED_SCRIPT_CONTEXT',
      'FAILED_TO_GET_APPLICATION_NUMBER_DOCUMENT',
      'MAXIMUM_APPLICATION_NUMBER_GENERATION_RETRY_REACHED',
      'FAILED_TO_UPDATE_APPLICATION_NUMBER_DOCUMENT',
      'FAILED_TO_GET_UPDATED_TEMPORARY_RECEIPT_NUMBER_DOCUMENT',
      'VERSION_CONFLICT',
      'INTERNAL_SERVER_ERROR',

      // Create case error
      'NULL_OR_EMPTY_DATAJSON',
      'INVALID_NEW_CASE_STATUS',
      'INVALID_JSON',
      'PATH_IS_REQUIRED',
      'INVALID_VALUE_FOR_PATH',
      'UNAUTHORIZED'
    ].includes(error.message)
      ? `caseAction.createCase.${error.message}`
      : 'UNKNOWN_ERROR'; // INTERNAL_SERVER_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { caseId: null };
  }
};

const fetchCase = (caseId: string) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{
  isFetchCaseSuccess: true,
  data: IFetchCaseSuccess
} | {
  isFetchCaseSuccess: false,
  data: null
}> => {
  try {
    const data = await CasesService.fetchCase(caseId);

    if (!isFetchCaseSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return {
      isFetchCaseSuccess: true,
      data
    };
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'ENTITY_NOT_FOUND'
    ].includes(error.message)
      ? `caseAction.fetchCase.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { isFetchCaseSuccess: false, data: null };
  }
};

const getLiveCaseSession = (caseId: string) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<IGetLiveCaseSessionSuccess | IStatusFailure> => {
  try {
    const payload = await CasesService.getLiveCaseSession(caseId);

    if (!isGetLiveCaseSessionSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    const {
      participant_data: participantData,
      session_data: { sessionUrl }
    } = payload;

    dispatch({
      type: casesTypes.FETCH_LIVE_CASE_SUCCESS,
      participant: participantData,
      sessionUrl,
    });

    return payload;
  } catch (error) {
    const errorTranslationKey = [
      'ENTITY_NOT_FOUND',
      'UNAUTHORIZED'
    ].includes(error.message)
      ? `caseAction.getLiveCaseSession.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { status: 'error', errorCode: error.message };
  }
};

const joinLiveCase = (
  caseId,
  participantId: string,
  participantKey: string
) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{
  isJoinLiveCaseSuccess: true;
  data: IJoinLiveCaseSuccess
} | {
  isJoinLiveCaseSuccess: false;
  data: null;
}> => {
  try {
    const data = await CasesService.joinLiveCase(
      caseId,
      participantId,
      participantKey
    );

    if (!isJoinLiveCaseSuccess(data)) {
      throw new Error('UNKNOWN_ERROR');
    }

    return { isJoinLiveCaseSuccess: true, data };
  } catch (error) {
    // TODO: Identify possible error_code and fall back to 'UNKNOWN_ERROR' when not match
    dispatch(alertActions.error(error.message));
    errorLogger(error);
    return { isJoinLiveCaseSuccess: false, data: null };
  }
};

const updateCase = (
  caseId: string,
  caseData: object,
  shouldAlertSave = true
) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{ isSuccess: boolean }> => {
  try {
    const body = convertCaseToEntity({ data: caseData });
    const data = await CasesService.updateCase(caseId, body);

    if (!isRequestSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    shouldAlertSave && dispatch(alertActions.success('formbuilder.caseStatus.successivelySaved'));
    return { isSuccess: true };
  } catch (error) {
    const errorTranslationKey = [
      'ENTITY_NOT_FOUND',
      'INVALID_CASE_STATUS',
      'CASE_NOT_UPDATABLE',
      'INVALID_JSON',
      'MISSING_DATA_JSON',
      'CASE_NOT_FOUND'
    ].includes(error.message)
      ? `caseAction.updateCase.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { isSuccess: false };
  }
};

const updateCaseStatus = (caseId, status: CaseStatus) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{ isUpdateSuccess: boolean }> => {
  try {
    const data = await CasesService.updateCaseStatus(caseId, status);

    if (!isRequestSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return { isUpdateSuccess: true };
  } catch (error) {
    const errorTranslationKey = [
      'INVALID_CASE_STATUS',
      'EMPTY_REQUEST_BODY',
      'INVALID_STATUS_TRANSITION',
      'ENTITY_NOT_FOUND',
      'UNAUTHORIZED'
    ].includes(error.message)
      ? `caseAction.updateCaseStatus.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return { isUpdateSuccess: false };
  }
};

const completeCase = (caseId: string) => updateCaseStatus(caseId, 'COMPLETED');

const archiveCase = (
  caseId: string,
  reason?: string
) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{ isArchiveSuccess: boolean }> => {
  try {
    const fetchPayload = await CasesService.fetchCase(caseId);

    if (!isFetchCaseSuccess(fetchPayload)) {
      throw new Error(getRequestErrorCode(fetchPayload) ?? 'UNKNOWN_ERROR');
    }

    const { effectiveVersion: { dataJson: caseData } } = fetchPayload;
    const caseDataWithDeleteReason = {
      ...caseData,
      Delete: { Reason: reason }
    };
    const body = convertCaseToEntity({ status: 'ARCHIVED', data: caseDataWithDeleteReason });
    const updateCasePayload = await CasesService.updateCase(caseId, body);

    if (!isRequestSuccess(updateCasePayload)) {
      throw new Error(getRequestErrorCode(updateCasePayload) ?? 'UNKNOWN_ERROR');
    }

    return { isArchiveSuccess: true };
  } catch (error) {
    const errorTranslationKey = [
      // common
      'ENTITY_NOT_FOUND',
      'UNAUTHORIZED',

      // fetchCase -- all are common code

      // updateCase
      'INVALID_CASE_STATUS',
      'CASE_NOT_UPDATABLE',
      'INVALID_JSON',
      'MISSING_DATA_JSON',
      'CASE_NOT_FOUND'
    ].includes(error.message)
      ? `caseAction.archiveCase.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return { isArchiveSuccess: false };
  }
};

const fetchCaseList = (statuses?: CaseStatus[], page?: number, pageSize?: number, keyword = '', dateRange?: ICaseFilters['dateRange']) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{ canSuccessfullyFetchCases: boolean }> => {
  try {
    const caseStatusQueryParts = (statuses ?? []).map(status => `(status1 = "${status}")`);
    const caseStatusQuery = caseStatusQueryParts.length ? caseStatusQueryParts.join(' or ') : null;

    const isNumberKeyword = /^[0-9]+(\.[0-9]+)?$/.test(keyword);

    const textQuery = keyword
      ? ['email1',
        'email2',
        'policyNumber',
        'name1',
        'name2',
        'otherString1',
        'otherString2',
        'referenceId',
        'disposition',
        'productName'].map(column => `(${column} like "%${keyword}%")`).join(' or ')
      : null;

    const amountQuery = keyword && isNumberKeyword
      ? [
        'amount1',
        'amount2',
        'amount3'
      ].map(column => `(${column} = ${keyword})`).join(' or ')
      : null;

    const keywordQuery = keyword && isNumberKeyword ? [textQuery, amountQuery].join(' or ') : textQuery;

    const dateRangeQuery = (dateRange?.length === 2 && dateRange[0] && dateRange[1])
      ? `(updated >= "${dateRange[0].startOf('day').toISOString()}" and updated <= "${dateRange[1].endOf('day').toISOString()}")`
      : null;

    const completeQuery = [caseStatusQuery, keywordQuery, dateRangeQuery]
      .filter(query => query)
      .map(query => `(${query})`)
      .join(' and ');

    const payload = await CasesService.fetchCaseList(page, pageSize, completeQuery);

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    const caseSummary = convertEntityToCaseSummary(payload);
    dispatch({ type: casesTypes.FETCH_SUCCESS, caseList: caseSummary });

    return { canSuccessfullyFetchCases: true };
  } catch (error) {
    const errorTranslationKey = [
      ''
    ].includes(error.message)
      ? `caseStore.fetchCases.${error.message}`
      : 'UNKNOWN_ERROR'; // INVALID_STATUS, INVALID_INPUT, CASE_LIST_ERROR
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { canSuccessfullyFetchCases: false };
  }
};

// TODO: unify the return type so that we can have a stricter type in FormBuilder
const downloadMergedPdf = (
  caseId: string,
  docStoreFiles: {
    FormName: string;
    DocStoreFilePath?: string;
    PreProcessors?: any;
    DocumentId?: string;
  }[],
  userInfo: any,
  ignoreSave = false,
  submitted = false,
  ignoreDownload = false,
  mode?: 'pdf' | 'zip'
) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
) => {
  try {
    const fetchPayload = await CasesService.fetchCase(caseId);

    if (!isFetchCaseSuccess(fetchPayload)) {
      throw new Error(getRequestErrorCode(fetchPayload) ?? 'UNKNOWN_ERROR');
    }

    const { effectiveVersion: { dataJson: caseData } } = fetchPayload;

    // Inject FP data
    const sendData = injectMetadata(caseData, userInfo);
    const body = convertCaseToEntity({ data: sendData });

    // Update case if it's not submitted
    if (!submitted) {
      const updatePayload = await CasesService.updateCase(caseId, body);
      if (!isRequestSuccess(updatePayload)) {
        throw new Error(updatePayload?.['error_code'] ?? 'UNKNOWN_ERROR');
      }
    }

    // Use client name as part of the file name
    const caseName = `${displayNameFormatter(caseData?.Applicant?.LastName?.English, caseData?.Applicant?.FirstName?.English)}` ?? 'Unknown Customer';

    const filename = fileNameFormatter(caseName, true, mode ?? 'pdf');

    const { data, status } = await PdfService.getMergedPdfUrl(
      sendData,
      docStoreFiles,
      filename,
      mode
    );

    if (status !== 200 || !isRequestSuccess(data)) {
      throw new Error('FAIL_TO_MERGE_PDF');
    }

    if (ignoreDownload) {
      return {
        ...data,
        filename
      };
    }

    if (ignoreSave) {
      return makeRequest<Blob>({
        method: 'GET',
        url: data.fileUrl,
        headers: { 'Content-Type': null },
        responseType: 'blob',
      });
    }

    window.open(data.fileUrl);
  } catch (error) {
    const errorTranslationKey = [
      'CASE_READ_PERMISSION_DENIED',
      'CASE_NOT_FOUND',
      'CASE_UPDATE_PERMISSION_DENIED',
      'FAIL_TO_MERGE_PDF',
    ].includes(error.message)
      ? `caseAction.downloadMergedPdf.${error.message}`
      : 'UNKNOWN_ERROR'; // fetchCase-->CASE_GET_ERROR, updateCase-->CASE_UPDATE_ERROR, INVALID_INPUT
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return {
      status: 'FAILED',
      errorCode: error.message
    };
  }
};

const createLinkShare = (
  caseId: string,
  lastName: string,
  firstName: string,
  phoneNumber: string,
  expiryDatetime: Date,
  introMessage: string,
  usageLimit = 1000,
  language?: string,
) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes>,
): Promise<{ caseLink: string | null }> => {
  try {
    // Check applicant phone number before create link
    if (!phoneNumber) {
      throw new Error('INVALID_PHONE_NUMBER');
    }

    // Create an anonymous user
    const createAnonymousUserPayload = await UserService.createAnonymousUser(
      lastName,
      firstName,
      phoneNumber,
      expiryDatetime,
    );

    if (!isCreateAnonymousUerSuccess(createAnonymousUserPayload)) {
      throw new Error(getRequestErrorCode(createAnonymousUserPayload) ?? 'UNKNOWN_ERROR');
    }

    const { userId } = createAnonymousUserPayload;

    // Delegate the case to the anonymous user
    const delegateCaseToAnonymousUserPayload = await CasesService.delegateCaseToAnonymousUser(
      caseId,
      userId,
      expiryDatetime,
    );

    if (!isRequestSuccess(delegateCaseToAnonymousUserPayload)) {
      throw new Error(getRequestErrorCode(delegateCaseToAnonymousUserPayload) ?? 'UNKNOWN_ERROR');
    }

    // Delegate the case to the anonymous user
    const createLinkSharePayload = await CasesService.createLinkShare(
      caseId,
      userId,
      expiryDatetime,
      introMessage,
      usageLimit,
      language,
    );

    if (!isRequestSuccess(createLinkSharePayload)) {
      throw new Error(getRequestErrorCode(createLinkSharePayload) ?? 'UNKNOWN_ERROR');
    }

    const { data: { id: linkId } } = createLinkSharePayload;

    return { caseLink: `${ANONYMOUS_USER_SERVICE_DOMAIN}/anonymous-user/welcome?client=${OPENID_CLIENT_ID}&realm=${OAUTH_REALMS}&linkId=${linkId}&anonymousUserId=${userId}` };
  } catch (error) {
    const errorTranslationKey = [
      // createAnonymousUser
      'INVALID_TOKEN',

      // delegate case
      'UNAUTHORIZED',
      'INVALID_GUID',
      'INVALID_UTC_DATETIME_FORMAT',

      // createLinkShare
      'NULL_OR_EMPTY_INTROMSG',
      'NULL_OR_EMPTY_TARGETURL',
      'USAGELIMIT_MUST_BE_LESS_THAN_1000',
      'USAGELIMIT_MUST_BE_ATLEAST_1',
      'INVALID_PHONE_NUMBER',
    ].includes(error.message)
      ? `caseAction.createLinkShare.${error.message}`
      /*
       * The following error codes are treated as UNKNOW_ERROR
       * createAnonymousUser --> INTERNAL_SERVER_ERROR, MISSING_KEY_IN_BODY, INVALID_DATETIME, INVALID_PHONE_NUMBER, INVALID_EMAIL, INVALID_VALUE_TYPE
       * createLinkShare --> LINK_CREATION_ERROR, INVALID_RESOURCE_TYPE
       */
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return { caseLink: null };
  }
};

const getCaseDataFromPasteBin = (pasteId: string) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{
  isGetPasteDataSuccess: true,
  data: IGetCaseDataFromPasteBinSuccess
} | {
  isGetPasteDataSuccess: false,
  data: null
}> => {
  try {
    const data = await CasesService.getCaseDataFromPasteBin(
      pasteId
    );

    if (!isGetCaseDataFromPasteBinSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return {
      isGetPasteDataSuccess: true,
      data
    };
  } catch (error) {
    const errorTranslationKey = [
      'ENTITY_NOT_FOUND',
    ].includes(error.message)
      ? `caseAction.getCaseDataFromPasteBin.${error.message}`
      : 'UNKNOWN_ERROR';

    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return {
      isGetPasteDataSuccess: false,
      data: null
    };
  }
};

const uploadCaseDocument = (caseId: string, file: File) => async (
  dispatch: ThunkDispatch<AppState, null, CasesActionTypes | AlertActionTypes>,
): Promise<{
  documentId: string;
  url: string;
  errorCode: null;
} | {
  documentId: null;
  url: null;
  errorCode: string;
}> => {
  try {
    const payload = await CasesService.uploadCaseDocument(caseId, file);

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    const { data: { id, url } } = payload;

    return {
      documentId: id,
      url: `${DOCSTORE_DOMAIN_WITH_TENANT}${url}`,
      errorCode: null
    };
  } catch (error) {
    const fileTooLargeErrorRegex = /^(SIZE|FILE)_MUST_BE_LESS_THAN_\d+$/;
    const errorTranslationKey = [
      'FILE_NOT_FOUND',
      'INVALID_REQUEST',
      'ENTITY_NOT_FOUND',
      'CASE_NOT_UPDATABLE',
      'UNAUTHORIZED',
    ].includes(error.message)
      ? `caseAction.uploadCaseDocument.${error.message}`
      : fileTooLargeErrorRegex.test(error.message)
        ? 'caseAction.uploadCaseDocument.FILE_TOO_LARGE'
        : 'UNKNOWN_ERROR';

    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return { documentId: null, url: null, errorCode: error.message };
  }
};

const getPdfImage = (
  pdfTitle: string,
  caseData: any,
  preProcessors: object,
  color: string,
  includeSignature: boolean,
  pdfPath?: string,
  base64Pdf?: string
) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
): Promise< IGetPdfImageSuccess | {
  images: null;
  signatureFields: null;
  otherFields: null;
  message: string;
}> => {
  try {
    const data = await PdfService.getPdfImage(
      pdfTitle,
      caseData,
      preProcessors,
      color,
      includeSignature,
      pdfPath,
      base64Pdf
    );

    if (!isRequestSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return data;
  } catch (error) {
    const errorTranslationKey = error.message ?? 'caseAction.getPdfImage.ERROR_DISPLAYING_DOCUMENT';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return {
      images: null,
      signatureFields: null,
      otherFields: null,
      message: error.message
    };
  }
};

const downloadCaseFiles = (caseId: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
): Promise<Blob | null> => {
  try {
    const data = await CasesService.downloadCaseFiles(caseId);

    if (!isDownloadCaseFileSuccess(data)) {
      throw new Error(getRequestErrorCode(data) ?? 'UNKNOWN_ERROR');
    }

    return data;
  } catch (error) {
    const errorTranslationKey = [
      'ENTITY_NOT_FOUND',
    ].includes(error.message)
      ? `caseAction.downloadCaseFiles.${error.message}`
      : 'UNKNOWN_ERROR';

    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);

    return null;
  }
};

const makeTempDocumentPermanent = (caseId: string, filename: string, documentId: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
): Promise<{ permanentDocumentId: string | null }> => {
  try {
    const payload = await CasesService.makeTempDocumentPermanent(
      caseId,
      filename,
      documentId,
    );

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    const { data: { id: newDocumentId } } = payload;

    return {
      permanentDocumentId: newDocumentId
    };
  } catch (error) {
    const errorTranslationKey = [
      'UNAUTHORIZED',
      'TEMPORARY_TOKEN_EXPIRED',
      'ENTITY_NOT_FOUND',
      'CASE_NOT_UPDATABLE',
    ].includes(error.message)
      ? `caseAction.makeTempDocumentPermanent.${error.message}`
      : 'UNKNOWN_ERROR'; // INVALID_JSON, NULL_OR_EMPTY_FILENAME, EMPTY_REQUEST_BODY
    dispatch(alertActions.error(errorTranslationKey));

    errorLogger(error);

    return { permanentDocumentId: null };
  }
};

const deleteCaseDocument = (caseId: string, documentId: string) => async (
  dispatch: ThunkDispatch<AppState, null, AlertActionTypes>,
): Promise<{
  isSuccess: true;
  errorCode: null;
} | {
  isSuccess: false;
  errorCode: string;
}> => {
  try {
    const payload = await CasesService.deleteCaseDocument(caseId, documentId);

    if (!isRequestSuccess(payload)) {
      throw new Error(getRequestErrorCode(payload) ?? 'UNKNOWN_ERROR');
    }

    return { isSuccess: true, errorCode: null };
  } catch (error) {
    const errorTranslationKey = [
      'ENTITY_NOT_FOUND',
      'CASE_NOT_UPDATABLE',
      'UNAUTHORIZED'
    ].includes(error.message)
      ? `caseAction.deleteCaseDocument.${error.message}`
      : 'UNKNOWN_ERROR';
    dispatch(alertActions.error(errorTranslationKey));
    errorLogger(error);
    return { isSuccess: false, errorCode: error.message };
  }
};

export const casesAction = {
  createCase,
  updateCase,
  updateCaseStatus,
  completeCase,
  archiveCase,
  fetchCase,
  getLiveCaseSession,
  joinLiveCase,
  fetchCaseList,
  downloadMergedPdf,
  createLinkShare,
  getCaseDataFromPasteBin,
  uploadCaseDocument,
  getPdfImage,
  downloadCaseFiles,
  makeTempDocumentPermanent,
  deleteCaseDocument
};
