import React, { FC } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';
import styled from 'styled-components';
import SelectionCard from './SelectionCard';
import { colorHexToRgba } from '../../../helpers';

interface DefaultCheckboxStyleProps {
  error?: boolean;
  userColor?: string;
}

interface StyledCheckboxProps extends CheckboxProps, DefaultCheckboxStyleProps {
  checkboxType?: 'check' | 'card';
  title?: string;
  description?: string;
}

const StyledCheckbox = styled(AntCheckbox)<StyledCheckboxProps>`
  & .ant-checkbox-inner {
    border: 1px solid ${props => (props.error
      ? props.theme.color.alert
      : props.userColor || props.theme.color.border)};

    background-color: ${props => (props.error ? props.theme.color.lightAlert : props.theme.color.white)};
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid ${props => (props.error
      ? props.theme.color.alert
      : props.userColor || props.theme.color.primary)};
    background-color: ${props => (props.error ? props.theme.color.lightAlert : props.theme.color.primary)};
  }

  &.ant-checkbox-wrapper-disabled .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => colorHexToRgba(props.theme.color.primary, 0.5)};
    border: none;

    &:after{
      border-color: ${props => colorHexToRgba(props.theme.color.bodyLight, 0.8)};
    }
  }

  ${props => (props.checkboxType === 'card' ? `
    height: 100%;
    width: 100%;

    & .ant-checkbox {
      display: none;
    }

    & .ant-checkbox + span {
      padding-left: 0;
      padding-right: 0;
    }
  ` : `
    &.ant-checkbox-wrapper {
      margin-bottom: 0.25rem;
      position: relative;
      padding-left: 1rem;
    }

    & .ant-checkbox {
      position: absolute;
      left: 0;
      top: 0.1rem;
    }

    & .ant-checkbox + span {
      display: block;
    }
  `)}
`;

const { Group } = AntCheckbox;

const CheckboxGroup = styled(Group)`
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const Checkbox: FC<StyledCheckboxProps> = ({
  checkboxType = 'check',
  error,
  userColor,
  title = '',
  description = '',
  checked,
  children,
  ...props
}) => (
  <StyledCheckbox
    checkboxType={checkboxType}
    error={error}
    userColor={userColor}
    checked={checked}
    {...props}
    className="formbuilder__checkbox__common"
  >
    {
      checkboxType === 'card' ? (
        <SelectionCard
          checked={checked}
          title={<span>{title}</span>}
          height="100%"
          description={description}
        />
      ) : (
        <span className="formbuilder__checkbox__common__item">{children}</span> // Title or custom elements
      )
    }
  </StyledCheckbox>
);

export default Checkbox;

export { CheckboxGroup };
