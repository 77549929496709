import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';

import { IFormFieldProps } from '../../types';
import Tooltip from './Tooltip';
import Sup from './Sup';

interface ILabelDivProps {
  textAlignment?: 'left' | 'right' | 'center';
  margin?: string;
  fontSize?: string;
  fontWeight?: string;
  flex?: string;
  className?: string;
}

interface ILabelProps extends Pick<
  IFormFieldProps,
  'label'
  | 'tooltip'
  | 'required'
  | 'readonly'
  | 'metadata'
  | 'name'
  | 'getBindedText'
>, ILabelDivProps {}

const LabelDiv = styled.div<ILabelDivProps>`
  width: 100%;
  font-size: ${props => props.fontSize || '0.875rem'};
  font-weight: ${props => props.fontWeight || 'normal'};
  margin: ${props => props.margin || 'auto'};
  text-align: ${props => props.textAlignment || 'left'};
  ${props => props.flex && `flex: ${props.flex};`};
  padding: 0.3125rem 0;
`;

const TooltipWrapper = styled.div`
  margin-left: ${props => props.theme.unit.space_sm};
  display: inline-block;

  .anticon {
    vertical-align: middle;
  }
`;

const Label: FC<ILabelProps> = ({
  name,
  label,
  tooltip,
  metadata,
  required,
  readonly,
  getBindedText,
  ...labelDivProps
}) => {
  const translationId = `${name}_${label}`;
  return (label ? (
    <LabelDiv {...labelDivProps}>
      <span className="formbuilder__row__label__text">
        <FormattedHTMLMessage id={translationId} defaultMessage={label} />
      </span>

      {required && !readonly && (
        <Sup className="formbuilder__row__label__sup">*</Sup>
      )}

      {!!tooltip && (
        <TooltipWrapper className="formbuilder__row__tooltip">
          <Tooltip name={name} tooltip={tooltip} tooltipMetadata={metadata?.tooltip} getBindedText={getBindedText} />
        </TooltipWrapper>
      )}
    </LabelDiv>
  ) : null);
};

export default Label;
