import AppConstants from './app.constants';

const {
  REACT_APP_CASESTORE_DOMAIN,
  REACT_APP_PDFSERVICE_DOMAIN,
  REACT_APP_EXCELENGINE_DOMAIN,
  REACT_APP_DOCSTORE_DOMAIN,
  REACT_APP_LIVEJSONSERVER_DOMAIN,
  REACT_APP_LIVEJSON_URL_PREFIX
} = process.env;

const apiEndpointDomians = {
  CASESTORE_DOMAIN_WITH_PATH: `${REACT_APP_CASESTORE_DOMAIN}/${AppConstants.TENANT}/casestore/api/v1`,
  PDFSERVICE_DOMAIN_WITH_PATH: `${REACT_APP_PDFSERVICE_DOMAIN}/api`,
  EXCELENGINE_DOMAIN_WITH_PATH: `${REACT_APP_EXCELENGINE_DOMAIN}/api/v1`,
  FILEMANAGER_DOMAIN_WITH_PATH: `${REACT_APP_EXCELENGINE_DOMAIN}/api/filemanager`,
  DOCSTORE_DOMAIN_WITH_TENANT: `${REACT_APP_DOCSTORE_DOMAIN}/${AppConstants.TENANT}`,
  DOCSTORE_DOMAIN_WITH_PATH: `${REACT_APP_DOCSTORE_DOMAIN}/${AppConstants.TENANT}/docstore/api/v1`,
  LIVEJSONSERVER_DOMAIN: REACT_APP_LIVEJSONSERVER_DOMAIN || '',
  LIVEJSONSERVER_DOMAIN_WITH_PATH: `${REACT_APP_LIVEJSONSERVER_DOMAIN}${REACT_APP_LIVEJSON_URL_PREFIX}`,
};

export default apiEndpointDomians;
