import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import promise from 'es6-promise';
import { detect } from 'detect-browser';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import App from './App';

// Logging Services
import './logging';

// import * as serviceWorker from './serviceWorker';

import './assets/styles/index.scss';

promise.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>LOADING...</div>} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// Security warning on staging and production
if (process.env.NODE_ENV !== 'development') {
  const { name: browserName } = detect() ?? {};

  const warningPrefix = 'STOP!';
  const warningBody = 'This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your account.';
  const copyrightMessage = 'Ignited by Coherent';
  const companyLink = 'https://www.coherent.com.hk/';

  if (browserName !== 'edge' && browserName !== 'ie') {
    console.info(
      `%c${warningPrefix}%c\n\n${warningBody}%c\n\n${copyrightMessage}%c -- ${companyLink}`,
      'background: #ff0000; color: #ffffff; padding: 5px; font-size: 30px;',
      'font-size: 16px;',
      'font-size: 12px; color: #9c43ff;',
      'font-size: 12px;'
    );
  } else {
    console.info(`${warningPrefix}\n\n${warningBody}\n\n${copyrightMessage} -- ${companyLink}`);
  }
}
