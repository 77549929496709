import { alertTypes } from '../types';

export interface AlertActionTypes {
  type: string;
  msgType?: string;
  msgContent?: string;
  msgDescription?: string;
  customProps?: any;
  component?: string;
}

const triggerMessage = (msgType: string) => (
  msgContent: string,
  msgDescription = '',
  customProps = {},
  component = 'message',
) => ({
  type: alertTypes.SET_MESSAGE,
  msgType,
  msgContent,
  msgDescription,
  customProps,
  component,
});

const success = triggerMessage('success');
const error = triggerMessage('error');
const warning = triggerMessage('warning');
const info = triggerMessage('info');
const loading = triggerMessage('loading');

const clear = () => ({ type: alertTypes.CLEAR_MESSAGE });

export const alertActions = {
  success,
  error,
  warning,
  info,
  loading,
  clear,
};
