import React, {
  FC, useMemo
} from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CheckboxValueType } from 'antd/lib/checkbox/Group.d';

import Modal from './Modal';
import { Checkbox, CheckboxGroup } from '../../FormBuilderLibrary/components/FormCommon';
import { IDocFile } from '../../interfaces';

interface PrintModalProps {
  visible: boolean;
  onCloseModal: () => void;
  docFiles: IDocFile[];
  onOkButtonClick?: () => void;
  formSpecMeta?: any;
  caseSubmitted: boolean;
  isPrintingPDF: boolean;
  coreData?: any;
  onPrint: () => Promise<void>;
  onCheckBoxClick: (checkValue: CheckboxValueType[]) => void;
  selectedFilesId: CheckboxValueType[];
}

const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-flow: column;
`;

const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const PrintModal: FC<PrintModalProps> = ({
  visible,
  onCloseModal,
  docFiles,
  onOkButtonClick,
  formSpecMeta,
  caseSubmitted,
  coreData,
  isPrintingPDF,
  onPrint,
  onCheckBoxClick,
  selectedFilesId
}) => {
  const intl = useIntl();

  const modalTitle = useMemo(() => (
    caseSubmitted
      ? formSpecMeta?.printDialogueSubmitTitle
      : formSpecMeta?.printDialogueTitle
  ) || intl.formatMessage({ id: 'printModal.title' }),
  [caseSubmitted, formSpecMeta, intl]);

  const modalDescription = useMemo(() => (
    caseSubmitted
      ? formSpecMeta?.printDialogueSubmitText
      : formSpecMeta?.printDialogueText
  ) || intl.formatMessage({ id: 'printModal.description' }),
  [caseSubmitted, formSpecMeta, intl]);

  return (
    <Modal
      visible={visible}
      title={modalTitle}
      description={modalDescription}
      cancelButtonLabel={intl.formatMessage({ id: 'printModal.cancelButton' })}
      onCancelButtonClick={onCloseModal}
      okButtonLabel={intl.formatMessage({ id: 'printModal.printButton' })}
      okButtonDisable={
        docFiles.filter(
          file => selectedFilesId.includes(file.id) || file.metadata.Mandatory
        ).length === 0 && !coreData?.Print
      }
      okButtonLoading={isPrintingPDF}
      onOkButtonClick={() => {
        onPrint && onPrint();
        onOkButtonClick && onOkButtonClick();
      }}
      destroyOnClose
      className="print-modal"
    >
      <StyledCheckboxGroup
        onChange={onCheckBoxClick}
        value={selectedFilesId}
      >
        {docFiles
          .filter(file => !file.metadata.Mandatory)
          .sort((a, b) => a.metadata.Order - b.metadata.Order)
          .map(file => (
            <StyledCheckbox
              id={`modal__print__option__${file.id}`}
              key={file.id}
              value={file.id}
              className="print-modal__checkbox"
            >
              {file.metadata.FormName}
            </StyledCheckbox>
          ))}
      </StyledCheckboxGroup>
    </Modal>
  );
};

export default PrintModal;
