import React, { FC, ReactNode } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';
import { Modal as AntModal, Button } from 'antd';
import ModalProps from 'antd/lib/modal';

export type ModalPropsType = {
  width?: string | number;
  title?: string | ReactNode;
  visible: boolean;
  titleFontSize?: number;
  description?: string | ReactNode;
  cancelButtonLabel?: string;
  onCancelButtonClick?: () => null | void;
  okButtonLabel?: string;
  okButtonDisable?: boolean;
  okButtonLoading?: boolean;
  onOkButtonClick?: () => void;
  closable?: boolean;
  destroyOnClose?: boolean;
  className?: string;
  zIndex?: number;
  customizedFooter?: ReactNode;
  fullScreenInMobile?: boolean;
};

interface IStyledModal extends ModalProps, Pick<ModalPropsType, 'titleFontSize'> {
}

const StyledModal = styled(({
  titleFontSize,
  fullScreenInMobile,
  ...props
}) => <AntModal {...props} />)<IStyledModal>`
  & .ant-modal-content {
    overflow: hidden;
  }
  & .ant-modal-header {
    border-bottom: 0;
    padding: ${props => `${props.theme.unit.space_xl} ${props.theme.unit.space_xl} ${props.theme.unit.space_md} ${props.theme.unit.space_xl}`} ;
  }

  & .ant-modal-title{
    font-size: ${props => props.titleFontSize || 1.125}rem;
    font-weight: bold;
    line-height: 1.25em;
    color: ${props => props.theme.color.headings};
  }

  & .ant-modal-body {
    padding: ${props => (props.footer
      ? `${props.theme.unit.space_sm} ${props.theme.unit.space_xl}`
      : `${props.theme.unit.space_sm} ${props.theme.unit.space_xl} ${props.theme.unit.space_xl} ${props.theme.unit.space_xl}`
    )} ;
    color: ${props => props.theme.color.body};
    font-size: 0.875rem;
  }

  & .ant-modal-footer {
    padding: ${props => `${props.theme.unit.space_md} ${props.theme.unit.space_xl} ${props.theme.unit.space_xl} ${props.theme.unit.space_xl}`} ;
    border-top: 0;
  }

  ${props => props.fullScreenInMobile && `
    @media ${props.theme.mediaQueries.mobile} {
      &.ant-modal {
         height: 100%;
         width: 100% !important;
         margin: 0; max-width:
         100%; padding:0;
      }
      .ant-modal-content {
        position: relative;
        border-radius: 0;
        height: 100%;
      }
      .ant-modal-footer {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
  `}
`;

const ModalDescription = styled.div`
  margin-bottom: ${props => props.theme.unit.space_lg};
  font-size: 0.8125rem;
`;

const Modal: FC<ModalPropsType> = ({
  width,
  title,
  visible,
  titleFontSize,
  description,
  cancelButtonLabel,
  onCancelButtonClick,
  okButtonLabel,
  okButtonDisable = false,
  okButtonLoading = false,
  onOkButtonClick,
  closable = false,
  destroyOnClose = false,
  zIndex = 1000,
  className,
  children,
  customizedFooter,
  fullScreenInMobile,
}) => {
  const footer = (
    <>
      {customizedFooter ?? null}
      {cancelButtonLabel ? (
        <Button
          className="formbuilder__modal__button__cancel"
          onClick={onCancelButtonClick}
        >
          {cancelButtonLabel}
        </Button>
      ) : null}
      {okButtonLabel ? (
        <Button
          className="formbuilder__modal__button__ok"
          type="primary"
          disabled={okButtonDisable}
          loading={okButtonLoading}
          onClick={onOkButtonClick}
        >
          {okButtonLabel}
        </Button>
      ) : null}
    </>
  );

  return (
    <StyledModal
      centered
      width={width}
      visible={visible}
      title={title}
      titleFontSize={titleFontSize}
      footer={cancelButtonLabel || okButtonLabel || customizedFooter ? footer : null}
      closable={closable}
      maskClosable={false}
      destroyOnClose={destroyOnClose}
      onCancel={onCancelButtonClick}
      className={`formbuilder__modal ${className}`}
      zIndex={className?.includes('formbuilder__hint-modal') ? 1010 : zIndex}
      fullScreenInMobile={fullScreenInMobile}
    >
      {description ? (
        <ModalDescription className="formbuilder__modal__description">
          {
            typeof description === 'string'
              ? <FormattedHTMLMessage id={description} />
              : description
          }
        </ModalDescription>
      ) : null}
      {children}
    </StyledModal>
  );
};

export default Modal;
